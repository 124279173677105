import React, { useState, useRef } from "react";
import { Button, Drawer, Select, Space, Typography, Upload } from "antd";
import PropTypes from "prop-types";
import EmailEditor from "react-email-editor";
import fileDownload from "js-file-download";
import RecipientSelector from "./RecipientSelector";
import mailTemplates from "./config";

const { Option } = Select;

const EmailComposer = ({ showComposer, setShowComposer }) => {
  const emailEditorRef = useRef(null);
  const [html, setHtml] = useState("");
  const [showRecipientSelector, setShowRecipientSelector] = useState(false);
  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      setHtml(data?.html);
    });
  };
  const onLoad = () => {
    emailEditorRef?.current?.editor?.loadTemplate(mailTemplates.default.id);
  };
  const downloadDesign = () => {
    emailEditorRef?.current?.editor?.exportHtml((data) => {
      fileDownload(
        JSON.stringify(data?.design),
        `design-${new Date().getTime()}.json`
      );
    });
  };
  return (
    <>
      <Drawer
        title="Compose Email"
        width={1200}
        visible={showComposer}
        onClose={() => setShowComposer(false)}
        footer={[
          <Button
            type="primary"
            onClick={() => {
              setShowRecipientSelector(true);
              exportHtml();
            }}
          >
            Continue
          </Button>,
        ]}
        footerStyle={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <EmailEditor ref={emailEditorRef} onLoad={onLoad} projectId="72569" />
        <Space
          style={{
            alignItems: "center",
          }}
        >
          <Typography>Select Design: </Typography>
          <Select
            onSelect={(value) =>
              emailEditorRef?.current?.editor?.loadTemplate(value)
            }
            defaultValue={mailTemplates.default.id}
          >
            {Object.values(mailTemplates).map((template) => (
              <Option key={template.id}>{template.name}</Option>
            ))}
          </Select>
          <Button onClick={() => downloadDesign()}>Download Design</Button>
          <Upload
            beforeUpload={(file) => {
              const reader = new FileReader();
              reader.onload = (e) => {
                emailEditorRef?.current?.editor?.loadDesign(
                  JSON.parse(e.target.result)
                );
              };
              reader.readAsText(file);
              return false;
            }}
            accept=".json"
          >
            <Button>Upload Design</Button>
          </Upload>
        </Space>
        <RecipientSelector
          showRecipientSelector={showRecipientSelector}
          setShowRecipientSelector={setShowRecipientSelector}
          setShowComposer={setShowComposer}
          html={html}
        />
      </Drawer>
    </>
  );
};

EmailComposer.propTypes = {
  showComposer: PropTypes.bool.isRequired,
  setShowComposer: PropTypes.func.isRequired,
};

export default EmailComposer;
