import React, { useState } from "react";
import { Typography, message, Button } from "antd";
import PropTypes from "prop-types";
import SettlePayoutModal from "./SettlePayoutModal";
import { createPayout } from "../api";

const SettlePayoutAction = ({ payoutDetails }) => {
  const [payoutModalVisible, setPayoutModalVisible] = useState(false);
  const [formSubmissionState, setFormSubmissionState] = useState(false);
  const [successfullySettled, setSuccessfullySettled] = useState(false);

  const handlePayoutSettlement = async (values) => {
    setFormSubmissionState(true);
    const reqBody = {
      ...values,
      baseAmount: values?.baseAmount || payoutDetails?.baseAmount,
      baseOrders: values?.baseOrders || payoutDetails?.baseOrders,
      creator: payoutDetails?.creator?._id,
      paymentTerm: payoutDetails?._id,
      ...(payoutDetails?.selectedMango && {
        selectedMango: payoutDetails?.selectedMango?._id,
      }),
      lastPayoutDate: payoutDetails?.lastPayoutDate,
      ...(values?.customSettlementDate && {
        customSettlementDate: values?.customSettlementDate?.toISOString(),
      }),
      lastDay: payoutDetails?.lastDay?.toISOString(),
      payoutProof: values?.payoutProof?.file,
    };
    console.log(reqBody);
    const formReqBody = new FormData();
    Object.keys(reqBody).forEach((key) => {
      if (reqBody[key]) {
        formReqBody.append(key, reqBody[key]);
      }
    });
    try {
      const resp = await createPayout(formReqBody);
      if (resp?.code === 0) {
        message.success("Payout settled successfully");
        setSuccessfullySettled(true);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
    setFormSubmissionState(false);
    setPayoutModalVisible(false);
  };

  return (
    <>
      {successfullySettled ? (
        <Typography
          style={{
            color: "green",
          }}
        >
          Payment Settled!
        </Typography>
      ) : (
        <Button
          type="primary"
          onClick={() => setPayoutModalVisible(true)}
          disabled={new Date() <= new Date(payoutDetails?.nextPayoutDate)}
        >
          Settle Payout
        </Button>
      )}
      <SettlePayoutModal
        baseAmount={payoutDetails?.baseAmount}
        baseOrders={payoutDetails?.baseOrders}
        premium={payoutDetails?.premium}
        minimumGuarantee={payoutDetails?.minimumGuarantee}
        modalVisible={payoutModalVisible}
        setModalVisible={setPayoutModalVisible}
        settlePaymentFunc={handlePayoutSettlement}
        formSubmissionState={formSubmissionState}
        paymentTermId={payoutDetails?._id}
        endDate={payoutDetails?.endDate}
      />
    </>
  );
};

SettlePayoutAction.propTypes = {
  payoutDetails: PropTypes.shape(Object).isRequired,
};

export default SettlePayoutAction;
