import React, { useState, useEffect } from "react";
import moment from "moment";
import { message, Table } from "antd";
import PropTypes from "prop-types";
import { getWhatsappOrdersOfUser } from "../api";

const WhatsappOrdersTable = ({ userId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);

  const handleFetchUserOrders = async () => {
    setLoading(true);
    try {
      const resp = await getWhatsappOrdersOfUser(userId, page, limit);
      if (resp?.code === 0) {
        setData(resp.result?.data);
        setTotal(resp.result?.total);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Amount Paid (GST incl.)",
      dataIndex: "amount",
      key: "amount",
      render: (text) => Number(text),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("DD/MM/YYYY"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  useEffect(() => {
    handleFetchUserOrders();
  }, [userId, page, limit]);

  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={loading}
      pagination={{
        current: page,
        total,
        pageSize: limit,
        onChange: (p) => setPage(p),
        onShowSizeChange: (p, s) => setLimit(s),
      }}
    />
  );
};

WhatsappOrdersTable.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default WhatsappOrdersTable;
