/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import { Layout, Row, Col, Button, Typography } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";

const Header = () => {
  const history = useHistory();
  const handleLogout = () => {
    localStorage.clear();
    history.push("/");
  };

  return (
    <Layout.Header className="siteHeader">
      <Row justify="space-between" align="middle">
        <Col>
          <Typography.Title level={4} style={{ marginBottom: 0 }}>
            Dashboard
          </Typography.Title>
        </Col>
        <Col>
          <Button
            type="link"
            icon={<PoweroffOutlined />}
            danger
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Col>
      </Row>
    </Layout.Header>
  );
};

export default Header;
