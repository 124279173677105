import React, { useEffect, useState } from "react";
import {
  Button,
  Space,
  Typography,
  message,
  Select,
  Drawer,
  Form,
  Input,
  Radio,
  Upload,
} from "antd";
import {
  UploadOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import {
  getAllInfluencers,
  getCreatorDetailsWithHiddenMangoes,
  sendDashboardMails,
  getRecipientsCount,
  getCampaigns,
} from "../api";
import config from "../../config";
import debounce from "../../Utils/utilityFunctions";

const { Option } = Select;

const RecipientSelector = ({
  html,
  showRecipientSelector,
  setShowRecipientSelector,
  setShowComposer,
}) => {
  const [form] = Form.useForm();
  const [creatorsOptions, setCreatorsOptions] = useState([]);
  const [creatorsLoading, setCreatorsLoading] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [mangoOptions, setMangoOptions] = useState([]);
  const [mangoLoading, setMangoLoading] = useState(false);
  const [recipientsCount, setRecipientsCount] = useState(0);
  const [recipientCountLoading, setRecipientCountLoading] = useState(false);
  const [sendingEmailState, setSendingEmailState] = useState(false);
  const [campaignSearch, setCampaignSearch] = useState("");
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [campaignsLoading, setCampaignsLoading] = useState(false);

  const getCreatorData = async (query) => {
    setCreatorsLoading(true);
    try {
      const res = await getAllInfluencers(query || "", 1);
      if (res.code === 0) {
        setCreatorsOptions(
          res?.result?.map((creator) => (
            <Option key={creator?._id}>{creator?.name}</Option>
          )) || []
        );
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error(err?.message || err?.result || "Something went wrong");
    }
    setCreatorsLoading(false);
  };

  const getMangoData = async (creatorId) => {
    setMangoLoading(true);
    try {
      if (creatorId) {
        const res = await getCreatorDetailsWithHiddenMangoes(creatorId);
        if (res.code === 0) {
          setMangoOptions(
            res?.result?.activeMangoes?.map((mango) => (
              <Option key={mango?._id}>{mango?.title}</Option>
            )) || []
          );
        } else {
          message.error("Something went wrong");
        }
      }
    } catch (err) {
      console.log(err);
      message.error(err?.message || err?.result || "Something went wrong");
    }
    setMangoLoading(false);
  };

  const getCampaignsData = async (query) => {
    setCampaignsLoading(true);
    try {
      const res = await getCampaigns(query || "", 1);
      if (res.code === 0) {
        setCampaignOptions(
          res?.result?.map((campaign) => (
            <Option key={campaign?._id}>{campaign?.subject}</Option>
          )) || []
        );
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error(err?.message || err?.result || "Something went wrong");
    }
    setCampaignsLoading(false);
  };

  const handleEmailSend = async (values) => {
    setSendingEmailState(true);
    const reqBody = {
      ...values,
      html,
    };
    const { testData } = reqBody;
    const processedTestData =
      testData?.map((item) => ({
        email: item?.email?.trim(),
        ...item?.additionalData?.reduce(
          (acc, curr) => ({
            ...acc,
            [curr?.key]: curr?.value,
          }),
          {}
        ),
      })) || [];
    reqBody.testData = processedTestData;
    try {
      const resp = await sendDashboardMails(reqBody);
      if (resp.code === 0) {
        message.success("Emails sent successfully");
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error(err?.message || err?.result || "Something went wrong");
    }
    setSendingEmailState(false);
    if (processedTestData?.length === 0) {
      setShowRecipientSelector(false);
      setShowComposer(false);
    }
  };

  const getRecipientsCountData = async (values) => {
    setRecipientCountLoading(true);
    try {
      const data = form.getFieldsValue();
      const fetch =
        (data?.recipientType === "custom" && !!data?.customCSV) ||
        data?.recipientType === "all" ||
        data?.recipientType === "creators" ||
        (data?.recipientType === "subscribers" && !!data?.creator) ||
        (data?.recipientType === "unread" && !!data?.campaignId);
      if (fetch) {
        const resp = await getRecipientsCount({
          ...data,
          ...values,
        });
        if (resp.code === 0) {
          setRecipientsCount(resp?.result?.count || 0);
        }
      } else {
        setRecipientsCount(0);
      }
    } catch (err) {
      console.log(err);
      message.error(err?.message || err?.result || "Something went wrong");
    }
    setRecipientCountLoading(false);
  };

  useEffect(() => {
    getCreatorData();
  }, [showRecipientSelector]);

  useEffect(async () => {
    form.setFieldsValue({
      mangos: [],
    });
    getMangoData(selectedCreator);
    getRecipientsCountData();
  }, [selectedCreator]);

  useEffect(() => {
    getCampaignsData(campaignSearch);
  }, [campaignSearch]);

  useEffect(() => {
    form.resetFields();
  }, [showRecipientSelector]);

  return (
    <>
      <Drawer
        title="Select Recipients"
        visible={showRecipientSelector}
        onClose={() => setShowRecipientSelector(false)}
        width={800}
        footer={[
          <Button
            type="primary"
            htmlType="submit"
            form="email-send-form"
            loading={sendingEmailState}
            disabled={recipientsCount === 0}
          >
            Send Mails
          </Button>,
        ]}
        footerStyle={{
          display: "flex",
          justifyContent: "flex-end",
        }}
        destroyOnClose
      >
        <Space
          direction="vertical"
          size="middle"
          style={{
            width: "100%",
          }}
        >
          <Form form={form} id="email-send-form" onFinish={handleEmailSend}>
            <Form.Item
              name="subject"
              label="Subject"
              rules={[
                {
                  required: true,
                  message: "Please input the subject",
                },
              ]}
            >
              <Input placeholder="Something Interesting!" />
            </Form.Item>
            <Form.Item
              name="recipientType"
              label="Recipients"
              rules={[
                {
                  required: true,
                  message: "Please select the recipients",
                },
              ]}
            >
              <Radio.Group onChange={getRecipientsCountData}>
                <Space direction="vertical">
                  <Radio value="all">All</Radio>
                  <Radio value="creators">Creators</Radio>
                  <Radio value="subscribers">Subscribers</Radio>
                  <Radio value="custom">Custom</Radio>
                  <Radio value="unread">Unread</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prev, current) =>
                prev.recipientType !== current.recipientType
              }
            >
              {({ getFieldValue }) => {
                const receivers = getFieldValue("recipientType");
                const forms = {
                  subscribers: (
                    <>
                      <Form.Item
                        name="creator"
                        label="Select Creator"
                        rules={[
                          {
                            required:
                              getFieldValue("recipientType") === "subscribers",
                            message: "Please select the creator",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          value={selectedCreator}
                          onSearch={(value) => getCreatorData(value)}
                          filterOption={false}
                          defaultActiveFirstOption={false}
                          onChange={setSelectedCreator}
                          loading={creatorsLoading}
                          allowClear
                        >
                          {creatorsOptions}
                        </Select>
                      </Form.Item>
                      <Form.Item name="mangos" label="Select Mangos">
                        <Select
                          mode="multiple"
                          loading={mangoLoading}
                          onChange={getRecipientsCountData}
                          allowClear
                          filterOption={(input, option) =>
                            option?.children
                              ?.toLowerCase()
                              ?.indexOf(input?.toLowerCase()) >= 0
                          }
                        >
                          {mangoOptions}
                        </Select>
                      </Form.Item>
                    </>
                  ),
                  custom: (
                    <>
                      <Form.Item
                        name="customCSV"
                        label="Upload CSV"
                        rules={[
                          {
                            required: getFieldValue("recipients") === "custom",
                            message: "Please upload the CSV",
                          },
                        ]}
                      >
                        <Upload
                          action={`${config?.baseApiUrl}/admin/upload-file-static`}
                          headers={{
                            Authorization: `Bearer ${localStorage.getItem(
                              "token"
                            )}`,
                          }}
                          onChange={({ file }) => {
                            form.setFieldsValue({
                              customCSV: file?.response?.result?.files[0],
                            });
                            getRecipientsCountData();
                          }}
                          accept=".csv"
                        >
                          <Button icon={<UploadOutlined />}>
                            Click to Upload
                          </Button>
                        </Upload>
                      </Form.Item>
                    </>
                  ),
                  unread: (
                    <>
                      <Form.Item name="campaignId" label="Select Campaign">
                        <Select
                          showSearch
                          onSearch={debounce(setCampaignSearch)}
                          allowClear
                          filterOption={false}
                          notFoundContent={
                            campaignsLoading ? <LoadingOutlined /> : null
                          }
                          onSelect={getRecipientsCountData}
                        >
                          {campaignOptions}
                        </Select>
                      </Form.Item>
                    </>
                  ),
                };
                return forms[receivers];
              }}
            </Form.Item>
            <Form.List
              name="testData"
              onChange={(val) => {
                console.log(val);
              }}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "email"]}
                        rules={[{ required: true, message: "Missing email" }]}
                        label="Test Email"
                      >
                        <Input type="email" />
                      </Form.Item>
                      <Form.List name={[name, "additionalData"]}>
                        {(additionalFields, functions) => (
                          <>
                            {additionalFields.map((values) => (
                              <Space
                                key={values.key}
                                style={{ display: "flex", marginBottom: 8 }}
                                align="baseline"
                              >
                                <Form.Item
                                  {...restField}
                                  name={[values.name, "key"]}
                                >
                                  <Input placeholder="Key" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[values.name, "value"]}
                                >
                                  <Input placeholder="Value" />
                                </Form.Item>
                                <MinusCircleOutlined
                                  onClick={() => functions.remove(values.name)}
                                />
                              </Space>
                            ))}
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => functions.add()}
                                block
                                icon={<PlusOutlined />}
                              >
                                Add Key/Value Data
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                      <MinusCircleOutlined
                        style={{
                          color: "red",
                        }}
                        onClick={() => remove(name)}
                      />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Test Data
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
          <Typography>
            <strong>Recipients Count:</strong>{" "}
            {recipientCountLoading ? <LoadingOutlined /> : recipientsCount}
          </Typography>
        </Space>
      </Drawer>
    </>
  );
};

RecipientSelector.propTypes = {
  html: PropTypes.string.isRequired,
  showRecipientSelector: PropTypes.bool.isRequired,
  setShowRecipientSelector: PropTypes.func.isRequired,
  setShowComposer: PropTypes.func.isRequired,
};

export default RecipientSelector;
