import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Divider, Space, Spin, Table, Typography, message } from "antd";
import moment from "moment";
import { getCreatorDetails } from "../api";

const CreatorDetailExpand = ({ creatorId }) => {
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleGetCreatorDetails = async () => {
    setLoading(true);
    try {
      const resp = await getCreatorDetails(creatorId);
      if (resp.code === 0) {
        setDetails(resp.result);
      } else {
        message.error(resp?.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const getMangoesTable = () => {
    const columns = [
      {
        title: "Mango Name",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Recurring Type",
        dataIndex: "recurringType",
        key: "recurringType",
      },
      {
        title: "Active Subscribers",
        dataIndex: "activeSubscribers",
        key: "activeSubscribers",
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (text, record) => `${record?.currency} ${text}`,
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text) => moment(text).format("DD MMM YYYY"),
      },
    ];

    return (
      <Table
        dataSource={details?.mangoes}
        columns={columns}
        pagination={{
          pageSize: 5,
        }}
        style={{
          width: "100%",
        }}
      />
    );
  };

  const getWithdrawalsTable = () => {
    const columns = [
      {
        title: "Withdrawal Id",
        dataIndex: "_id",
        key: "_id",
      },
      {
        title: "Amount",
        dataIndex: "netAmount",
        key: "netAmount",
      },
      {
        title: "Status",
        dataIndex: "approvalStatus",
        key: "approvalStatus",
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text) => moment(text).format("DD MMM YYYY"),
      },
    ];

    return (
      <Table
        dataSource={details?.withdrawals}
        columns={columns}
        pagination={{
          pageSize: 5,
        }}
        style={{
          width: "100%",
        }}
      />
    );
  };

  const getTierPaymentsTable = () => {
    const columns = [
      {
        title: "Order Id",
        dataIndex: "_id",
        key: "_id",
      },
      {
        title: "Tier",
        dataIndex: "tier",
        key: "tier",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (text, record) => `${record?.currency} ${text}`,
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text) => moment(text).format("DD MMM YYYY"),
      },
    ];
    return (
      <Table
        dataSource={details?.tierPayments}
        columns={columns}
        pagination={{
          pageSize: 5,
        }}
        style={{
          width: "100%",
        }}
      />
    );
  };

  useEffect(() => {
    handleGetCreatorDetails();
  }, []);

  return loading ? (
    <Spin />
  ) : (
    <>
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <Space direction="vertical">
          <Typography.Text strong>Basic Details:</Typography.Text>
          <Typography.Text>Name: {details?.name}</Typography.Text>
          <Typography.Text>Email: {details?.email}</Typography.Text>
          <Typography.Text>Phone: {details?.phone}</Typography.Text>
          <Typography.Text>Commission: {details?.tmCommission}</Typography.Text>
          <Typography.Text>
            Convenience: {details?.convenienceFee}
          </Typography.Text>
          {details?.amountDue ? (
            <Typography.Text>
              Amount Due: INR {details?.amountDue}
            </Typography.Text>
          ) : null}
          {details?.razorpayOnetimeComission ||
          details?.razorpayRecurringComission ||
          details?.stripeOnetimeComission ||
          details?.stripeRecurringComission ? (
            <>
              <Typography.Text strong>Payment Gateway Breakup</Typography.Text>
              <Typography.Text>
                Razorpay Onetime: {details?.razorpayOnetimeComission}
              </Typography.Text>
              <Typography.Text>
                Razorpay Recurring: {details?.razorpayRecurringComission}
              </Typography.Text>
              <Typography.Text>
                Stripe Onetime: {details?.stripeOnetimeComission}
              </Typography.Text>
              <Typography.Text>
                Stripe Recurring: {details?.stripeRecurringComission}
              </Typography.Text>
            </>
          ) : null}
          <Typography.Text>
            Total Earning: {details?.totalEarning}
          </Typography.Text>
        </Space>
        <Divider />
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          <Typography.Text strong>Mangoes: </Typography.Text>
          {getMangoesTable()}
        </Space>
        <Divider />
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          <Typography.Text strong>Withdrawals: </Typography.Text>
          {getWithdrawalsTable()}
        </Space>
        <Divider />
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          <Typography.Text strong>Tier Payments: </Typography.Text>
          {getTierPaymentsTable()}
        </Space>
      </Space>
    </>
  );
};

CreatorDetailExpand.propTypes = {
  creatorId: PropTypes.string.isRequired,
};

export default CreatorDetailExpand;
