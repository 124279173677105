import React, { useEffect, useState } from "react";
import { Table, Space, Typography, message, Col, Row, Select } from "antd";
import PropTypes from "prop-types";
import { DebounceInput } from "react-debounce-input";
import { getMailsByCampaign } from "../api";

const { Option } = Select;

const SentMailsTable = ({ campaignId, presentlyViewing, passedRecord }) => {
  const [sentMails, setSentMails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [openStatus, setOpenStatus] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);

  const getMailsData = async () => {
    setLoading(true);
    try {
      const resp = await getMailsByCampaign(
        campaignId,
        deliveryStatus,
        openStatus,
        search,
        page,
        limit
      );
      if (resp?.code === 0) {
        setSentMails(resp?.result?.data);
        setTotal(resp?.result?.total);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      message.error(err.message || "Something went wrong");
    }
    setLoading(false);
  };

  const linksTable = () => {
    const processedLinksData = Object.entries(
      passedRecord?.mailcampaign?.linkTracking
    ).map(([url, count]) => ({ url, count }));
    const columnsData = [
      {
        title: "URL",
        dataIndex: "url",
        key: "url",
      },
      {
        title: "Count",
        dataIndex: "count",
        key: "count",
      },
    ];
    return (
      <Table
        columns={columnsData}
        dataSource={processedLinksData}
        pagination={false}
        rowKey="url"
        style={{ marginTop: "10px" }}
      />
    );
  };

  const mailsTable = () => {
    const columnData = [
      {
        title: "To",
        dataIndex: "to",
        key: "to",
      },
      {
        title: "Delivery Status",
        dataIndex: "deliveryStatus",
        key: "deliveryStatus",
      },
      {
        title: "Open Status",
        dataIndex: "opened",
        key: "opened",
        render: (_, record) => (
          <Typography>{record?.opened ? "Yes" : "No"}</Typography>
        ),
      },
      {
        title: "Additional Data",
        dataIndex: "additionalData",
        key: "additionalData",
        render: (_, record) => (
          <>
            {Object.keys(record.additionalData).map(
              (key) =>
                ["number", "string"].includes(
                  typeof record?.additionalData[key]
                ) &&
                record?.additionalData[key] && (
                  <Typography>
                    <strong>{key}</strong>: {record?.additionalData[key]}
                  </Typography>
                )
            )}
          </>
        ),
      },
    ];
    return (
      <Table
        columns={columnData}
        dataSource={sentMails}
        pagination={{
          pageSize: limit,
          current: page,
          total,
          onChange: setPage,
          onShowSizeChange: (current, size) => setLimit(size),
        }}
        loading={loading}
      />
    );
  };

  const deliveryStatuses = [
    "delivered",
    "bounce",
    "dropped",
    "deferred",
    "blocked",
  ];

  useEffect(async () => {
    if (presentlyViewing === campaignId) {
      await getMailsData();
    }
  }, [search, page, limit, deliveryStatus, openStatus, presentlyViewing]);

  return (
    <>
      <Row
        style={{
          marginBottom: "10px",
        }}
      >
        {/* <Col span={12}></Col> */}
        <Col span={24}>
          <Space
            align="center"
            style={{
              paddingRight: "10px",
            }}
          >
            <DebounceInput
              style={{
                width: "400px",
              }}
              placeholder="Search by email"
              onChange={(e) => setSearch(e.target.value)}
            />
            <Select
              placeholder="Delivery status"
              allowClear
              onSelect={setDeliveryStatus}
            >
              {deliveryStatuses.map((status) => (
                <Option value={status}>{status}</Option>
              ))}
            </Select>
            <Select
              placeholder="Open status"
              allowClear
              onSelect={setOpenStatus}
              onClear={() => setOpenStatus("")}
            >
              <Option value="true">Yes</Option>
              <Option value="false">No</Option>
            </Select>
          </Space>
        </Col>
      </Row>
      {passedRecord?.mailcampaign?.linkTracking && (
        <Row
          style={{
            marginBottom: "10px",
          }}
        >
          <Typography>Links Statistics</Typography>
          <Col span={24}>{linksTable()}</Col>
        </Row>
      )}
      <Typography>Mail Details</Typography>
      {mailsTable()}
    </>
  );
};

SentMailsTable.propTypes = {
  campaignId: PropTypes.string.isRequired,
  presentlyViewing: PropTypes.string.isRequired,
  passedRecord: PropTypes.shape(),
};

export default SentMailsTable;
