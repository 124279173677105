import React, { useEffect, useState } from "react";
import moment from "moment";
import { DatePicker, Statistic, Card, Col, Row, Divider, message } from "antd";
import { getHomeWidgets } from "../api/index";
import "antd/dist/antd.css";
import "../../App.min.css";

const WhitelabelHome = () => {
  const [startDate, setStartDate] = useState(
    moment().add(-7, "days").startOf("day")
  );
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [dateRangeDetails, setDateRangeDetails] = useState({});
  const [todayDetails, setTodayDetails] = useState({});

  function onChange(date) {
    if (date) {
      setStartDate(date[0].startOf("day"));
      setEndDate(date[1].endOf("day"));
    } else {
      setStartDate(moment().add(-7, "days").startOf("day"));
      setEndDate(moment().endOf("day"));
    }
  }

  const fetchDateRangeEarningDetails = async (st, ed) => {
    try {
      const resp = await getHomeWidgets(st, ed, true);
      setDateRangeDetails(resp.result);
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  const fetchTodayEarningDetails = async () => {
    try {
      const resp = await getHomeWidgets(
        moment().startOf("day").toISOString(),
        moment().endOf("day").toISOString(),
        true
      );
      setTodayDetails(resp.result);
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  useEffect(async () => {
    try {
      await fetch("https://tagmango.com/get-cookie", {
        credentials: "include",
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchDateRangeEarningDetails(
      startDate.toISOString(),
      endDate.toISOString()
    );
    fetchTodayEarningDetails();
  }, [startDate, endDate]);

  return (
    <>
      <DatePicker.RangePicker
        onChange={onChange}
        value={[startDate, endDate]}
      />
      <br />
      <br />
      <Row
        gutter={[15, 15]}
        align="middle"
        justify="center"
        style={{
          marginBottom: "15px",
        }}
      >
        <Col span={6}>
          <Card
            className="widgetCard active"
            bodyStyle={{ position: "relative" }}
          >
            <Statistic
              title="Total Transaction Value"
              value={dateRangeDetails?.totalEarnings || 0}
              precision={2}
              valueStyle={{ color: "#3f8600" }}
              prefix={<span className="siteInr">&#8377;</span>}
            />
            <span
              style={{
                position: "absolute",
                right: 10,
                bottom: 5,
                textAlign: "right",
                fontSize: 11,
                color: "#bdbdbd",
                letterSpacing: "0.025em",
              }}
            >
              <span style={{ color: "#cf1322" }}>*</span> Without transactional
              costs
            </span>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className="widgetCard active"
            bodyStyle={{ position: "relative" }}
          >
            <Statistic
              title="Total Order"
              value={dateRangeDetails?.totalOrders}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className="widgetCard active"
            bodyStyle={{ position: "relative" }}
          >
            <Statistic
              title="Total Commission + Convenience"
              value={
                (dateRangeDetails?.totalCommission || 0) +
                (dateRangeDetails?.totalConvenience || 0)
              }
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              prefix={<span className="siteInr">&#8377;</span>}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className="widgetCard active"
            bodyStyle={{ position: "relative" }}
            // onClick={() =>
            //   showTotalEaring(startDate.toISOString(), endDate.toISOString())
            // }
          >
            <Statistic
              title="Total PG Charges"
              value={dateRangeDetails?.totalPaymentGatewayFees || 0}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              prefix={<span className="siteInr">&#8377;</span>}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={[15, 15]} align="middle" justify="center">
        <Col span={6}>
          <Card
            className="widgetCard active"
            bodyStyle={{ position: "relative" }}
          >
            <Statistic
              title="Total TM Earnings"
              value={
                (dateRangeDetails?.totalCommission || 0) +
                (dateRangeDetails?.totalConvenience || 0) -
                (dateRangeDetails?.totalPaymentGatewayFees || 0)
              }
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              prefix={<span className="siteInr">&#8377;</span>}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className="widgetCard active"
            bodyStyle={{ position: "relative" }}
          >
            <Statistic
              title="Total Refunds"
              value={dateRangeDetails?.totalRefundAmount || 0}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              prefix={<span className="siteInr">&#8377;</span>}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className="widgetCard active"
            bodyStyle={{ position: "relative" }}
          >
            <Statistic
              title="Total Affiliate Earnings"
              value={dateRangeDetails?.totalAffiliateCommission || 0}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              prefix={<span className="siteInr">&#8377;</span>}
            />
          </Card>
        </Col>
      </Row>
      <br />
      <br />
      <Divider />
      <h2>Todays Data </h2>
      <Row
        gutter={[15, 15]}
        align="middle"
        justify="center"
        style={{
          marginBottom: "15px",
        }}
      >
        <Col span={6}>
          <Card
            className="widgetCard active"
            bodyStyle={{ position: "relative" }}
          >
            <Statistic
              title="Total Transaction Value"
              value={todayDetails?.totalEarnings || 0}
              precision={2}
              valueStyle={{ color: "#3f8600" }}
              prefix={<span className="siteInr">&#8377;</span>}
            />
            <span
              style={{
                position: "absolute",
                right: 10,
                bottom: 5,
                textAlign: "right",
                fontSize: 11,
                color: "#bdbdbd",
                letterSpacing: "0.025em",
              }}
            >
              <span style={{ color: "#cf1322" }}>*</span> Without transactional
              costs
            </span>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className="widgetCard active"
            bodyStyle={{ position: "relative" }}
          >
            <Statistic
              title="Total Order"
              value={todayDetails?.totalOrders}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className="widgetCard active"
            bodyStyle={{ position: "relative" }}
          >
            <Statistic
              title="Total Commission + Convenience"
              value={
                (todayDetails?.totalCommission || 0) +
                (todayDetails?.totalConvenience || 0)
              }
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              prefix={<span className="siteInr">&#8377;</span>}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className="widgetCard active"
            bodyStyle={{ position: "relative" }}
            // onClick={() =>
            //   showTotalEaring(startDate.toISOString(), endDate.toISOString())
            // }
          >
            <Statistic
              title="Total PG Charges"
              value={todayDetails?.totalPaymentGatewayFees || 0}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              prefix={<span className="siteInr">&#8377;</span>}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={[15, 15]} align="middle" justify="center">
        <Col span={6}>
          <Card
            className="widgetCard active"
            bodyStyle={{ position: "relative" }}
          >
            <Statistic
              title="Total TM Earnings"
              value={
                (todayDetails?.totalCommission || 0) +
                (todayDetails?.totalConvenience || 0) -
                (todayDetails?.totalPaymentGatewayFees || 0)
              }
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              prefix={<span className="siteInr">&#8377;</span>}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className="widgetCard active"
            bodyStyle={{ position: "relative" }}
          >
            <Statistic
              title="Total Refunds"
              value={todayDetails?.totalRefundAmount || 0}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              prefix={<span className="siteInr">&#8377;</span>}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className="widgetCard active"
            bodyStyle={{ position: "relative" }}
          >
            <Statistic
              title="Total Affiliate Earnings"
              value={todayDetails?.totalAffiliateCommission || 0}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              prefix={<span className="siteInr">&#8377;</span>}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default WhitelabelHome;
