/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Typography, Row, Col, Card, Statistic, Tabs } from "antd";
import UnpaidTab from "./UnpaidTab";
import History from "./History";
import { getIncommingToPay } from "../api/index";
import "antd/dist/antd.css";
import "../../App.min.css";

const NonTransferredPayment = ({ isRestricted }) => {
  const dateDIff =
    (process.env.REACT_DATE_DIFF && parseInt(process.env, 10)) || 7;
  const [totalIncomming, setTotalIncomming] = useState(0);
  const [totalToPay, setTotalToPay] = useState(0);
  const [date, setDate] = useState(
    moment().add(-dateDIff, "days").startOf("day")
  );
  const [active, setActive] = useState("1");

  useEffect(async () => {
    const earningDetails = await getIncommingToPay(date);
    setTotalIncomming(
      (
        earningDetails?.result?.totalIncomming?.totalEarning +
        earningDetails?.result?.totalIncomming?.totalGst +
        earningDetails?.result?.totalIncomming?.totalWhatsapp
      ).toFixed(2)
    );

    setTotalToPay(
      (
        earningDetails?.result?.totalIncomming?.totalEarning * 0.9 -
        earningDetails?.result?.totalIncomming?.totalWhatsapp
      ).toFixed(2)
    );
  }, [date]);

  return (
    <>
      <Row gutter={[8, 8]} align="middle" justify="center">
        <Col span={6}>
          <Card
            className="widgetCard active"
            bodyStyle={{ position: "relative", textAlign: "center" }}
          >
            <Statistic
              title="Incomming"
              value={totalIncomming}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className="widgetCard active"
            bodyStyle={{ position: "relative", textAlign: "center" }}
          >
            <Statistic
              title="To Pay"
              value={totalToPay}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
        </Col>
      </Row>
      <Tabs
        defaultActiveKey="1"
        activeKey={active}
        onChange={(key) => {
          setActive(key);
        }}
      >
        <Tabs.TabPane
          tab={<Typography.Text className="tabTitle">Unpaid</Typography.Text>}
          key="1"
        >
          <UnpaidTab
            date={date}
            setDate={setDate}
            isRestricted={isRestricted}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<Typography.Text className="tabTitle">History</Typography.Text>}
          key="2"
        >
          <History date={date} setDate={setDate} />
          {/* <p>Page under construction...</p> */}
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default NonTransferredPayment;
