/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { DebounceInput } from "react-debounce-input";
import {
  Table,
  Space,
  Avatar,
  Typography,
  Button,
  Modal,
  Upload,
  message,
  Row,
  Col,
  Checkbox,
  DatePicker,
  AutoComplete,
  Input,
} from "antd";
import {
  HomeOutlined,
  LeftSquareOutlined,
  RightSquareOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import {
  getNonTransferredPayments,
  manualTransfer,
  uploadImage,
} from "../api/index";

import Loader from "../../Utils/Loader";
import {
  validImageExtensions,
  getFileExtension,
  getBase64,
} from "../../Utils/file";
import close from "../../Images/close.svg";
import "antd/dist/antd.css";
import "../../App.min.css";

const UnpaidTab = ({ date, setDate, isRestricted }) => {
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [userDetails, setUserDetails] = useState([]);
  const [notFoundContent, setNotFoundContent] = useState(false);
  const [page, setPage] = useState(1);
  const [file, setFile] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [manualPayForm, setManualPayForm] = useState(false);
  const [accountDetails, setAccountDetails] = useState(null);
  const [imgLoading, setImgLoading] = useState(false);
  const [manualTransectionId, setManualTransectionId] = useState(null);
  const [useMG, setUseMG] = useState(null);
  const [loading, setLoading] = useState(false);
  const [manualPayFormData, setManualPayFormData] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [orderIdList, setOrderIdList] = useState({});
  const [forceRefeshCount, setForceRefeshCount] = useState(0);
  const customFormat = (value) => `Before: ${value.format("DD-MM-YYYY")}`;

  useEffect(async () => {
    try {
      setLoader(true);
      const searchValue = searchQuery.length > 2 ? searchQuery : "";
      const earningDetails = await getNonTransferredPayments(
        page,
        date,
        searchValue
      );
      setLoader(false);
      if (earningDetails.code === 0) {
        if (searchValue && earningDetails?.result?.length === 0) {
          setNotFoundContent(true);
          return false;
        }
        setUserDetails(earningDetails?.result?.orders);
        setOrderIdList(
          earningDetails?.result?.orders.reduce((acc, eachOrder) => {
            acc[eachOrder?.earningsList[0].creatorId] =
              eachOrder?.earningsList.map((eachEarning) => eachEarning?._id);
            return acc;
          }, {})
        );
        setTotalPage(Math.ceil(earningDetails?.result?.orderCount / 100));
      } else if (earningDetails.code === 401 || earningDetails.status === 401) {
        localStorage.clear();
        history.push("/");
        earningDetails?.message
          ? message.error(earningDetails?.message)
          : message.error("Something went wrong");
      } else {
        earningDetails?.message
          ? message.error(earningDetails?.message)
          : message.error("Something went wrong");
      }
    } catch (error) {
      error?.message
        ? message.error(error?.message)
        : message.error("Something went wrong");
    }
    return () => {
      setUserDetails([]);
    };
  }, [page, searchQuery, date, forceRefeshCount]);

  const onSearch = (query) => {
    setNotFoundContent(false);
    setPage(1);
    setUserDetails([]);
    setSearchQuery(query);
  };

  const handleCheckbox = (creatorId, orderId) => {
    const updatedOrderList = { ...orderIdList };
    const index = orderIdList[creatorId].indexOf(orderId);
    if (index !== -1) {
      const newList = [...updatedOrderList[creatorId]];
      newList.splice(index, 1);
      updatedOrderList[creatorId] = newList;
    } else {
      updatedOrderList[creatorId] = [...updatedOrderList[creatorId], orderId];
    }
    setOrderIdList(updatedOrderList);
  };

  const manulaPayFormModal = () => {
    const setFileAndThumbnail = (imageFile, url) => {
      setImgLoading(false);
      setFile(imageFile);
      setImageUrl(url);
    };

    const handleManualPayAction = () => {
      if (!file) {
        message.error("Pay slip is required");
        return;
      }

      if (!manualTransectionId) {
        message.error("Manual TransectionID required");
        return;
      }
      const formData = new FormData();
      formData.append("image", file);
      setLoading(true);
      uploadImage(formData)
        .then((res) => {
          if (res.code === 0) {
            const fileUrl =
              res?.result?.compressed ||
              (res?.result && res?.result?.files && res?.result?.files[0]);
            const filterOrders = currentOrders.reduce((acc, eachOrder) => {
              if (
                orderIdList[eachOrder?.creatorId].indexOf(eachOrder?._id) !== -1
              ) {
                const filterObj = {
                  amount: eachOrder?.amount,
                  whatsappCharge: eachOrder?.whatsappCharge,
                  creatorId: eachOrder?.creatorId,
                  _id: eachOrder?._id,
                };
                acc.push(filterObj);
                return acc;
              }
              return acc;
            }, []);
            const req = {
              orders: filterOrders,
              paySlip: fileUrl,
              totalTransferredAmount: (
                totalAmount - (useMG ? accountDetails?.mgAmount : 0)
              ).toFixed(2),
              manualTransferId: manualTransectionId,
              creatorId: accountDetails?.creatorId,
            };
            if (useMG) {
              req.useMG = accountDetails?.mgAmount;
            }
            manualTransfer(req)
              .then(() => {
                message.success("Manual payment  completed successfully !!");
                setLoading(false);
                setManualPayForm(false);
                setManualTransectionId(null);
                setUseMG(false);
                setFileAndThumbnail(null, null);
                setTotalAmount(0);
                setForceRefeshCount(forceRefeshCount + 1);
                setAccountDetails(null);
              })
              .catch((error) => {
                setLoading(false);
                setManualPayForm(false);
                setFileAndThumbnail(null, null);
                setManualTransectionId(null);
                setUseMG(false);
                setAccountDetails(null);
                setTotalAmount(0);
                console.log(error);
                error?.message
                  ? message.error(error?.message || error?.result)
                  : message.error("Something went wrong");
              });
          }
        })
        .catch((error) => {
          setLoading(false);
          setManualPayForm(false);
          setManualTransectionId(null);
          setUseMG(false);
          setFileAndThumbnail(null, null);
          setAccountDetails(null);
          setTotalAmount(0);
          console.log(error);
          error?.message
            ? message.error(error?.message || error?.result)
            : message.error("Something went wrong");
        });
    };

    const beforeUpload = (inputfile) => {
      const isValidExtension =
        validImageExtensions.indexOf(inputfile.type) !== -1;

      if (!isValidExtension) {
        alert("You can only valid image type!");
        return;
      }

      const isValidSize = inputfile.size / 1024 / 1024 < 1024;
      if (!isValidSize) {
        alert("Image must be smaller than 1024MB!");
        return;
      }

      const type = getFileExtension(inputfile.name)[1].toLowerCase();

      setImgLoading(true);
      setImageUrl(null);
      if (isValidExtension && isValidSize) {
        // convertImage(type, inputfile, setFileAndThumbnail);
        getBase64(inputfile, (url) => {
          setFileAndThumbnail(inputfile, url);
        });
      } else {
        setFile(null);
      }
      return false;
    };

    return (
      <>
        <Row>
          <Col span={12}>
            <Typography style={{ fontWeight: 500, fontSize: 14 }}>
              Upload Pay Slip
            </Typography>
            <Upload
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              accept="image/*"
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="avatar"
                  id="imageID"
                  style={{
                    width: "100%",
                    maxHeight: "106px",
                    maxWidth: "106px",
                  }}
                />
              ) : (
                <div>
                  {imgLoading ? (
                    <LoadingOutlined style={{ zIndex: 1000 }} />
                  ) : (
                    <PlusOutlined />
                  )}
                  <div className="ant-upload-text">Upload</div>
                </div>
              )}
            </Upload>
          </Col>
          {accountDetails?.accountNumber ? (
            <Col span={12}>
              <Typography style={{ fontWeight: 500, fontSize: 14 }}>
                Account Name:
              </Typography>
              <span style={{ paddingLeft: "20px" }}>
                {accountDetails?.accountName || "No data found"}
              </span>
              <Typography style={{ fontWeight: 500, fontSize: 14 }}>
                Account Number:
              </Typography>
              <span style={{ paddingLeft: "20px" }}>
                {accountDetails?.accountNumber || "No data found"}
              </span>
              <Typography style={{ fontWeight: 500, fontSize: 14 }}>
                Bank Name:
              </Typography>
              <span style={{ paddingLeft: "20px" }}>
                {accountDetails?.bankName || "No data found"}
              </span>
              <Typography style={{ fontWeight: 500, fontSize: 14 }}>
                IFSC:
              </Typography>
              <span style={{ paddingLeft: "20px" }}>
                {accountDetails?.ifsc || "No data found"}
              </span>
            </Col>
          ) : (
            <span style={{ paddingLeft: "20px", color: "red" }}>
              Bank details not added !!
            </span>
          )}
        </Row>
        <Row>
          <Col>
            <Typography style={{ fontWeight: 500, fontSize: 14 }}>
              Order Count: {orderIdList[accountDetails?.creatorId]?.length}
            </Typography>
            <Typography style={{ fontWeight: 500, fontSize: 14 }}>
              Order total:{" "}
              {(totalAmount - (useMG ? accountDetails?.mgAmount : 0)).toFixed(
                2
              )}
            </Typography>
            {/* <Typography style={{ fontWeight: 500, fontSize: 14 }} >
              Order Count: {userDetails[accountDetails?.index].earningsList.reduce((acc, eachOrder) => {
                acc+ eachOrder.amount*0.9;
                return acc;
              }, 0)}
            </Typography> */}
          </Col>
          <Col>
            {/* {console.log(orderIdList[accountDetails?.creatorId])}
            {console.log(currentOrders)}
            <Typography style={{ fontWeight: 500, fontSize: 14 }} >
              Order Count: {currentOrders.reduce((acc, eachOrder) => {
                if(orderIdList[accountDetails?.creatorId].indexOf(eachOrder._id)) {
                  acc + 
                    (eachOrder?.amount && eachOrder?.amount* 0.9 || 0) +
                    (eachOrder?.gst || 0) +
                    (eachOrder?.whatsappCharge || 0)
                }
                return acc;
              }, 0)}
            </Typography> */}
          </Col>
        </Row>
        <Typography style={{ fontWeight: 500, fontSize: 14 }}>
          Manual TranserID:
        </Typography>
        <Input
          size="small"
          value={manualTransectionId}
          onChange={(event) => setManualTransectionId(event.target.value)}
        />
        {accountDetails?.mgAmount && (
          <Checkbox onChange={(e) => setUseMG(e.target.checked)}>
            User Available MG value: {accountDetails?.mgAmount}
          </Checkbox>
        )}
        <br />
        <br />
        <Button
          type="primary"
          className="siteBtn siteBtnPrimary"
          size="small"
          htmlType="submit"
          loading={loading}
          onClick={handleManualPayAction}
        >
          Manual Pay
        </Button>
      </>
    );
  };

  const handleCLick = (action) => {
    if (action === "home") {
      setPage(1);
    }

    if (action === "next") {
      setPage(page + 1);
    }

    if (action === "previous") {
      setPage(page - 1);
    }
  };

  const pageDisplay = () => {
    return (
      <p style={{ display: "contents" }}>
        Page {page} of {totalPage}
      </p>
    );
  };

  const displayUserDetails = () => {
    const handleOnChnage = (pagination) => {
      console.log(pagination);
    };

    const handleManualPay = (data) => {
      setCurrentOrders(data?.earningsList);
      setManualPayForm(true);
      setAccountDetails(data?.accountDetails);
      let total = 0;
      for (let i = 0; i < data?.earningsList?.length; i += 1) {
        if (orderIdList[data.key].indexOf(data?.earningsList[i]?._id) !== -1) {
          total =
            total +
            data?.earningsList[i]?.amount * 0.9 -
            data?.earningsList[i]?.whatsappCharge;
        }
      }
      setTotalAmount(total);
    };

    const expandedRowRender = (record) => {
      const columns = [
        {
          title: "Subscriber",
          dataIndex: "fanName",
          key: "fanName",
          render: (text, record) => (
            <Space size="small">
              <Avatar src={record.fanProfilePicUrl} size={32} />
              <Typography style={{ fontWeight: 500, fontSize: 14 }}>
                {text}
              </Typography>
            </Space>
          ),
        },
        {
          title: "Earning",
          dataIndex: "amount",
          key: "amount",
        },
        {
          title: "GST",
          dataIndex: "gst",
          key: "gst",
          render: (text, record) => (
            <Typography style={{ fontWeight: 500, fontSize: 14 }}>
              {(record?.gst || 0).toFixed(2)}
            </Typography>
          ),
        },
        {
          title: "WhatsApp",
          dataIndex: "whatsAppCharge",
          key: "whatsAppCharge",
          render: (text, record) => (
            <Typography style={{ fontWeight: 500, fontSize: 14 }}>
              {(record?.whatsappCharge || 0).toFixed(2)}
            </Typography>
          ),
        },
        {
          title: "Checked",
          dataIndex: "checked",
          key: "checked",
          render: (text, record) => (
            <Checkbox
              style={{ cursor: "none" }}
              checked={
                orderIdList[record?.creatorId].indexOf(record?.orderId) !== -1
              }
              onChange={() =>
                handleCheckbox(record?.creatorId, record?.orderId)
              }
            />
          ),
        },
        {
          title: "Razorpay Id",
          dataIndex: "razorpayId",
          key: "razorpayId",
          render: (text, record) => (
            <Typography style={{ fontWeight: 500, fontSize: 14 }}>
              {record?.razorpayOrderId || record?.razorpaySubscriptionId}
            </Typography>
          ),
        },
        {
          title: "Created At",
          dataIndex: "createdAt",
          key: "createdAt",
        },
      ];

      const data = record.earningsList.map((eachEarningsList) => {
        return {
          fanName: eachEarningsList?.fanName,
          fanProfilePicUrl: eachEarningsList?.fanProfilePicUrl,
          gst: eachEarningsList?.gst,
          whatsappCharge: eachEarningsList?.whatsappCharge,
          razorpayOrderId: eachEarningsList?.razorpayOrderId,
          razorpaySubscriptionId: eachEarningsList?.razorpaySubscriptionId,
          amount: eachEarningsList?.amount,
          createdAt: moment(eachEarningsList?.createdAt).format(
            "MMMM Do YYYY, h:mm:ss a"
          ),
          creatorId: eachEarningsList?.creatorId,
          orderId: eachEarningsList?._id,
        };
      });

      return (
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered
        />
      );
    };

    const tableData = userDetails.reduce((acc, eachDetails, index) => {
      return [
        ...acc,
        {
          key: eachDetails?._id?.creatorId,
          creatorName: eachDetails?._id?.creatorName,
          creatorProfilePicUrl: eachDetails?._id?.creatorProfilePicUrl,
          totalUntransferred: (eachDetails?.totalUntransferred * 0.9).toFixed(
            2
          ),
          totalEarning: eachDetails?.totalUntransferred,
          totalGST: eachDetails?.totalGST,
          totalWhatsappCharge: eachDetails?.totalWhatsappCharge,
          earningsList: eachDetails?.earningsList,
          accountDetails: {
            accountName: eachDetails?._id?.accountName,
            accountNumber: eachDetails?._id?.accountNumber,
            bankName: eachDetails?._id?.bankName,
            ifsc: eachDetails?._id?.ifsc,
            creatorId: eachDetails?._id?.creatorId,
            mgAmount: eachDetails?._id?.mgAmount,
            index,
          },
        },
      ];
    }, []);

    const columnData = [
      {
        title: "Creator Name",
        dataIndex: "creatorName",
        key: "creatorName",
        render: (text, record) => (
          <Space size="small">
            <Avatar src={record.creatorProfilePicUrl} size={32} />
            <Typography style={{ fontWeight: 500, fontSize: 14 }}>
              {text}
            </Typography>
          </Space>
        ),
      },
      {
        title: "Earning",
        dataIndex: "totalEarning",
        key: "totalEarning",
      },
      {
        title: "GST",
        dataIndex: "totalGST",
        key: "totalGST",
        render: (text, record) => (
          <Typography style={{ fontWeight: 500, fontSize: 14 }}>
            {(record?.totalGST || 0).toFixed(2)}
          </Typography>
        ),
      },
      {
        title: "Whatsapp",
        dataIndex: "totalWhatsappCharge",
        key: "totalWhatsappCharge",
        render: (text, record) => (
          <Typography style={{ fontWeight: 500, fontSize: 14 }}>
            {(record?.totalWhatsappCharge || 0).toFixed(2)}
          </Typography>
        ),
      },
      {
        title: "Unpaid Amount(after 10% Deduction)",
        dataIndex: "totalUntransferred",
        key: "totalUntransferred",
        render: (text, record) => (
          <Typography style={{ fontWeight: 500, fontSize: 14 }}>
            {record?.totalUntransferred - (record?.totalWhatsappCharge || 0)}
          </Typography>
        ),
      },
      {
        title: "No of Transaction",
        dataIndex: "noOfTransaction",
        key: "noOfTransaction",
        render: (text, record) => (
          <Typography style={{ fontWeight: 500, fontSize: 14 }}>
            {record?.earningsList?.length || 0}
          </Typography>
        ),
      },
    ];

    const actionData = {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Space size="small">
          <Button
            className="siteBtn siteBtnPrimary sm"
            onClick={() => handleManualPay(record)}
            style={{ fontSize: 13 }}
            disabled={
              !(orderIdList[record?.key] && orderIdList[record?.key].length)
            }
          >
            Manual Pay
          </Button>
        </Space>
      ),
    };

    return (
      <>
        <Table
          className="components-table-demo-nested"
          columns={isRestricted ? columnData : [...columnData, actionData]}
          dataSource={tableData}
          expandable={{ expandedRowRender }}
          pagination={{ pageSize: 100, hideOnSinglePage: true }}
          scroll={{ x: 600 }}
          bordered
          onChange={handleOnChnage}
        />
      </>
    );
  };

  const headers = [
    { label: "Creator Name", key: "_id.creatorName" },
    { label: "Number of pending transfers", key: "orderNum" },
    { label: "Total pending amount", key: "totalUntransferred" },
  ];

  return (
    <>
      {/* <CSVLink
        filename={`non_transferred_users_${new Date()}.csv`}
        data={userDetails && userDetails?.length > 0 ? userDetails : ''}
        headers={headers}>
        <Button>
          Export CSV
       </Button>
      </CSVLink> */}
      <Row gutter={[8, 8]} align="middle" justify="center">
        <Col span={6}>
          <DatePicker
            value={date}
            format={customFormat}
            disabledDate={(current) => current.isAfter(moment())}
            onChange={(date) => setDate(date.startOf("day"))}
          />
        </Col>
        <Col span={6}>
          <DebounceInput
            minLength={2}
            debounceTimeout={300}
            className="searchbar"
            style={{ width: "100%" }}
            placeholder="Search a creator"
            onChange={(event) => onSearch(event.target.value)}
          />
        </Col>
      </Row>
      {loader ? (
        <Loader />
      ) : (
        <>
          {userDetails?.length > 0 ? displayUserDetails() : null}
          {pageDisplay()}
          <div style={{ float: "right" }}>
            <Button
              type="primary"
              disabled={page === 1}
              icon={<LeftSquareOutlined />}
              onClick={() => handleCLick("previous")}
            />
            <Button
              type="primary"
              disabled={page === 1}
              icon={<HomeOutlined />}
              onClick={() => handleCLick("home")}
            />
            <Button
              type="primary"
              disabled={page === totalPage}
              icon={<RightSquareOutlined />}
              onClick={() => handleCLick("next")}
            />
          </div>
        </>
      )}
      <Modal
        title="Manual Pay Form"
        visible={manualPayForm}
        onCancel={() => {
          setManualPayForm(false);
          setManualPayFormData(null);
          setImgLoading(false);
          setManualTransectionId(null);
          setUseMG(false);
          setFile(null);
          setImageUrl(null);
          setTotalAmount(0);
        }}
        centered
        footer={null}
        maskClosable={false}
        // bodyStyle={{ padding: 0 }}
        closeIcon={<img src={close} alt="close" />}
      >
        {manulaPayFormModal()}
      </Modal>
    </>
  );
};

export default UnpaidTab;
