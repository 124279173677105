import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Divider,
  Modal,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import moment from "moment";
import fileDownload from "js-file-download";
import ProductForm from "./RefernEarnProductForm";
import {
  createRefernEarnProduct,
  editRefernEarnProduct,
  getRefernEarnPayouts,
  getRefernEarnProductSales,
  getRefernEarnSignups,
} from "../Pages/api";
import SalesTable from "./RefernEarnSalesTable";

const DetailsExpand = ({ userDetails }) => {
  const [productData, setProductData] = useState([]);
  const [payoutsData, setPayoutsData] = useState([]);
  const [signupsData, setSignupsData] = useState([]);
  const [signupsPage, setSignupsPage] = useState(1);
  const [signupsLimit, setSignupsLimit] = useState(10);
  const [signupsTotal, setSignupsTotal] = useState(0);
  const [signupsLoading, setSignupsLoading] = useState(false);
  const [productFormVisible, setProductFormVisible] = useState(false);
  const [salesTableVisible, setSalesTableVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const productFormRef = useRef(null);

  const handleFetchProductSales = async () => {
    try {
      const resp = await getRefernEarnProductSales(userDetails?._id);
      if (resp?.code === 0) {
        setProductData(resp.result);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  const handleFetchRefernEarnPayouts = async () => {
    try {
      const resp = await getRefernEarnPayouts(userDetails?._id);
      if (resp?.code === 0) {
        setPayoutsData(resp.result);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  const handleNewProductCreation = async () => {
    try {
      const values = await productFormRef.current.form.validateFields();
      const reqBody = {
        user: userDetails?._id,
        productType: "mango", // only allowing mango for now
        revenueShare: values.revenueShare,
        productName: values.productName,
        productLink: values.productLink,
        mango: values.mango,
      };
      const resp = await createRefernEarnProduct(reqBody);
      if (resp?.code === 0) {
        message.success("Product created successfully");
        setProductFormVisible(false);
        setSelectedProduct(null);
        handleFetchProductSales();
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  const handleEditProduct = async () => {
    try {
      const values = await productFormRef.current.form.validateFields();
      const reqBody = {
        revenueShare: values.revenueShare,
        productName: values.productName,
        productLink: values.productLink,
      };
      const resp = await editRefernEarnProduct(selectedProduct._id, reqBody);
      if (resp?.code === 0) {
        message.success("Product updated successfully");
        setProductFormVisible(false);
        setSelectedProduct(null);
        handleFetchProductSales();
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  const handleFetchSignups = async () => {
    setSignupsLoading(true);
    try {
      const resp = await getRefernEarnSignups(
        userDetails?._id,
        signupsPage,
        signupsLimit
      );
      if (resp?.code === 0) {
        setSignupsData(resp.result?.data);
        setSignupsTotal(resp.result?.total);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      setSignupsLoading(false);
    }
  };

  const handleSignupsCSVDownload = async () => {
    const hide = message.loading("Generating CSV...", 0);
    try {
      const resp = await getRefernEarnSignups(
        userDetails?._id,
        undefined,
        undefined,
        true
      );
      fileDownload(resp, `signups-${userDetails?._id}-${Date.now()}.csv`);
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      hide();
    }
  };

  useEffect(() => {
    if (userDetails) {
      handleFetchProductSales();
      handleFetchRefernEarnPayouts();
    }
  }, [userDetails]);

  useEffect(() => {
    if (userDetails) {
      handleFetchSignups();
    }
  }, [userDetails, signupsPage, signupsLimit]);

  const columns = [
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      render: (text, record) => (
        <Space direction="vertical">
          <Typography>{text}</Typography>
          <Typography>
            <strong>Link:</strong>{" "}
            <Typography.Link>{record?.productLink || "N/A"}</Typography.Link>
          </Typography>
        </Space>
      ),
    },
    {
      title: "Total Sale Value",
      dataIndex: "totalSaleValue",
      key: "totalSaleValue",
      render: (text) => text.toFixed(2),
    },
    {
      title: "Number of Sales",
      dataIndex: "numberOfSales",
      key: "numberOfSales",
    },
    {
      title: "Total Commission",
      dataIndex: "totalCommission",
      key: "totalCommission",
      render: (text) => text.toFixed(2),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space direction="vertical">
          <Button
            type="primary"
            block
            onClick={() => {
              setSelectedProduct(record);
              setSalesTableVisible(true);
            }}
          >
            View Sales
          </Button>
          <Button
            type="primary"
            block
            onClick={() => {
              setSelectedProduct(record);
              setProductFormVisible(true);
            }}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  const payoutsColumns = [
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("DD/MM/YYYY hh:mm A"),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => text.toFixed(2),
    },
    {
      title: "Additional GST",
      dataIndex: "additionalGst",
      key: "additionalGst",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            block
            onClick={() => {
              window.open(record.invoice, "_blank");
            }}
          >
            Download Invoice
          </Button>
        </Space>
      ),
    },
  ];

  const signupsColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Signed Up At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("DD/MM/YYYY hh:mm a"),
    },
  ];

  return (
    <>
      <Space direction="vertical">
        <Space direction="vertical">
          <Typography.Text strong>GST Details:</Typography.Text>
          {userDetails?.gstNumber ? (
            <>
              <Typography>{userDetails?.gstNumber}</Typography>
              <Typography>
                <strong>GST Registration Name:</strong>{" "}
                {userDetails?.gstRegistrationName}
              </Typography>
              <Typography>
                <strong>GST Registration Address:</strong>{" "}
                {userDetails?.gstRegistrationAddress}
              </Typography>
              <Typography>
                <strong>GST State:</strong> {userDetails?.gstState}
              </Typography>
            </>
          ) : (
            <Typography>N/A</Typography>
          )}
        </Space>
        <Space direction="vertical">
          <Typography.Text strong>Bank Details:</Typography.Text>
          {!userDetails?.accountNumber &&
          !userDetails?.internationalBankAccount ? (
            <Typography>N/A</Typography>
          ) : null}
          {userDetails?.accountNumber ? (
            <>
              <Typography>
                <strong>Account Number:</strong> {userDetails?.accountNumber}
              </Typography>
              <Typography>
                <strong>IFSC:</strong> {userDetails?.ifsc}
              </Typography>
              <Typography>
                <strong>Bank Name:</strong> {userDetails?.bankName}
              </Typography>
            </>
          ) : null}
          {userDetails?.internationalBankAccount ? (
            <>
              <Typography>
                <strong>Account Number: </strong>{" "}
                {userDetails?.internationalBankAccount?.account_number}
              </Typography>
              <Typography>
                <strong>Bank Name: </strong>{" "}
                {userDetails?.internationalBankAccount?.bank_name}
              </Typography>
              <Typography>
                <strong>Country: </strong>
                {userDetails?.internationalBankAccount?.country}
              </Typography>
            </>
          ) : null}
        </Space>
      </Space>
      <Divider />
      <Table columns={columns} dataSource={productData} />
      <Button type="primary" onClick={() => setProductFormVisible(true)}>
        Add Product
      </Button>
      <Divider />
      <Typography.Text strong>Payouts:</Typography.Text>
      <Table columns={payoutsColumns} dataSource={payoutsData} />
      <Divider />
      <Typography.Text strong>Signups:</Typography.Text>
      <Table
        columns={signupsColumns}
        dataSource={signupsData}
        pagination={{
          current: signupsPage,
          pageSize: signupsLimit,
          total: signupsTotal,
          onChange: (page) => setSignupsPage(page),
          onShowSizeChange: (_, limit) => setSignupsLimit(limit),
        }}
        loading={signupsLoading}
      />
      <Button
        onClick={() => {
          handleSignupsCSVDownload();
        }}
        type="primary"
      >
        Download Signups CSV
      </Button>
      <Modal
        visible={productFormVisible}
        title="Add/Edit Product"
        onCancel={() => {
          setProductFormVisible(false);
          setSelectedProduct(null);
        }}
        destroyOnClose
        onOk={() => {
          if (!selectedProduct) {
            handleNewProductCreation();
          } else {
            handleEditProduct();
          }
        }}
      >
        <ProductForm ref={productFormRef} product={selectedProduct} />
      </Modal>
      <Modal
        visible={salesTableVisible}
        title="Sales Table"
        onCancel={() => {
          setSalesTableVisible(false);
          setSelectedProduct(null);
        }}
        destroyOnClose
        width={1200}
      >
        <SalesTable product={selectedProduct} />
      </Modal>
    </>
  );
};

DetailsExpand.propTypes = {
  userDetails: PropTypes.shape(),
};

export default DetailsExpand;
