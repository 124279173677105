import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Form,
  AutoComplete,
  Typography,
  Avatar,
  Space,
  Select,
  message,
  Radio,
  Modal,
  Input,
  Col,
  Row,
  DatePicker,
  Button,
} from "antd";
import { createCoupon, getCoupon, getAllInfluencers, editCoupon } from "../api";

const CreateCouponForm = (props) => {
  const {
    formVisible,
    setFormVisible,
    toggleCreate,
    setToggleCreate,
    setSelectedRow,
    setEditMode,
    selectedRow,
    editMode,
  } = props;
  const [creatorId, setCreatorId] = useState(
    selectedRow && selectedRow?.creatorId
  );
  const [mango, setMango] = useState(selectedRow && selectedRow?.mangoId);
  const [tierValue, setTierValue] = useState([]);
  const [couponType, setCouponType] = useState(
    selectedRow && selectedRow?.coupontype
  );
  const [discountType, setDiscountType] = useState(
    selectedRow && selectedRow.discountType
  );
  const [discountAmount, setDiscountAmount] = useState(
    selectedRow && selectedRow.discountAmount
  );
  const [totalUsage, setTotalUsage] = useState(
    selectedRow && selectedRow.remainingusage
  );
  const [maximumamount, setMaximumAmount] = useState(
    selectedRow && selectedRow.maximum
  );
  const [minimumamonut, setMinimumAmount] = useState(
    selectedRow && selectedRow.minimum
  );
  const [startDate, setStartDate] = useState(
    selectedRow && moment(selectedRow?.startAt)
  );
  const [endDate, setEndDate] = useState(
    selectedRow && moment(selectedRow?.validTill)
  );
  const [couponCode, setCouponCode] = useState(
    editMode ? selectedRow && selectedRow?.couponcode : null
  );
  const [creatorquery, setCreatorQuery] = useState(
    selectedRow && selectedRow?.creator
  );
  const [loading, setLoading] = useState(false);
  const [couponcodeexists, setCoupCodeExists] = useState(false);
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const searchStyle = { width: "100%" };

  const { Option } = AutoComplete;
  const filterMango = (inputArr) => {
    return inputArr?.filter(
      (m) => !m.isPublic && !m.isStopTakingPayment && !m.isHidden
    );
  };
  const getCreatorSearch = () => {
    getAllInfluencers(creatorquery, 1)
      .then((response) => {
        if (response.code === 0) {
          if (response.result.length) {
            setUsers(response.result);
          }
        } else if (response.code === 100) {
          message.error("Something went wrong");
        }
      })
      .catch(() => {
        message.error("Something went wrong");
      });
  };

  useEffect(() => {
    getCreatorSearch();
  }, [creatorquery]);

  useEffect(() => {
    if (!creatorId) {
      form.setFieldsValue({ mango: null });
      setMango(null);
      return;
    }
    const creator = users.filter((m) => m._id === creatorId);

    const tierList = filterMango(creator[0]?.mangoes);
    setTierValue(tierList);
    if (tierList?.length === 1) {
      form.setFieldsValue({ mango: tierList[0]?._id });
      setMango(tierList[0]?._id);
    }
  }, [creatorId, users]);

  const checkCouponExists = async () => {
    try {
      const res = await getCoupon({ code: couponCode });
      if (res.code === 0) {
        if (res?.result?.length > 0) {
          message.error("Coupon Code Alreay Exists");
          setCoupCodeExists(true);
        } else setCoupCodeExists(false);
      } else setCoupCodeExists(false);
    } catch (err) {
      message.error("Something went wrong");
      setCoupCodeExists(false);
    }
  };

  const mangoPriceStyle = {
    color: "#f18926",
    fontSize: 18,
    marginRight: 6,
    fontFamily: "sans-serif",
  };
  const mangoTypeStyle = { fontSize: 16, color: "grey" };
  const onRadioChange = (e) => {
    setCouponType(e.target.value);
    if (e.target.value === "direct_subscription") {
      setDiscountType("percentageDiscount");
      setDiscountAmount(100);
      form.setFieldsValue({ discounttype: "percentageDiscount" });
      form.setFieldsValue({ amount: 100 });
    } else {
      setDiscountAmount(null);
      setDiscountType(null);
      form.setFieldsValue({ discounttype: null });
      form.setFieldsValue({ amount: null });
    }
  };

  const getLabel = (label) => {
    return (
      <Typography style={{ fontSize: 15, fontWeight: 500 }}>{label}</Typography>
    );
  };

  const onCancel = () => {
    setFormVisible(false);
    setSelectedRow(null);
    setEditMode(false);
    form.resetFields();
  };

  const cancelButtonStyle = { borderColor: "#f18926", color: "#f18926" };

  const generateFormSubmitBody = () => ({
    code: couponCode,
    type: couponType,
    startAt: startDate,
    validTill: endDate,
    ...(discountType === "percentageDiscount"
      ? { percentageDiscount: Number(discountAmount) }
      : {}),
    ...(discountType === "flatDiscount"
      ? { flatDiscount: Number(discountAmount) }
      : {}),
    ...(couponType === "creator_discount_coupon" ? { creator: creatorId } : {}),
    ...(couponType === "mango_coupon" ? { creator: creatorId, mango } : {}),
    ...(minimumamonut && minimumamonut !== "-"
      ? { minAmount: Number(minimumamonut) }
      : {}),
    ...(maximumamount && maximumamount !== "-"
      ? { maxAmount: Number(maximumamount) }
      : {}),
    ...(totalUsage && totalUsage !== "-"
      ? { remainingOrder: Number(totalUsage) }
      : {}),
  });

  const createCouponHandler = async () => {
    try {
      const couponexists = await getCoupon({ code: couponCode });
      if (couponexists.code === 0) {
        if (couponexists?.result?.length > 0) {
          message.error("Coupon Code Alreay Exists");
          setLoading(false);
          return;
        }
      }
      // const body = {
      //   code: couponCode,
      //   type: couponType,
      //   startAt: startDate,
      //   validTill: endDate,
      // };
      // if (discountType === "flatDiscount") body.flatDiscount = discountAmount;
      // else body.percentageDiscount = discountAmount;
      // if (couponType === "creator_discount_coupon") body.creator = creatorId;
      // else if (couponType === "mango_coupon") {
      //   body.creator = creatorId;
      //   body.mango = mango;
      // }
      // if (minimumamonut) body.minAmount = minimumamonut;
      // if (maximumamount) body.maxAmount = maximumamount;
      // if (totalUsage) body.remainingOrder = totalUsage;
      const res = await createCoupon(generateFormSubmitBody());
      if (res.code === 0) {
        message.success("Coupon Created Successfully!");
        setLoading(false);
        setFormVisible(false);
        form.resetFields();
        setSelectedRow(null);
        setToggleCreate(!toggleCreate);
      } else {
        setLoading(false);
        setFormVisible(false);
        form.resetFields();
        setSelectedRow(null);
      }
    } catch (err) {
      message.error("Something went wrong!");
      setLoading(false);
      setFormVisible(false);
      setSelectedRow(null);
      form.resetFields();
    }
  };

  const editCouponHandler = async () => {
    try {
      const res = await editCoupon(generateFormSubmitBody());
      if (res.code === 0) {
        message.success("Coupon Updated Successfully!");
        setLoading(false);
        setFormVisible(false);
        form.resetFields();
        setSelectedRow(null);
        setToggleCreate(!toggleCreate);
        setEditMode(false);
      } else {
        setLoading(false);
        setFormVisible(false);
        form.resetFields();
        setSelectedRow(null);
        setEditMode(false);
      }
    } catch (err) {
      message.error("Something went wrong!");
      setLoading(false);
      setFormVisible(false);
      setSelectedRow(null);
      form.resetFields();
      setEditMode(false);
    }
  };

  const handleOk = async () => {
    if (!couponType) {
      message.error("Please Select a Coupon Type!");
      return false;
    }
    if (couponcodeexists) {
      message.error("Coupon Code Alreay Exists");
      return false;
    }
    if (
      (couponType === "creator_discount_coupon" ||
        couponType === "mango_coupon") &&
      !creatorId
    ) {
      message.error("Please Select Creator!");
      return false;
    }
    if (couponType === "mango_coupon" && !mango) {
      message.error("Please Select Mango!");
      return false;
    }
    if (!couponCode) {
      message.error("Please Enter Coupon Code!");
      return false;
    }
    if (!discountType) {
      message.error("Please Select Discount Type!");
      return false;
    }
    if (!discountAmount) {
      message.error("Please Enter Discount Amount!");
      return false;
    }
    if (discountAmount <= 0) {
      message.error("Please Enter Valid Discount Amount!");
      return false;
    }
    if (discountType === "percentageDiscount" && discountAmount > 100) {
      message.error("Please Enter Valid Percentage Discount!");
      return false;
    }
    if (!startDate) {
      message.error("Please Select Coupon Start Date!");
      return false;
    }
    if (!endDate) {
      message.error("Please Select Coupon End Date!");
      return false;
    }

    if (startDate.toString() === endDate.toString()) {
      message.error("Start Date and End Date should not be same!");
      return false;
    }
    setLoading(true);
    if (!editMode) {
      createCouponHandler();
    } else {
      editCouponHandler();
    }
  };

  return (
    <Modal
      title="Create Coupon Form"
      visible={formVisible}
      destroyOnClose
      centered
      onCancel={onCancel}
      width={900}
      maskClosable={false}
      footer={[
        <Button
          key="cancel"
          type="default"
          style={cancelButtonStyle}
          onClick={onCancel}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          form="mailForm"
          htmlType="submit"
          loading={loading}
          type="primary"
          onClick={handleOk}
        >
          {editMode ? "Update" : "Create"}
        </Button>,
      ]}
    >
      <Form
        id="couponForm"
        layout="vertical"
        style={{ width: "100%" }}
        form={form}
      >
        <Form.Item name="coupontype" label={getLabel("Select Coupon Type")}>
          <Radio.Group
            onChange={onRadioChange}
            value={couponType}
            defaultValue={selectedRow ? selectedRow?.coupontype : null}
            disabled={editMode}
          >
            <Space direction="horizontal">
              <Radio value="creator_discount_coupon">
                Creator Discount Coupon
              </Radio>
              <Radio value="mango_coupon">Mango Coupon</Radio>
              <Radio value="direct_subscription">Direct Subscription</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        {couponType ? (
          <>
            {couponType !== "direct_subscription" ? (
              <Form.Item name="creator" label={getLabel("Select Creator")}>
                <AutoComplete
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  allowClear
                  defaultValue={selectedRow && selectedRow?.creator}
                  onSelect={(value, record) => {
                    setCreatorId(record.key);
                    setMango(null);
                    form.setFieldsValue({ mango: null });
                  }}
                  onSearch={(value) => setCreatorQuery(value)}
                  placeholder="Enter Creator"
                  onChange={(value) => {
                    if (!value) {
                      setMango(null);
                      setTierValue([]);
                      setCreatorId(null);
                    }
                  }}
                  style={searchStyle}
                  disabled={editMode}
                >
                  {/* <Option>All</Option> */}
                  {users?.map((eachDetails) => (
                    <Option
                      key={eachDetails?._id}
                      value={eachDetails?.name}
                      disabled={editMode}
                    >
                      <Space size="small">
                        <Avatar src={eachDetails?.profilePicUrl} size={32} />
                        <Typography
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            marginLeft: 6,
                          }}
                        >
                          {eachDetails?.name}
                        </Typography>
                      </Space>
                    </Option>
                  ))}
                </AutoComplete>
              </Form.Item>
            ) : null}
            {couponType === "mango_coupon" ? (
              <Form.Item name="mango" label={getLabel("Select Mango")}>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Enter Mango"
                  allowClear
                  size="large"
                  notFoundContent={null}
                  value={mango}
                  defaultValue={mango}
                  onChange={(value) => setMango(value)}
                  listItemHeight={10}
                  listHeight={250}
                  disabled={editMode}
                >
                  {tierValue?.map((item) => (
                    <Select.Option value={item._id} key={item._id}>
                      <div style={{ padding: "7.5px 0" }}>
                        <Typography.Title
                          style={{
                            fontWeight: 500,
                            fontSize: 15,
                          }}
                        >
                          {item.title}
                        </Typography.Title>
                        <span className="inputRupee" style={mangoPriceStyle}>
                          &#8377;
                          {item.price}
                        </span>
                        <span style={mangoTypeStyle}>{item.recurringType}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : null}
            <Form.Item name="couponcode" label={getLabel("Coupon Code")}>
              <Input
                style={{ width: "100%" }}
                value={couponCode}
                defaultValue={couponCode}
                autocomplete="off"
                type="string"
                min={1}
                placeholder="Enter Coupon Code"
                onChange={(e) => {
                  setCouponCode(e.target.value);
                }}
                onBlur={checkCouponExists}
                disabled={editMode}
              />
            </Form.Item>
            <Form.Item
              name="discounttype"
              label={getLabel("Select Discount Type")}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Select Discount Type"
                allowClear
                size="large"
                notFoundContent={null}
                value={discountType}
                defaultValue={discountType}
                onChange={(value) => setDiscountType(value)}
                listItemHeight={10}
                listHeight={250}
                // disabled={couponType === "direct_subscription"}
              >
                <Select.Option value="flatDiscount" key="flatDiscount">
                  Flat Discount
                </Select.Option>
                <Select.Option
                  value="percentageDiscount"
                  key="percentageDiscount"
                >
                  Percentage Discount
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="amount" label={getLabel("Discount Amount")}>
              <Input
                style={{ width: "100%" }}
                value={discountAmount}
                defaultValue={discountAmount}
                autocomplete="off"
                type="number"
                min={1}
                placeholder="Enter Discount Amount"
                prefix={
                  discountType === "flatDiscount" ? (
                    <span
                      className="inputRupee"
                      style={{ fontFamily: "sans-sarif" }}
                    >
                      &#8377;
                    </span>
                  ) : (
                    <span
                      className="inputRupee"
                      style={{ fontFamily: "sans-sarif" }}
                    >
                      %
                    </span>
                  )
                }
                onChange={(e) => {
                  setDiscountAmount(e.target.value);
                }}
                // disabled={couponType === "direct_subscription"}
              />
            </Form.Item>
            <Form.Item name="totalusage" label={getLabel("Total Usages")}>
              <Input
                style={{ width: "100%" }}
                value={totalUsage}
                defaultValue={totalUsage}
                type="number"
                min={1}
                autocomplete="off"
                placeholder="Enter Total Usages (optional)"
                onChange={(e) => {
                  setTotalUsage(e.target.value);
                }}
              />
            </Form.Item>
            <Row
              gutter={[
                { xs: 0, md: 15 },
                { xs: 15, md: 0 },
              ]}
            >
              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  name="minimumamount"
                  label={getLabel("Minimum Amount")}
                >
                  <Input
                    style={{ width: "100%" }}
                    value={minimumamonut}
                    defaultValue={minimumamonut}
                    type="number"
                    min={1}
                    autocomplete="off"
                    placeholder="Enter Minimum Mango Price (optional)"
                    onChange={(e) => {
                      setMinimumAmount(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  name="maximumamount"
                  label={getLabel("Maximum Amount")}
                >
                  <Input
                    style={{ width: "100%" }}
                    value={maximumamount}
                    defaultValue={maximumamount}
                    min={1}
                    type="number"
                    autocomplete="off"
                    placeholder="Enter Maximum Mango Price (optional)"
                    onChange={(e) => {
                      setMaximumAmount(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row
              gutter={[
                { xs: 0, md: 15 },
                { xs: 15, md: 0 },
              ]}
            >
              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  name="startdate"
                  label={getLabel("Coupon Start Date")}
                >
                  <DatePicker
                    size="large"
                    className="vcDateInput"
                    suffixIcon={null}
                    style={{ width: "100%" }}
                    allowClear
                    placeholder="Start date"
                    format="ddd, MMM Do, YYYY"
                    value={startDate}
                    defaultValue={startDate}
                    disabledDate={(selectedDate) =>
                      selectedDate <= moment(new Date()).startOf("day")
                    }
                    onChange={(selectedDate) =>
                      setStartDate(moment(selectedDate).startOf("day"))
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <Form.Item name="enddate" label={getLabel("Coupon End Date")}>
                  <DatePicker
                    size="large"
                    className="vcDateInput"
                    suffixIcon={null}
                    style={{ width: "100%" }}
                    allowClear
                    placeholder="End date"
                    format="ddd, MMM Do, YYYY"
                    disabledDate={(selectedDate) =>
                      selectedDate <= moment(startDate).startOf("day")
                    }
                    value={endDate}
                    defaultValue={endDate}
                    onChange={(selectedDate) =>
                      setEndDate(moment(selectedDate).endOf("day"))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : null}
      </Form>
    </Modal>
  );
};

CreateCouponForm.propTypes = {
  formVisible: PropTypes.bool,
  setFormVisible: PropTypes.func,
  setSelectedRow: PropTypes.func,
  setToggleCreate: PropTypes.func,
  setEditMode: PropTypes.func,
  toggleCreate: PropTypes.bool,
  selectedRow: PropTypes.shape({
    coupontype: PropTypes.string,
    creatorId: PropTypes.string,
    creator: PropTypes.string,
    mangoId: PropTypes.string,
    couponcode: PropTypes.string,
    discountAmount: PropTypes.number,
    discountType: PropTypes.string,
    remainingusage: PropTypes.number,
    startAt: PropTypes.string,
    validTill: PropTypes.string,
    minimum: PropTypes.number,
    maximum: PropTypes.number,
  }),
  editMode: PropTypes.bool,
};

export default CreateCouponForm;
