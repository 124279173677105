import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { Form, Input, Typography } from "antd";

const CreateConfigForm = ({ config }, ref) => {
  const formFields = [
    {
      name: "whatsappApiKey",
      label: "Whatsapp Api Key",
    },
    {
      name: "whatsappAccountId",
      label: "Whatsapp Account Id",
    },
    {
      name: "whatsappSecretKey",
      label: "Whatsapp Secret Key",
    },
    {
      name: "whatsappChannelId",
      label: "Whatsapp Channel Id",
    },
    {
      name: "whatsappBrandName",
      label: "Whatsapp Brand Name",
    },
  ];

  const [form] = Form.useForm();

  useEffect(() => {
    if (config) {
      form.setFieldsValue(config);
    } else {
      form.resetFields();
    }
  }, [config]);

  useImperativeHandle(ref, () => ({
    form,
  }));

  return (
    <>
      <Form form={form}>
        {formFields.map((field) => (
          <Form.Item {...field} key={field.name}>
            <Input />
          </Form.Item>
        ))}
      </Form>
      {config?.configType === "default" && (
        <Typography>This is a Default Config</Typography>
      )}
    </>
  );
};

CreateConfigForm.propTypes = {
  config: PropTypes.shape({
    whatsappApiKey: PropTypes.string,
    whatsappAccountId: PropTypes.string,
    whatsappSecretKey: PropTypes.string,
    whatsappChannelId: PropTypes.string,
    whatsappBrandName: PropTypes.string,
    configType: PropTypes.string,
  }),
};

export default forwardRef(CreateConfigForm);
