import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  DatePicker,
  Table,
  Space,
  Typography,
  Avatar,
  Checkbox,
  message,
  Col,
  Row,
  Pagination,
} from "antd";
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { DebounceInput } from "react-debounce-input";
import { getCreatorsEarnings } from "../api";
import PaymentTermsAction from "./PaymentTermsActions";
import PayoutHistoryTable from "./PayoutHistoryTable";

const PaymentTerms = () => {
  const history = useHistory();
  const [creators, setCreators] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(
    moment().add(-7, "days").startOf("day")
  );
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [totalPages, setTotalPages] = useState(0);
  const [showLifetimeEarnings, setShowLifetimeEarnings] = useState(false);
  const [presentlyViewingCreator, setPresentlyViewingCreator] = useState("");

  const controlsElementsStyle = {
    display: "flex",
    width: "100%",
    marginBottom: "8px",
  };

  const errorHandle = (error) => {
    if (error.status === 401 || error.code === 401) {
      localStorage.clear();
      history.push("/");
      return error?.message || error?.result
        ? message.error(error?.message || error?.result)
        : message.error("Something went wrong");
    }
    console.log(error);
    return error?.message || error?.result
      ? message.error(error?.message || error?.result)
      : message.error("Something went wrong");
  };

  useEffect(async () => {
    setLoading(true);
    try {
      const resp = await getCreatorsEarnings(
        startDate.toISOString(),
        endDate.toISOString(),
        search,
        null,
        false,
        page
      );
      if (resp?.code === 0) {
        setCreators(resp.result.creators);
        setTotalPages(Math.ceil(resp.result.creatorCount / 100));
        setLoading(false);
      } else {
        message.error(resp?.message || "Something went wrong");
        setLoading(false);
      }
    } catch (err) {
      errorHandle(err);
    }
  }, [page, search, startDate, endDate]);

  useEffect(() => {
    if (showLifetimeEarnings) {
      setStartDate(moment("2020-12-28 14:30:14.755Z").startOf("day"));
    } else {
      setStartDate(moment().add(-7, "days").startOf("day"));
    }
  }, [showLifetimeEarnings]);

  const creatorsTable = () => {
    const columnData = [
      {
        title: "Creator",
        dataIndex: "_id",
        key: "_id",
        render: (_, record) => (
          <Space size="small">
            <Avatar src={record?.profilePicUrl} size={32} />
            <Typography
              style={{ fontWeight: 500, fontSize: 14, color: "#1890ff" }}
            >
              {record?.name}
            </Typography>
          </Space>
        ),
      },
      {
        title: "Mangoes",
        dataIndex: "nMangos",
        key: "nMangos",
      },
      {
        title: "Earnings",
        dataIndex: "earning",
        key: "earning",
      },
      {
        title: "Orders",
        dataIndex: "totalOrders",
        key: "totalOrders",
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        render: (_, record) => <PaymentTermsAction creator={record?._id} />,
      },
    ];
    return (
      <>
        <div
          style={{
            display: "grid",
          }}
        >
          {loading ? (
            <LoadingOutlined
              style={{
                fontSize: "50px",
                alignSelf: "center",
                justifySelf: "center",
              }}
            />
          ) : (
            <div>
              <Table
                columns={columnData}
                dataSource={creators}
                pagination={{ pageSize: 100, hideOnSinglePage: true }}
                rowKey={(record) => record._id}
                expandable={{
                  expandedRowRender: (record) => (
                    <PayoutHistoryTable
                      creator={record?._id}
                      presentlyViewing={presentlyViewingCreator}
                    />
                  ),
                  onExpand: (expanded, record) =>
                    expanded ? setPresentlyViewingCreator(record?._id) : null,
                }}
              />
              <Pagination
                current={page}
                pageSize={100}
                pageSizeOptions={["100"]}
                total={totalPages * 100}
                onChange={(value) => {
                  setPage(value);
                }}
              />
            </div>
          )}
        </div>
      </>
    );
  };
  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: "16px",
          width: "100%",
        }}
      >
        <Col
          span={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            flexDirection: "column",
          }}
        >
          <div style={controlsElementsStyle}>
            <DebounceInput
              minLength={2}
              debounceTimeout={300}
              className="searchbar"
              style={{ width: "100%", marginBottom: "4px" }}
              placeholder="Search"
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginBottom: "8px",
              justifyContent: "center",
            }}
          >
            <DatePicker.RangePicker
              disabled={showLifetimeEarnings}
              onChange={(date) => {
                if (date) {
                  setStartDate(date[0].startOf("day"));
                  setEndDate(date[1].endOf("day"));
                } else {
                  setStartDate(moment().add(-7, "days").startOf("day"));
                  setEndDate(moment().endOf("day"));
                }
              }}
              value={[startDate, endDate]}
            />
          </div>
          <div
            style={{
              ...controlsElementsStyle,
              alignItems: "center",
            }}
          >
            <Checkbox
              style={{ width: "50%" }}
              onChange={() => {
                setShowLifetimeEarnings(!showLifetimeEarnings);
              }}
            >
              Show Lifetime Earnings
            </Checkbox>
          </div>
        </Col>
      </Row>
      {creatorsTable()}
    </>
  );
};

export default PaymentTerms;
