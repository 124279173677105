/* eslint-disable no-unused-vars */
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  message,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import { getDateRangeEarningV2 } from "../api";

const currencyMappings = {
  INR: "₹",
  USD: "$",
  EUR: "€",
};

const Refunds = () => {
  const [refunds, setRefunds] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [refundsLoading, setRefundsLoading] = useState(false);
  const [totalRefundValue, setTotalRefundValue] = useState(0);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(
    moment().add(-7, "days").startOf("day")
  );
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const debouncedSearch = useDebounce({
    value: search,
    callback: () => setPage(1),
  });

  const getRefundsData = async () => {
    setRefundsLoading(true);
    const searchFields = [
      "creatorName",
      "fanName",
      "fanEmail",
      "fanPhone",
      "mangoName",
    ];
    try {
      const resp = await getDateRangeEarningV2(
        startDate.toISOString(),
        endDate.toISOString(),
        debouncedSearch,
        searchFields,
        "refunded",
        undefined,
        undefined,
        false,
        page
      );
      if (resp?.code === 0) {
        setRefunds(resp?.result?.orders);
        setTotal(resp?.result?.orderCount);
        setTotalRefundValue(resp?.result?.totalRefund);
      } else {
        message.error(resp?.message || "Something went wrong");
      }
    } catch (err) {
      message.error(err?.message || "Something went wrong");
    } finally {
      setRefundsLoading(false);
    }
  };

  const refundsTable = () => {
    const nameStyle = { fontWeight: 500, fontSize: 14, color: "#1890ff" };
    const columns = [
      {
        title: "Order ID",
        dataIndex: "_id",
        key: "_id",
      },
      {
        title: "Creator",
        dataIndex: "creatorName",
        key: "creatorName",
        render: (text, record) => (
          <Space size="small" direction="vertical">
            <Avatar src={record?.creatorProfilePicUrl} size={32} />
            <Typography style={nameStyle}>{record?.creatorName}</Typography>
            <Typography>
              (Refund Rate: {record?.refundRate || "N/A"})
            </Typography>
            <Typography>{record?.mangoName}</Typography>
          </Space>
        ),
      },
      {
        title: "Subscriber",
        dataIndex: "fanName",
        key: "fanName",
        render: (text, record) => (
          <Space size="small" direction="vertical">
            <Avatar src={record?.fanImg} size={32} />
            <Typography style={nameStyle}>{record?.fanName}</Typography>
            <Typography
              style={{
                fontWeight: 300,
                fontSize: 14,
              }}
            >
              {record?.fanEmail}
            </Typography>
            <Typography
              style={{
                fontWeight: 300,
                fontSize: 14,
              }}
            >
              {record?.fanPhone}
            </Typography>
            <Typography
              style={{
                fontWeight: 300,
                fontSize: 14,
              }}
            >
              Country: {record?.fanCountry ? record?.fanCountry : "N/A"}
            </Typography>
          </Space>
        ),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (text, record) => (
          <Space size="small" direction="vertical">
            <Typography.Text strong>Order Details:</Typography.Text>
            <Typography>
              {currencyMappings[record?.subscriberCurrency || "INR"]}
              {record?.amount}
            </Typography>
            {record?.gst ? (
              <Typography>
                <strong>GST: </strong>
                {currencyMappings[record?.subscriberCurrency || "INR"]}
                {record?.gst}
              </Typography>
            ) : null}
            {record?.convenienceFee ? (
              <Typography>
                <strong>Convenience Fee:</strong>
                {currencyMappings[record?.subscriberCurrency || "INR"]}
                {record?.convenienceFee}
              </Typography>
            ) : null}
            <Typography>
              <strong>Comission charged: </strong>
              {currencyMappings[record?.subscriberCurrency || "INR"]}
              {
                record[(record?.subscriberCurrency || "INR")?.toLowerCase()]
                  ?.commissionCharged
              }
            </Typography>
            <Typography.Text strong>Actual Refund Details:</Typography.Text>
            <Typography>
              {currencyMappings.INR} {record?.refundAmount?.inrAmount}
            </Typography>
          </Space>
        ),
      },
      {
        title: "Order Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text, record) => (
          <Typography>
            {moment(record?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
          </Typography>
        ),
      },
      {
        title: "Order Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) => (
          <Typography>
            {record?.refundState
              ? `${
                  record?.refundState === "completed"
                    ? record?.status
                    : `Refund ${record?.refundState}`
                }`
              : record?.status}
          </Typography>
        ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        render: (text, record) => (
          <Space size="small" direction="vertical">
            {record?.invoice && (
              <Button
                type="primary"
                href={record?.invoice}
                target="_blank"
                block
              >
                Invoice
              </Button>
            )}
          </Space>
        ),
      },
    ];
    return (
      <>
        <div>
          <Table
            columns={columns}
            dataSource={refunds}
            loading={refundsLoading}
            pagination={{
              current: page,
              pageSize: 100,
              onChange: setPage,
              total,
              showSizeChanger: false,
              showTotal: (t) => `Total ${t} orders`,
            }}
          />
        </div>
      </>
    );
  };

  useEffect(() => {
    getRefundsData();
  }, [page, debouncedSearch, startDate, endDate]);

  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: "16px",
          width: "100%",
        }}
      >
        <Col span={12}>
          <Card title="Total Refund Value" bordered={false}>
            <Typography>₹ {totalRefundValue?.toFixed(2)}</Typography>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Total Orders" bordered={false}>
            <Typography>{total}</Typography>
          </Card>
        </Col>
      </Row>
      <Row
        style={{
          marginBottom: "8px",
        }}
      >
        <Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", width: "100%" }}>
            <Input
              value={search}
              minLength={2}
              className="searchbar"
              style={{ width: "100%" }}
              placeholder="Search"
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
        </Col>
        <Col span={12}>
          <Space
            style={{
              width: `100%`,
              justifyContent: "flex-end",
            }}
          >
            <DatePicker.RangePicker
              onChange={(date) => {
                if (date) {
                  setStartDate(date[0].startOf("day"));
                  setEndDate(date[1].endOf("day"));
                } else {
                  setStartDate(moment().add(-7, "days").startOf("day"));
                  setEndDate(moment().endOf("day"));
                }
              }}
              value={[startDate, endDate]}
            />
            <Button type="primary">Download CSV</Button>
          </Space>
        </Col>
      </Row>
      {refundsTable()}
    </>
  );
};

export default Refunds;
