import React, { useState, useEffect } from "react";
import {
  message,
  Button,
  Modal,
  DatePicker,
  Form,
  InputNumber,
  Input,
  Upload,
} from "antd";
import moment from "moment";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { sendCertificateMails } from "../api";

const SendCertificatesAction = ({
  creatorName,
  mango,
  mangoName,
  startDate,
  onSuccessfulSend,
}) => {
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [sendingCertificates, setSendingCertificates] = useState(false);
  const [testMode, setTestMode] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (showApprovalModal) {
      form.setFieldsValue({
        creatorName,
        mangoName,
        startDate: moment(new Date(startDate), "DD/MM/YYYY"),
        endDate: moment(new Date(), "DD/MM/YYYY"),
        hours: 1,
      });
    }
  }, [showApprovalModal]);

  useEffect(() => {
    if (testMode) {
      message.warn(
        "Please note that this is a test mode. No emails will be sent. Until test emails field is cleared"
      );
    } else {
      message.warn("Test mode is off. Emails will be sent.");
    }
  }, [testMode]);

  const constructCertificateFormData = (values) => {
    console.log(values);
    const formData = new FormData();
    formData.append("mango", mango);
    formData.append("mangoName", values?.mangoName);
    formData.append("creatorName", values?.creatorName);
    formData.append("startDate", values?.startDate.toISOString());
    formData.append("endDate", values?.endDate.toISOString());
    formData.append("hours", values?.hours);
    if (testMode) {
      formData.append("testEmails", values?.testEmails);
    }
    if (values?.signature) {
      formData.append("signature", values?.signature?.file);
    }
    return formData;
  };

  const sendCertificates = async (values) => {
    setSendingCertificates(true);
    try {
      const formData = constructCertificateFormData(values);
      const res = await sendCertificateMails(formData);
      if (res.code === 0) {
        message.success("Certificates sent successfully!");
        onSuccessfulSend(mango);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error(err?.message || err?.result || "Something went wrong");
    }
    setSendingCertificates(false);
    form.resetFields();
    setShowApprovalModal(false);
  };

  const sendTestMails = async () => {
    try {
      const formData = constructCertificateFormData(form.getFieldsValue());
      const res = await sendCertificateMails(formData, true);
      if (res.code === 0) message.success("Test emails sent successfully!");
      else message.error(res?.message || "Something went wrong");
    } catch (err) {
      console.log(err);
      message.error(err?.message || "Something went wrong");
    }
  };

  return (
    <>
      <Modal
        title="Send Certificates Mails?"
        visible={showApprovalModal}
        onCancel={() => {
          setShowApprovalModal(false);
        }}
        footer={[
          <Button onClick={sendTestMails}>Send Test Mail</Button>,
          <Button
            form="send-certificates-form"
            type="primary"
            htmlType="submit"
            disabled={testMode}
          >
            Send Certificates
          </Button>,
        ]}
      >
        <Form
          onFinish={sendCertificates}
          form={form}
          id="send-certificates-form"
          onChange={() => {
            const values = form.getFieldsValue();
            setTestMode(values?.testEmails?.length > 0);
          }}
        >
          <Form.Item
            name="creatorName"
            label="Creator Name"
            rules={[
              {
                required: true,
                message: "Please input creator name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="mangoName"
            label="Mango Name"
            rules={[
              {
                required: true,
                message: "Please input mango name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="startDate"
            label="Start Date"
            rules={[
              {
                required: true,
                message: "Please input start date",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="endDate"
            label="Issue Date"
            rules={[
              {
                required: true,
                message: "Please input issue date",
              },
              {
                validator: (_, value) => {
                  if (value && value.isAfter(form.getFieldValue("startDate"))) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Issue date should be after start date")
                  );
                },
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="hours"
            label="Hours"
            rules={[
              {
                required: true,
                message: "Please input hours",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item name="signature" label="Signature">
            <Upload
              accept={[".png", ".jpg", ".jpeg"]}
              beforeUpload={() => false}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item name="testEmails" label="Test Emails">
            <Input placeholder="Comma separated list of test email addresses" />
          </Form.Item>
        </Form>
      </Modal>
      {sendingCertificates ? (
        <LoadingOutlined />
      ) : (
        <Button
          type="primary"
          disabled={!mango}
          onClick={() => {
            setShowApprovalModal(true);
          }}
        >
          Send Certificates
        </Button>
      )}
    </>
  );
};

SendCertificatesAction.propTypes = {
  mango: PropTypes.string.isRequired,
  mangoName: PropTypes.string.isRequired,
  creatorName: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  onSuccessfulSend: PropTypes.func.isRequired,
};

export default SendCertificatesAction;
