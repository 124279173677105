import React from "react";
import { Tabs } from "antd";
import "../../App.min.css";
import AllCreatorsAndSubscribers from "./AllCreatorsAndSubscribers";
import MangoNotification from "./Mango";

const { TabPane } = Tabs;

const PushNotifications = () => {
  return (
    <>
      <Tabs type="card">
        <TabPane key="all_creators" tab=" All creators">
          <AllCreatorsAndSubscribers isForCreators />
        </TabPane>
        <TabPane key="all_subs" tab="All  Subscribers">
          <AllCreatorsAndSubscribers isForCreators={false} />
        </TabPane>
        <TabPane key="mango" tab="Mango">
          <MangoNotification onlyCreator={false} />
        </TabPane>
        <TabPane key="creator" tab="Creator">
          <MangoNotification onlyCreator />
        </TabPane>
      </Tabs>
    </>
  );
};

export default PushNotifications;
