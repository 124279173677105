import React from "react";
import { Table, Typography } from "antd";
import PropTypes from "prop-types";

const MangoExpandTable = ({ mangos }) => {
  const columnData = [
    {
      title: "Mango Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Earnings",
      dataIndex: "earning",
      key: "earning",
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
      render: (text, record) => (
        <Typography>{record?.gst?.toFixed(2)}</Typography>
      ),
    },
    {
      title: "Number of Orders",
      dataIndex: "nOrders",
      key: "nOrders",
    },
  ];
  return <Table columns={columnData} dataSource={mangos} pagination={false} />;
};

MangoExpandTable.propTypes = {
  mangos: PropTypes.shape,
};

export default MangoExpandTable;
