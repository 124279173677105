/* Constants */
export const validImageExtensions = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
];

export const getFileExtension = (fileName) => {
  const re = /(?:\.([^.]+))?$/;
  return re.exec(fileName);
};

// make base64 image form file object
export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
