import React, { useEffect, useState } from "react";
import {
  Table,
  Typography,
  Row,
  Col,
  message,
  Space,
  Avatar,
  Select,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import SendCertificatesAction from "./SendCertificatesAction";
import {
  getAllInfluencers,
  getCreatorDetailsWithHiddenMangoes,
  getActiveSubscribers,
} from "../api";
import debounce from "../../Utils/utilityFunctions";

const { Option } = Select;

const CertificatesSection = () => {
  const [creators, setCreators] = useState([]);
  const [creatorsLoading, setCreatorsLoading] = useState(false);
  const [creatorsOptions, setCreatorsOptions] = useState(null);
  const [mangoes, setMangoes] = useState({});
  const [mangoesLoading, setMangoesLoading] = useState(false);
  const [mangoesOptions, setMangoesOptions] = useState(null);
  const [search, setSearch] = useState("");
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [selectedCreatorName, setSelectedCreatorName] = useState("");
  const [selectedMango, setSelectedMango] = useState(null);
  const [selectedMangoStartDate, setSelectedMangoStartDate] = useState(null);
  const [selectedMangoName, setSelectedMangoName] = useState("");
  const [activeSubscribers, setActiveSubscribers] = useState([]);
  const [activeSubscribersLoading, setActiveSubscribersLoading] =
    useState(false);

  const getCreatorSearch = async (query) => {
    try {
      const res = await getAllInfluencers(query);
      if (res.code === 0) {
        setCreators(res?.result || []);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error(err?.message || err?.result || "Something went wrong");
    }
  };

  const getCreatorMangoes = async (creatorId) => {
    try {
      if (creatorId) {
        const res = await getCreatorDetailsWithHiddenMangoes(creatorId);
        if (res.code === 0) {
          setMangoes(
            res?.result?.activeMangoes
              ?.filter((mango) => !mango?.isPublic)
              .reduce(
                (prev, current) => ({ ...prev, [current._id]: current }),
                {}
              ) || {}
          );
        } else {
          message.error("Something went wrong");
        }
      }
    } catch (err) {
      console.log(err);
      message.error(err?.message || err?.result || "Something went wrong");
    }
  };

  const getActiveSubscribersOfMango = async (creatorId, mangoId) => {
    try {
      if (mangoId) {
        const res = await getActiveSubscribers(creatorId, mangoId);
        if (res.code === 0) {
          setActiveSubscribers(res?.result || []);
        } else {
          message.error("Something went wrong");
        }
      }
    } catch (err) {
      console.log(err);
      message.error(err?.message || err?.result || "Something went wrong");
    }
  };

  useEffect(async () => {
    setCreatorsLoading(true);
    await getCreatorSearch(search);
    setCreatorsLoading(false);
  }, [search]);

  useEffect(() => {
    setCreatorsOptions(
      creators.map((creator) => (
        <Option value={creator?._id}>{creator?.name}</Option>
      ))
    );
  }, [creators]);

  useEffect(async () => {
    setMangoesLoading(true);
    setSelectedMango(null);
    setSelectedMangoStartDate(null);
    setActiveSubscribers([]);
    await getCreatorMangoes(selectedCreator);
    setMangoesLoading(false);
  }, [selectedCreator]);

  useEffect(() => {
    setMangoesOptions(
      Object.keys(mangoes).map((mangoId) => (
        <Option value={mangoId}>{mangoes[mangoId].title}</Option>
      ))
    );
  }, [mangoes]);

  useEffect(async () => {
    setActiveSubscribersLoading(true);
    await getActiveSubscribersOfMango(selectedCreator, selectedMango);
    setActiveSubscribersLoading(false);
  }, [selectedMango]);

  const subscriberDataColumns = [
    {
      title: "Name",
      dataIndex: "fanName",
      render: (text, record) => (
        <Space size="small" style={{ width: "100%" }}>
          <Avatar src={record?.fanProfilePicUrl} size={32} />
          <Typography style={{ fontWeight: 500, fontSize: 15, marginLeft: 6 }}>
            {record?.fanName}
          </Typography>
        </Space>
      ),
    },
    {
      title: "Email",
      dataIndex: "fanEmail",
    },
    {
      title: "Phone",
      dataIndex: "fanPhone",
    },
  ];

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
        <Col span={12}>
          <Select
            showSearch
            allowClear
            placeholder="Select a creator"
            onChange={(value, option) => {
              setSelectedCreator(value);
              setSelectedCreatorName(option?.children);
            }}
            onSearch={debounce(setSearch)}
            style={{ width: "100%" }}
            notFoundContent={creatorsLoading ? <LoadingOutlined /> : null}
            filterOption={false}
          >
            {creatorsOptions}
          </Select>
        </Col>
        <Col span={12}>
          <Select
            showSearch
            allowClear
            placeholder="Select a mango"
            onChange={(value) => {
              setSelectedMango(value);
              setSelectedMangoStartDate(mangoes[value]?.start);
              setSelectedMangoName(mangoes[value]?.title);
            }}
            style={{ width: "100%" }}
            value={selectedMango}
            filterOption={(input, option) =>
              option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            notFoundContent={mangoesLoading ? <LoadingOutlined /> : null}
            disabled={!selectedCreator}
          >
            {mangoesOptions}
          </Select>
        </Col>
      </Row>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: "20px",
        }}
      >
        <Col span={12}>
          <Typography>
            Found {activeSubscribers.length} active subscribers
          </Typography>
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {mangoes[selectedMango]?.certificateSentOn ? (
            <p style={{ color: "green" }}>
              Certificates Sent on{" "}
              {moment(mangoes[selectedMango]?.certificateSentOn).format(
                "DD/MM/YYYY"
              )}
            </p>
          ) : (
            <SendCertificatesAction
              mango={selectedMango}
              mangoName={selectedMangoName}
              creatorName={selectedCreatorName}
              startDate={selectedMangoStartDate}
              onSuccessfulSend={(mango) => {
                setMangoes({
                  ...mangoes,
                  [mango]: {
                    ...mangoes[mango],
                    certificateSentOn: new Date().toISOString(),
                  },
                });
              }}
            />
          )}
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {activeSubscribersLoading ? (
            <LoadingOutlined />
          ) : (
            <Table
              dataSource={activeSubscribers}
              columns={subscriberDataColumns}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default CertificatesSection;
