/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Form,
  Input,
  message,
  Select,
  Checkbox,
} from "antd";
import "antd/dist/antd.css";
import {
  getAllInfluencers,
  getCreatorDetailsWithHiddenMangoes,
  sendPushNotifications,
} from "../../api";

const { Option } = Select;

const MangoNotification = ({ onlyCreator }) => {
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [creatorOptions, setCreatorOptions] = useState([]);
  const [tierlist, setTierlist] = useState([]);
  const [tierOptions, setTierOptions] = useState([]);
  const [selectedTier, setSelectedTier] = useState([]);
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [enableLink, setEnableLink] = useState(false);
  const [search, setSearch] = useState("");

  const handleLinkToggle = (e) => {
    setEnableLink(e.target.checked);
  };

  const getCreatorSearch = async (query) => {
    const res = await getAllInfluencers(query);
    try {
      if (res.code === 0) {
        setUsers(res.result || []);
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      console.log(error);
      error?.message
        ? message.error(error?.message || error?.result)
        : message.error("Something went wrong");
    }
  };

  useEffect(() => {
    getCreatorSearch(search);
  }, [search]);

  useEffect(() => {
    setCreatorOptions(
      users.map((user) => <Option key={user._id}>{user.name}</Option>)
    );
  }, [users]);

  useEffect(() => {
    setTierOptions(
      tierlist.map((tier) => <Option key={tier._id}>{tier.title}</Option>)
    );
  }, [tierlist]);

  function handleCreatorChange(value) {
    setTierlist([]);
    setSelectedCreators(value);
  }

  useEffect(() => {
    async function getmangoes() {
      if (selectedCreators?.length) {
        const mangoes = [];
        for (let i = 0; i < selectedCreators.length; i += 1) {
          const res = await getCreatorDetailsWithHiddenMangoes(
            selectedCreators[i]
          );
          if (res.code !== 100) {
            const nonPublicMangoes = res.result.activeMangoes.filter(
              (item) => !item.isPublic
            );
            mangoes.push(...nonPublicMangoes);
          } else if (res.code === 100) {
            console.log(res?.result?.message);
          }
        }
        setTierlist(mangoes);
      }
    }
    getmangoes();
  }, [selectedCreators]);

  function handleMangoChange(value) {
    if (value.length) {
      setSelectedTier(value);
    }
  }

  const handleOnFinish = async (value) => {
    const optionalParams = onlyCreator
      ? { creators: selectedCreators }
      : { mangoes: selectedTier };
    const data = {
      ...value,
      type: onlyCreator ? "only_creator" : "only_mango",
      ...optionalParams,
    };
    try {
      const res = await sendPushNotifications(data);
      if (res.code === 0) {
        const { nUsers, nDevices } = res.result;
        message.info(`Notifications Sent to ${nUsers} Users with ${nDevices}`);
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      console.log(error);
      error?.message
        ? message.error(error?.message)
        : message.error("Something went wrong");
    }
  };

  return (
    <>
      <div>
        <Typography.Text type="secondary">
          Send notifications to all the{" "}
          {onlyCreator
            ? "subscribers of the creator"
            : "subscribers of the creator's mangoes"}
        </Typography.Text>
        <div style={{ marginTop: "20px" }}>
          <Typography.Text>Select the creators</Typography.Text>
          <Select
            filterOption={(input, option) =>
              option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
              0
            }
            mode="tags"
            style={{ width: "100%", marginBottom: "20px" }}
            onChange={handleCreatorChange}
            onSearch={setSearch}
            tokenSeparators={[","]}
          >
            {creatorOptions}
          </Select>

          {!onlyCreator && (
            <>
              <Typography.Text>Select the mangoes</Typography.Text>
              <Select
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                }
                mode="tags"
                style={{ width: "100%", marginBottom: "20px" }}
                onChange={handleMangoChange}
                tokenSeparators={[","]}
              >
                {tierOptions}
              </Select>
            </>
          )}

          <Form
            layout="vertical"
            style={{ width: "100%", marginTop: "5px" }}
            onFinish={handleOnFinish}
          >
            <Form.Item
              name="heading"
              label="Heading"
              rules={[
                {
                  required: true,
                  message: "Please enter heading",
                },
              ]}
            >
              <Input
                placeholder="Enter the notification title here . . ."
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="content"
              label="Content"
              rules={[
                {
                  required: true,
                  message: "Please enter content",
                },
              ]}
            >
              <Input.TextArea placeholder="Enter the notification content here . . ." />
            </Form.Item>
            <Checkbox
              style={{ marginBottom: "20px" }}
              onChange={handleLinkToggle}
            >
              Add redirection link
            </Checkbox>
            {enableLink && (
              <Form.Item
                name="link"
                label="Redirection link"
                rules={[
                  {
                    required: enableLink,
                    message: "Please enter the link",
                  },
                  {
                    type: "url",
                    message:
                      "This field must be a valid url. Ex: https://www.google.com/",
                  },
                ]}
              >
                <Input
                  placeholder="Enter the redirection link here . . ."
                  size="large"
                />
              </Form.Item>
            )}
            <Button
              disabled={
                onlyCreator ? !selectedCreators?.length : !selectedTier?.length
              }
              htmlType="submit"
              type="primary"
              size="large"
              block
            >
              Send
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default MangoNotification;
