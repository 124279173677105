/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { Button, message, Space, Table, Typography } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import fileDownload from "js-file-download";
import { getFunnelStageCreators } from "../api";

const FunnelExpandTable = ({
  funnelStage,
  utm_campaign,
  startDate,
  endDate,
}) => {
  const [funnelData, setFunnelData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);

  const columnData = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (
        <Typography>{moment(text).format("DD/MM/YYYY hh:mm a")}</Typography>
      ),
    },
    {
      title: "Meta Details",
      dataIndex: "meta",
      key: "meta",
      render: (_, { meta }) => (
        <Space direction="vertical">
          <Typography>
            <strong>UTM Campaign:</strong> {meta?.utm_campaign}
          </Typography>
          <Typography>
            <strong>UTM Source:</strong> {meta?.utm_source}
          </Typography>
          <Typography>
            <strong>UTM Medium:</strong> {meta?.utm_medium}
          </Typography>
          <Typography>
            <strong>UTM Term:</strong> {meta?.utm_term}
          </Typography>
          <Typography>
            <strong>UTM Content:</strong> {meta?.utm_content || "N/A"}
          </Typography>
          <Typography>
            <strong>First SignIn:</strong> {meta?.firstSignInDate || "N/A"}
          </Typography>
          <Typography>
            <strong>First Mango Created At:</strong>{" "}
            {meta?.firstMangoCreationDate || "N/A"}
          </Typography>
          <Typography>
            <strong>First Earning At</strong> {meta?.firstEarningDate || "N/A"}
          </Typography>
        </Space>
      ),
    },
  ];

  const getFunnelStageCreatorsData = async () => {
    setLoading(true);
    try {
      const resp = await getFunnelStageCreators(
        utm_campaign,
        startDate?.toISOString(),
        endDate?.toISOString(),
        funnelStage,
        page,
        limit
      );
      if (resp.code === 0) {
        setFunnelData(resp.result?.data);
        setTotal(resp?.result?.total);
      } else {
        message.error("Error fetching funnel counts");
      }
    } catch (error) {
      message.error("Error fetching funnel counts");
      console.log(error);
    }
    setLoading(false);
  };

  const downloadCSVFunnel = async () => {
    try {
      const resp = await getFunnelStageCreators(
        utm_campaign,
        startDate?.toISOString(),
        endDate?.toISOString(),
        funnelStage,
        page,
        limit,
        true
      );
      fileDownload(resp, "funnel-stage-creators.csv");
    } catch (error) {
      message.error("Error fetching funnel counts");
      console.log(error);
    }
  };

  useEffect(() => {
    getFunnelStageCreatorsData();
  }, [funnelStage, utm_campaign, startDate, endDate, page, limit]);

  return (
    <>
      <Button type="primary" onClick={downloadCSVFunnel}>
        Donwload CSV
      </Button>
      <Table
        columns={columnData}
        dataSource={funnelData}
        pagination={{
          current: page,
          limit,
          total,
          onChange: setPage,
          showSizeChanger: true,
          onShowSizeChange: (current, size) => setLimit(size),
        }}
        loading={loading}
      />
    </>
  );
};

FunnelExpandTable.propTypes = {
  funnelStage: PropTypes.string.isRequired,
  utm_campaign: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};

export default FunnelExpandTable;
