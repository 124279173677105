const mailTemplates = {
  default: {
    id: "159908",
    name: "Default Template",
  },
  newsletter: {
    id: "198354",
    name: "Newsletter",
  },
  investor_newsletter: {
    id: "194882",
    name: "Investor Newsletter",
  },
};

export default mailTemplates;
