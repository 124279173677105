/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table, Space, Avatar, Typography, Button, message } from "antd";
import {
  HomeOutlined,
  LeftSquareOutlined,
  RightSquareOutlined,
} from "@ant-design/icons";
import moment from "moment";
import "antd/dist/antd.css";
import "../../App.min.css";
import { CSVLink } from "react-csv";
import { getNoAccountUser } from "../api/index";

const NoAccountUser = () => {
  const history = useHistory();
  const [userDetails, setUserDetails] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(async () => {
    try {
      const fetchedUserDetails = await getNoAccountUser(page);
      if (fetchedUserDetails.code === 0) {
        setUserDetails(fetchedUserDetails?.result?.users);
        setTotalPage(Math.ceil(fetchedUserDetails?.result?.userCount / 100));
      } else if (
        fetchedUserDetails.code === 401 ||
        fetchedUserDetails.status === 401
      ) {
        localStorage.clear();
        history.push("/");
        fetchedUserDetails?.message
          ? message.error(fetchedUserDetails?.message)
          : message.error("Something went wrong");
      } else {
        fetchedUserDetails?.message
          ? message.error(fetchedUserDetails?.message)
          : message.error("Something went wrong");
      }
    } catch (error) {
      error?.message
        ? message.error(error?.message)
        : message.error("Something went wrong");
    }
    return () => {
      setUserDetails([]);
    };
  }, [page]);

  // const onChange = (date, dateString) => {
  //   if (date) {
  //     console.log(date.format('MM-YYYY'));
  //     setDate(date.format('MM-YYYY'));
  //   } else {
  //     setDate(moment(new Date()).format('MM-YYYY'));
  //   }
  // }

  const pageDisplay = () => {
    return (
      <p style={{ display: "contents" }}>
        Page {page} of {totalPage}
      </p>
    );
  };

  const handleCLick = (action) => {
    if (action === "home") {
      setPage(1);
    }

    if (action === "next") {
      setPage(page + 1);
    }

    if (action === "previous") {
      setPage(page - 1);
    }
  };

  const displayUserDetails = () => {
    const onboardingObj = {
      dual: "Dual",
      fan_completed: "Fan",
      creator_completed: "Creator",
    };
    const tableData = userDetails.reduce((acc, eachDetails) => {
      return [
        ...acc,
        {
          key: eachDetails?._id,
          name: eachDetails?.name,
          onboarding: onboardingObj[eachDetails?.onboarding],
          email: eachDetails?.email,
          phone: eachDetails?.phone,
          createdAt: moment(eachDetails?.createdAt).format(
            "MMMM Do YYYY, h:mm:ss a"
          ),
          profilePicUrl: eachDetails?.profilePicUrl,
        },
      ];
    }, []);

    const columnData = [
      {
        title: "Creator Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <Space size="small">
            <Avatar src={record.profilePicUrl} size={32} />
            <Typography style={{ fontWeight: 500, fontSize: 14 }}>
              {text}
            </Typography>
          </Space>
        ),
      },
      {
        title: "Creator Status",
        dataIndex: "onboarding",
        key: "onboarding",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
      },
    ];
    return (
      <>
        <Table
          columns={columnData}
          dataSource={tableData}
          pagination={{ pageSize: 100, hideOnSinglePage: true }}
        />
      </>
    );
  };

  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Gender", key: "gender" },
    { label: "Profile Pic", key: "profilePicUrl" },
    { label: "Phone No.", key: "phone" },
    { label: "Onboarding status", key: "onboarding" },
    { label: "About", key: "about" },
    { label: "Profile created", key: "createdAt" },
    { label: "Facebook", key: "facebook" },
    { label: "Instagram", key: "instagram" },
    { label: "Linkedin", key: "linkedin" },
    { label: "Email verified", key: "isEmailVerified" },
    { label: "Account Deactivated", key: "isDeactivated" },
    { label: "Account hidden from discovery", key: "isHiddenFromDiscovery" },
    { label: "Twitter", key: "twitter" },
    { label: "Slug", key: "userSlug" },
    { label: "Youtube", key: "youtube" },
    { label: "Mongo Id", key: "_id" },
  ];

  return (
    <>
      <CSVLink
        filename={`no_account_${new Date()}.csv`}
        data={userDetails}
        headers={headers}
      >
        <Button>Export CSV</Button>
      </CSVLink>
      {userDetails?.length > 0 ? displayUserDetails() : null}
      {pageDisplay()}
      <div style={{ float: "right" }}>
        <Button
          type="primary"
          disabled={page === 1}
          icon={<LeftSquareOutlined />}
          onClick={() => handleCLick("previous")}
        />
        <Button
          type="primary"
          disabled={page === 1}
          icon={<HomeOutlined />}
          onClick={() => handleCLick("home")}
        />
        <Button
          type="primary"
          icon={<RightSquareOutlined />}
          disabled={page === totalPage}
          onClick={() => handleCLick("next")}
        />
      </div>
    </>
  );
};

export default NoAccountUser;
