import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { message, Button, Modal } from "antd";
import PropTypes from "prop-types";
import { approveCreator } from "../api";

const CreatorTableItem = ({ creator, approveFunc }) => {
  const [loading, setLoading] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const isApproved = creator?.creatorAccessRequested !== "requested";
  const approve = async () => {
    setLoading(true);
    try {
      const resp = await approveCreator(creator._id);
      if (resp.code === 0 && resp.result) {
        approveFunc();
        message.success("Creator accepted successfully!");
      } else {
        message.error("Could not process your request");
      }
    } catch (err) {
      console.log(err);
      message.error("Could not process your request");
    } finally {
      setLoading(false);
      setApproveModal(false);
    }
  };
  const approvedButton = isApproved ? (
    <p
      style={{
        color: "green",
      }}
    >
      Approved
    </p>
  ) : (
    <Button
      type="primary"
      onClick={() => {
        setApproveModal(true);
      }}
    >
      Accept
    </Button>
  );
  return (
    <div>
      <Modal
        title="Approve Creator?"
        visible={approveModal}
        onOk={approve}
        onCancel={() => {
          setApproveModal(false);
        }}
        okText="Yes"
        cancelText="No"
      >
        <p>
          Are you sure you wish to accept{" "}
          <strong>{creator?.name?.toUpperCase()}</strong> as a creator?
        </p>
      </Modal>
      {loading ? (
        <LoadingOutlined
          style={{
            fontSize: "1rem",
          }}
        />
      ) : (
        approvedButton
      )}
    </div>
  );
};

CreatorTableItem.propTypes = {
  creator: PropTypes.shape,
  approveFunc: PropTypes.func,
};

export default CreatorTableItem;
