import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Table,
  Space,
  Typography,
  Avatar,
  message,
  Col,
  Row,
  Pagination,
  Card,
} from "antd";
import { DebounceInput } from "react-debounce-input";
import moment from "moment";
import { getUpcomingPayouts } from "../api";
import SettlePayoutAction from "./SettlePayoutAction";
import SettlePayoutOrdersTable from "./SettlePayoutOrdersTable";

const PayoutsSchedule = () => {
  const [payouts, setPayouts] = useState([]);
  const [payoutsLoading, setPayoutsLoading] = useState(false);
  // const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(1, "month"));
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [totalBaseAmount, setTotalBaseAmount] = useState(0);
  // const [totalBaseAmountToday, setTotalBaseAmountToday] = useState(0);
  const [search, setSearch] = useState("");
  const [lastDay, setLastDay] = useState(moment().startOf("day"));
  const [presentlyViewing, setPresentlyViewing] = useState("");

  const controlsElementsStyle = {
    display: "flex",
    width: "100%",
    marginBottom: "8px",
  };

  const getPayoutsData = async () => {
    setPayoutsLoading(true);
    try {
      const resp = await getUpcomingPayouts(
        endDate.toISOString(),
        page,
        limit,
        search
      );
      if (resp?.code === 0) {
        setPayouts(resp?.result?.data);
        setTotal(resp?.result?.total);
        setLastDay(moment(resp?.result?.lastDay));
        setTotalBaseAmount(resp?.result?.totalBaseAmount);
        // setTotalBaseAmountToday(resp?.result?.totalBaseAmountToday);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
    setPayoutsLoading(false);
  };

  const payoutsTable = () => {
    const columnsData = [
      {
        title: "Creator",
        dataIndex: "creator",
        key: "creator",
        render: (_, record) => (
          <Space size="small">
            <Avatar src={record?.creator?.profilePicUrl} size={32} />
            <Typography
              style={{ fontWeight: 500, fontSize: 14, color: "#1890ff" }}
            >
              {record?.creator?.name}
            </Typography>
          </Space>
        ),
      },
      {
        title: "Bank Details",
        dataIndex: "creator",
        key: "creator",
        render: (_, record) => (
          <Typography>
            <strong>Account Name:</strong> {record?.creator?.accountName} <br />
            <strong>Account Number:</strong> {record?.creator?.accountNumber}{" "}
            <br />
            <strong>Bank Name: </strong>
            {record?.creator?.bankName} <br />
            <strong>IFSC:</strong> {record?.creator?.ifsc} <br />
            <strong>Bank Phone:</strong> {record?.creator?.bankPhone} <br />
          </Typography>
        ),
      },
      {
        title: "Next Payout Date",
        dataIndex: "nextPayoutDate",
        key: "nextPayoutDate",
        render: (_, record) => (
          <Typography>
            {moment(record?.nextPayoutDate).format("MMMM Do YYYY, h:mm:ss a")}
          </Typography>
        ),
      },
      {
        title: "Last Payout Date",
        dataIndex: "lastPayoutDate",
        key: "lastPayoutDate",
        render: (_, record) => (
          <Typography>
            {moment(record?.lastPayoutDate).format("MMMM Do YYYY, h:mm:ss a")}
          </Typography>
        ),
      },
      {
        title: "Terms Type",
        dataIndex: "termsType",
        key: "termsType",
        render: (_, record) => (
          <Typography>{`${record?.termsType} ${
            record?.termsType === "continuous"
              ? `(${record?.payoutFrequency})`
              : `(${record?.selectedMango?.title})`
          }`}</Typography>
        ),
      },
      {
        title: "Base Payout Amount",
        dataIndex: "baseAmount",
        key: "baseAmount",
      },
      {
        title: "Base Number of Orders",
        dataIndex: "baseOrders",
      },
      {
        title: "Minimum Guarantee",
        dataIndex: "minimumGuarantee",
        key: "minimumGuarantee",
      },
      {
        title: "Premium %",
        dataIndex: "premium",
        key: "premium",
      },
      {
        title: "Notes",
        dataIndex: "notes",
        key: "notes",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (_, record) => (
          <SettlePayoutAction
            payoutDetails={{
              ...record,
              endDate: endDate?.toISOString(),
              lastDay,
            }}
          />
        ),
      },
    ];
    return (
      <>
        <Table
          columns={columnsData}
          dataSource={payouts}
          loading={payoutsLoading}
          pagination={false}
          rowKey={(record) => record?._id}
          expandable={{
            expandedRowRender: (record) => (
              <SettlePayoutOrdersTable
                paymentTerm={record?._id}
                lastDay={lastDay?.toISOString()}
                presentlyViewing={presentlyViewing}
              />
            ),
            onExpand: (expanded, record) =>
              expanded
                ? setPresentlyViewing(record?._id)
                : setPresentlyViewing(""),
            expandedRowKeys: [presentlyViewing],
          }}
        />
        <Pagination
          style={{
            marginTop: "20px",
          }}
          current={page}
          pageSize={limit}
          total={total}
          onChange={setPage}
          pageSizeOptions={["10", "50", "100"]}
          showTotal={(t) => `Total ${t} items`}
          onShowSizeChange={(_, size) => {
            setLimit(size);
            setPage(1);
          }}
        />
      </>
    );
  };

  useEffect(async () => {
    await getPayoutsData();
  }, [endDate, page, limit, search]);

  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: "16px",
          width: "100%",
        }}
      >
        <Col span={8}>
          <Card title="Total Base Payout">
            <Typography>{totalBaseAmount}</Typography>
          </Card>
        </Col>
        {/* <Col span={8}>
          <Card title="Total Base Payout Today">
            <Typography>{totalBaseAmountToday}</Typography>
          </Card>
        </Col> */}
        <Col
          span={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            flexDirection: "column",
          }}
        >
          <div style={controlsElementsStyle}>
            <DebounceInput
              minLength={2}
              debounceTimeout={300}
              className="searchbar"
              style={{ width: "100%", marginBottom: "4px" }}
              placeholder="Search"
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginBottom: "8px",
              justifyContent: "center",
            }}
          >
            <DatePicker
              onChange={(date) => {
                if (date) {
                  setEndDate(date.endOf("day"));
                } else {
                  setEndDate(moment().add(1, "month").endOf("day"));
                }
              }}
              value={endDate}
              disabledDate={(current) => current && current < moment()}
            />
          </div>
        </Col>
      </Row>
      {payoutsTable()}
    </>
  );
};

export default PayoutsSchedule;
