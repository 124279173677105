import axios from "axios";
import config from "../../config";

const client = axios.create({
  baseURL: config.baseApiUrl,
});

// TOKEN TO BE TAKEN FROM LOCAL STORAGE
client.interceptors.request.use((configuration) => {
  if (configuration.baseURL === "https://ifsc.razorpay.com") {
    delete client.defaults.headers.common.Authorization;
  }
  const tempConf = configuration;
  const localToken = localStorage.getItem("token");
  // const token = contextValue?.state?.userReducer?.token || localToken;
  // tempConf.headers.Authorization = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiI1ZmZjMzk4MGFhOTY4OWMwZDM2OWZmMTEiLCJvbmJvYXJkaW5nIjoiZHVhbCIsImlzT25ib2FyZGluZ0NvbXBsZXRlZCI6dHJ1ZSwiaWF0IjoxNjExMDM5ODg5fQ.ZPUEw-JJzozgOcilfkINmFDfLskqbEeQ0AsEvNsqVkk`;
  tempConf.headers.Authorization = `Bearer ${localToken}`;
  return tempConf;
});

const request = (options) => {
  const onSuccess = (response) => {
    // console.debug("Request Successful!", response);
    return response.data;
  };

  const onError = (error) => {
    console.error("Request Failed:", error.config);

    if (error.response) {
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);
    } else {
      console.error("Error Message:", error.message);
    }

    const errorMessage = error.response?.data.result || error.message;

    const errorObject = new Error(errorMessage);
    errorObject.status = error.response?.status;
    throw errorObject;
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;
