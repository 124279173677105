import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Space,
  Table,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import { getDashboardMails } from "../api";
import EmailComposer from "./EmailComposer";
import SentMailsTable from "./SentMailsTable";

const EmailSection = () => {
  const [showComposer, setShowComposer] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().add(-7, "days").startOf("day")
  );
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [mailData, setMailData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [presentlyViewing, setPresentlyViewing] = useState("");
  const debouncedSearch = useDebounce({
    value: search,
    callback: () => setPage(1),
  });

  const getMailData = async () => {
    setLoading(true);
    try {
      const resp = await getDashboardMails(
        startDate.toISOString(),
        endDate.toISOString(),
        debouncedSearch,
        page,
        limit
      );
      if (resp?.code === 0) {
        setMailData(resp?.result?.data);
        setTotal(resp?.result?.total);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      message.error(err.message || "Something went wrong");
    }
    setLoading(false);
  };

  const mailsTable = () => {
    const columnsData = [
      {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
      },
      {
        title: "Total Mails",
        dataIndex: "totalMail",
        key: "totalMail",
      },
      {
        title: "Delivered",
        dataIndex: "deliveredMails",
        key: "deliveredMails",
      },
      {
        title: "Opened",
        dataIndex: "openedMails",
        key: "openedMails",
      },
      {
        title: "Clicked",
        dataIndex: "clickedLinkInMails",
        key: "clickedLinkInMails",
      },
      {
        title: "Unread",
        dataIndex: "unreadMails",
        key: "unreadMails",
      },
    ];
    return (
      <>
        <Table
          columns={columnsData}
          dataSource={mailData}
          pagination={{
            pageSize: limit,
            current: page,
            total,
            onChange: setPage,
            onShowSizeChange: (current, size) => setLimit(size),
          }}
          loading={loading}
          rowKey={(record) => record._id}
          expandable={{
            expandedRowRender: (record) => (
              <SentMailsTable
                passedRecord={record}
                campaignId={record._id}
                presentlyViewing={presentlyViewing}
              />
            ),
            onExpand: (expanded, record) =>
              expanded
                ? setPresentlyViewing(record._id)
                : setPresentlyViewing(""),
            expandedRowKeys: [presentlyViewing],
          }}
        />
      </>
    );
  };

  useEffect(() => {
    getMailData();
  }, [startDate, endDate, debouncedSearch, page, limit]);

  useEffect(() => {
    if (!showComposer) {
      setTimeout(getMailData, 2000);
    }
  }, [showComposer]);

  return (
    <>
      <Row>
        <Col
          span={8}
          style={{
            padding: "10px",
          }}
        >
          <Input
            placeholder="Search by subject"
            minLength={2}
            value={search}
            style={{
              width: "100%",
            }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
        <Col
          span={8}
          style={{
            padding: "10px",
          }}
        >
          <DatePicker.RangePicker
            style={{
              width: "100%",
            }}
            value={[startDate, endDate]}
            onChange={(date) => {
              if (date) {
                setStartDate(date[0].startOf("day"));
                setEndDate(date[1].endOf("day"));
              } else {
                setStartDate(moment().add(-7, "days").startOf("day"));
                setEndDate(moment().endOf("day"));
              }
            }}
          />
        </Col>
        <Col
          span={8}
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Space size="small">
            <Button onClick={() => getMailData()}>Refresh</Button>
            <Button onClick={() => setShowComposer(true)} type="primary">
              Compose Email
            </Button>
          </Space>
        </Col>
      </Row>
      {mailsTable()}
      <EmailComposer
        showComposer={showComposer}
        setShowComposer={setShowComposer}
      />
    </>
  );
};

export default EmailSection;
