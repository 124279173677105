/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import { Typography, Button, Form, Input, Checkbox, message } from "antd";
import "antd/dist/antd.css";
import { sendPushNotifications } from "../../api";

const AllCreatorsAndSubscribers = ({ isForCreators }) => {
  const [enableLink, setEnableLink] = useState(false);

  const handleOnFinish = async (value) => {
    const data = {
      ...value,
      type: isForCreators ? "all_creators" : "all_subscribers",
    };
    try {
      const res = await sendPushNotifications(data);
      if (res.code === 0) {
        const { nUsers, nDevices } = res.result;
        message.info(`Notifications Sent to ${nUsers} Users with ${nDevices}`);
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      console.log(error);
      error?.message
        ? message.error(error?.message)
        : message.error("Something went wrong");
    }
  };

  const handleLinkToggle = (e) => {
    setEnableLink(e.target.checked);
  };

  return (
    <>
      <div>
        <Typography.Text type="secondary">
          Send notifications to all the{" "}
          {isForCreators ? "creators" : "subscribers"} on the platform
        </Typography.Text>
        <Form
          layout="vertical"
          style={{ width: "100%", marginTop: "10px" }}
          onFinish={handleOnFinish}
        >
          <Form.Item
            name="heading"
            label="Heading"
            rules={[
              {
                required: true,
                message: "Please enter heading",
              },
            ]}
          >
            <Input
              placeholder="Enter the notification title here . . ."
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="content"
            label="Content"
            rules={[
              {
                required: true,
                message: "Please enter content",
              },
            ]}
          >
            <Input.TextArea placeholder="Enter the notification content here . . ." />
          </Form.Item>
          <Checkbox
            style={{ marginBottom: "20px" }}
            onChange={handleLinkToggle}
          >
            Add redirection link
          </Checkbox>
          {enableLink && (
            <Form.Item
              name="link"
              label="Redirection link"
              rules={[
                {
                  required: enableLink,
                  message: "Please enter the link",
                },
                {
                  type: "url",
                  message:
                    "This field must be a valid url. Ex: https://www.google.com/",
                },
              ]}
            >
              <Input
                placeholder="Enter the redirection link here . . ."
                size="large"
              />
            </Form.Item>
          )}
          <Button type="primary" htmlType="submit" size="large" block>
            Send
          </Button>
        </Form>
      </div>
    </>
  );
};

export default AllCreatorsAndSubscribers;
