import React, { useEffect, useState } from "react";
import {
  Typography,
  message,
  Table,
  Space,
  Pagination,
  Avatar,
  Input,
} from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import { getUpcomingPayoutOrders } from "../api";
import debounce from "../../Utils/utilityFunctions";

const { Search } = Input;

const SettlePayoutOrdersTable = ({
  paymentTerm,
  lastDay,
  presentlyViewing,
}) => {
  const [payoutOrdersLoading, setPayoutOrdersLoading] = useState(false);
  const [payoutOrders, setPayoutOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);

  const getPayoutOrdersData = async () => {
    setPayoutOrdersLoading(true);
    try {
      const resp = await getUpcomingPayoutOrders(
        paymentTerm,
        lastDay,
        page,
        limit,
        search
      );
      if (resp?.code === 0) {
        setPayoutOrders(resp?.result?.data);
        setTotal(resp?.result?.total);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
    setPayoutOrdersLoading(false);
  };

  useEffect(async () => {
    if (presentlyViewing === paymentTerm) {
      await getPayoutOrdersData();
    }
  }, [page, limit, search, presentlyViewing]);

  const ordersTable = () => {
    const columnData = [
      {
        title: "Order Id",
        dataIndex: "_id",
        key: "_id",
      },
      {
        title: "Subscriber",
        dataIndex: "fanName",
        key: "fanName",
        render: (_, record) => (
          <Space size="small">
            <Avatar src={record?.fanProfilePic} size={32} />
            <Typography
              style={{ fontWeight: 500, fontSize: 14, color: "#1890ff" }}
            >
              <strong>{record?.fanName}</strong> <br />
              {record?.fanPhone} <br />
              {record?.fanEmail}
            </Typography>
          </Space>
        ),
      },
      {
        title: "Mango",
        dataIndex: "mango",
        key: "mango",
      },
      {
        title: "Amount",
        dataIndex: "amount",
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (_, record) => (
          <Typography>
            {moment(record?.createdAt).format("DD/MM/YYYY hh:mm a")}
          </Typography>
        ),
      },
    ];
    return (
      <>
        <Table
          columns={columnData}
          dataSource={payoutOrders}
          pagination={false}
          loading={payoutOrdersLoading}
          style={{ marginTop: "20px" }}
        />
        <Pagination
          current={page}
          pageSize={limit}
          total={total}
          onShowSizeChange={(_, size) => setLimit(size)}
          onChange={setPage}
        />
      </>
    );
  };
  return (
    <>
      <Search
        placeholder="Search by name, mango, phone"
        allowClear
        onSearch={setSearch}
        onChange={(e) => debounce(setSearch)(e.target.value)}
        style={{
          marginBottom: "10px",
        }}
      />
      {ordersTable()}
    </>
  );
};

SettlePayoutOrdersTable.propTypes = {
  paymentTerm: PropTypes.string.isRequired,
  lastDay: PropTypes.string.isRequired,
  presentlyViewing: PropTypes.string.isRequired,
};

export default SettlePayoutOrdersTable;
