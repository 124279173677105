/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import {
  DownOutlined,
  HomeOutlined,
  LeftSquareOutlined,
  RightSquareOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  message,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import qs from "qs";
import React, { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { Link, useHistory } from "react-router-dom";
import "../../App.min.css";
import Loader from "../../Utils/Loader";
import { getDateRangeEaring, getDateRangeUserDetails } from "../api/index";

const { Option } = Select;

const userTypeObj = {
  creator: "creator_completed",
  subscriber: "fan_completed",
  dual: "dual",
};

const loaderStyle = {
  position: "fixed",
  top: "45%",
  left: "45%",
  zIndex: 9999,
};

const UserDetailsList = () => {
  const history = useHistory();
  const {
    startDate: urlStartDate,
    endDate: urlEndDate,
    userType: type,
  } = qs.parse(history.location.search.slice(1));

  const initialStart = moment(urlStartDate).startOf("day");
  const initialEnd = moment(urlEndDate).endOf("day");

  const [totalOrder, setTotalOrder] = useState(0);
  const [totalEarning, setTotalEarning] = useState(0);
  const [showLifetimeOrders, setShowLifetimeOrders] = useState(false);
  const [totalCreatorCount, setTotalCreatorCount] = useState(0);
  const [totalSubscriberCount, setTotalSubscriberCount] = useState(0);
  const [totalDualCount, setTotalDualCount] = useState(0);
  const [searchBy, setSearchBy] = useState("name"); // [email, name]
  const [searchFieldText, setSearchFieldText] = useState("");
  const [toggleFilter, setToggleFilter] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [userMangoDetails, setuserMangoDetails] = useState([]);
  const [fanMangoDetails, setfanMangoDetails] = useState([]);
  const [startDateState, setStartDateState] = useState(initialStart);
  const [endDateState, setEndDateState] = useState(initialEnd);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filterUserType, setFilterUserType] = useState(type || "creator");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const {
      startDate: startDateParams,
      endDate: endDateParams,
      userType,
    } = qs.parse(history.location.search.slice(1));
    if (!(startDateParams && endDateParams)) {
      setShowLifetimeOrders(true);
      return;
    }
    setStartDateState(moment(startDateParams).startOf("day"));
    setEndDateState(moment(endDateParams).endOf("day"));
    setFilterUserType(userType);
  }, [history.location]);

  const errorHandle = (error) => {
    if (error.status === 401 || error.code === 401) {
      localStorage.clear();
      history.push("/");
      error?.message || error?.result
        ? message.error(error?.message || error?.result)
        : message.error("Something went wrong");
    } else {
      error?.message || error?.result
        ? message.error(error?.message || error?.result)
        : message.error("Something went wrong");
    }
  };

  function onChange(date, dateString) {
    if (date) {
      setStartDateState(date[0].startOf("day"));
      setEndDateState(date[1].endOf("day"));
    }
    // else if (startDateParams) {
    //   setStartDateState(moment(startDateParams).startOf('day'));
    //   setEndDateState(moment(endDateParams).endOf('day'));
    // }
    else {
      setStartDateState(moment().add(-7, "days").startOf("day"));
      setEndDateState(moment().endOf("day"));
    }
  }

  const searchFilter = (value) => {
    if (!value) {
      setSearchFieldText("");
      setSearchBy(null);
    }
    setPage(1);
    setToggleFilter(!toggleFilter);
  };

  const handleCLick = (action) => {
    if (action === "home") {
      setPage(1);
    }

    if (action === "next") {
      setPage(page + 1);
    }

    if (action === "previous") {
      setPage(page - 1);
    }
  };

  const selectUserFilter = () => {
    const handleMenuClick = (e) => {
      setFilterUserType(e?.key);
    };

    const menu = (
      <Menu onClick={handleMenuClick} selectedKeys={[filterUserType]}>
        <Menu.Item key="creator" icon={<UserOutlined />}>
          Creator
        </Menu.Item>
        <Menu.Item key="subscriber" icon={<UserOutlined />}>
          Subscriber
        </Menu.Item>
        <Menu.Item key="dual" icon={<UserOutlined />}>
          Dual
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu}>
        <Button>
          User Type <DownOutlined />
        </Button>
      </Dropdown>
    );
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            window.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => window.searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const collectUserData = (response) => {
    if (filterUserType === "creator" || filterUserType === "dual") {
      const mangoDetails = response?.result?.orderDetails?.creatorOrder.reduce(
        (acc, eachMangoDetails) => {
          if (acc[eachMangoDetails?._id?.creatorId]) {
            const userObj = { ...acc[eachMangoDetails?._id?.creatorId] };
            userObj.earing += eachMangoDetails?.totalEarning;
            userObj.order += eachMangoDetails?.totalOrder;
            userObj.mangoes = [
              ...userObj.mangoes,
              {
                ...eachMangoDetails._id,
                totalOrder: eachMangoDetails?.totalOrder,
                totalEarning: eachMangoDetails?.totalEarning,
              },
            ];
            return { ...acc, [eachMangoDetails?._id?.creatorId]: userObj };
          }
          const userObj = {};
          userObj.earing = eachMangoDetails?.totalEarning;
          userObj.order = eachMangoDetails?.totalOrder;
          userObj.mangoes = [
            {
              ...eachMangoDetails._id,
              totalOrder: eachMangoDetails?.totalOrder,
              totalEarning: eachMangoDetails?.totalEarning,
            },
          ];
          return { ...acc, [eachMangoDetails?._id?.creatorId]: userObj };
        },
        {}
      );
      Object.keys(mangoDetails).map((creatorId) => {
        const uniqueMangoes = [];
        const addedIds = [];
        mangoDetails[creatorId].mangoes.map((mango) => {
          if (!addedIds.includes(String(mango.mangoId))) {
            uniqueMangoes.push(mango);
            addedIds.push(String(mango.mangoId));
          } else {
            const exisingIndex = uniqueMangoes.findIndex(
              (existingMango) =>
                existingMango.mangoId.toString() === mango.mangoId.toString()
            );
            uniqueMangoes[exisingIndex] = {
              ...uniqueMangoes[exisingIndex],
              totalEarning:
                uniqueMangoes[exisingIndex].totalEarning + mango.totalEarning,
              totalOrder:
                uniqueMangoes[exisingIndex].totalOrder + mango.totalOrder,
            };
          }
        });
        mangoDetails[creatorId].mangoes = uniqueMangoes;
      });
      setuserMangoDetails(mangoDetails);
    }

    if (filterUserType === "subscriber" || filterUserType === "dual") {
      const mangoDetails = response?.result?.orderDetails?.fanOrder.reduce(
        (acc, eachMangoDetails) => {
          if (acc[eachMangoDetails?._id?.fanId]) {
            const userObj = { ...acc[eachMangoDetails?._id?.fanId] };
            userObj.spend += eachMangoDetails?.totalSpend;
            userObj.order += eachMangoDetails?.totalOrder;
            userObj.mangoList = [
              ...userObj.mangoList,
              {
                ...eachMangoDetails._id,
                totalOrder: eachMangoDetails?.totalOrder,
                totalSpend: eachMangoDetails?.totalSpend,
              },
            ];
            return { ...acc, [eachMangoDetails?._id?.fanId]: userObj };
          }
          const userObj = {};
          userObj.spend = eachMangoDetails?.totalSpend;
          userObj.order = eachMangoDetails?.totalOrder;
          userObj.mangoList = [
            {
              ...eachMangoDetails._id,
              totalOrder: eachMangoDetails?.totalOrder,
              totalSpend: eachMangoDetails?.totalSpend,
            },
          ];
          return { ...acc, [eachMangoDetails?._id?.fanId]: userObj };
        },
        {}
      );
      setfanMangoDetails(mangoDetails);
    }
  };

  const getUserDetails = async (sDate, eDate) => {
    try {
      setLoader(true);
      const resp = await getDateRangeUserDetails(
        sDate,
        eDate,
        false,
        userTypeObj[filterUserType],
        page,
        searchBy,
        searchFieldText
      );
      if (resp?.code === 0) {
        collectUserData(resp);
        setTotalPage(Math.ceil(resp?.result?.userCount / 100));
        setUserDetails(resp?.result);
        setLoader(false);
      } else {
        setLoader(false);
        errorHandle(resp);
      }
    } catch (error) {
      setLoader(false);
      errorHandle(error);
    }
  };

  const creatorExpandedRowRender = (record) => {
    const columns = [
      {
        title: "Mango Title",
        dataIndex: "mangoName",
        key: "mangoName",
      },
      {
        title: "Price",
        dataIndex: "mangoPrice",
        key: "mangoPrice",
      },
      {
        title: "Total Order",
        dataIndex: "totalOrder",
        key: "totalOrder",
        render: (text, record) => (
          <Typography>
            {userMangoDetails[record.creatorId]
              ? userMangoDetails[record.creatorId].mangoes.find(
                  (mango) => mango.mangoId === record.mangoId
                )?.totalOrder
              : 0}
          </Typography>
        ),
      },
      {
        title: "Content Types",
        dataIndex: "contentType",
        key: "contentType",
        render: (text, record) => (
          <Typography>
            {record.chat && "Chat"} {record.content && "Content"}{" "}
            {record.videocall === 1 && "Video Call"}
          </Typography>
        ),
      },
      {
        title: "Recurring Type",
        dataIndex: "mangoType",
        key: "mangoType",
      },
      {
        title: "Created At",
        dataIndex: "mangoCreationDate",
        key: "mangoCreationDate",
      },
      {
        title: "Active",
        dataIndex: "active",
        key: "active",
        render: (text, record) => (
          <Checkbox
            checked={!(record.mangoIsHidden || record.mangoIsStopTakingPayment)}
          />
        ),
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={record?.mangoList}
        pagination={false}
        bordered
      />
    );
  };

  const subscriberExpandedRowRender = (record) => {
    const columns = [
      {
        title: "Creator Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <Space size="small">
            <Avatar src={record?.creatorProfilePicUrl} size={32} />
            <Typography
              style={{ fontWeight: 500, fontSize: 14, color: "#1890ff" }}
            >
              {record?.creatorName}
            </Typography>
          </Space>
        ),
      },
      {
        title: "Mango Title",
        dataIndex: "mangoName",
        key: "mangoName",
      },
      {
        title: "Price",
        dataIndex: "amount",
        key: "amount",
      },
      {
        title: "No of time Purchase",
        dataIndex: "totalOrder",
        key: "totalOrder",
      },
      {
        title: "Recurring Type",
        dataIndex: "mangoType",
        key: "mangoType",
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={fanMangoDetails[record?.key]?.mangoList}
        pagination={false}
        bordered
      />
    );
  };

  const displayUserDetails = () => {
    const expandedRowRender = (record) => {
      if (filterUserType === "creator") {
        return creatorExpandedRowRender(record);
      }

      if (filterUserType === "subscriber") {
        return subscriberExpandedRowRender(record);
      }

      if (filterUserType === "dual") {
        return (
          <>
            <div>{creatorExpandedRowRender(record)}</div>
            <div>{subscriberExpandedRowRender(record)}</div>
          </>
        );
      }
    };

    let userBaseColumn = [];
    const fanColumn = [
      {
        title: "Total Spend",
        dataIndex: "totalSpend",
        key: "totalSpend",
        render: (text, record) => (
          <Typography>
            {fanMangoDetails[record.key]
              ? fanMangoDetails[record.key].spend
              : 0}
          </Typography>
        ),
      },
      {
        title: "Total Mango Purchase",
        dataIndex: "totalMangoPurchase",
        key: "totalMangoPurchase",
        render: (text, record) => (
          <Typography>
            {fanMangoDetails[record.key]
              ? fanMangoDetails[record.key].order
              : 0}
          </Typography>
        ),
      },
      {
        title: "Unique Creator",
        dataIndex: "uniqueCreator",
        key: "uniqueCreator",
        render: (text, record) => (
          <Typography>
            {fanMangoDetails[record.key]
              ? Object.values(
                  fanMangoDetails[record.key].mangoList.reduce((acc, mango) => {
                    return { ...acc, [mango.creatorId]: 1 };
                  }, {})
                ).length
              : 0}
          </Typography>
        ),
      },
    ];

    if (filterUserType === "creator" || filterUserType === "dual") {
      userBaseColumn = [
        {
          title: "Earning",
          dataIndex: "earning",
          key: "earning",
        },
        {
          title: "Total Order",
          dataIndex: "order",
          key: "order",
        },
        {
          title: "Active Mango/ Mango",
          dataIndex: "activeMango",
          key: "activeMango",
          render: (text, record) => (
            <Typography>
              {record?.activeMango} / {record?.numberOfMangoes}
            </Typography>
          ),
        },
        ...((filterUserType === "dual" && fanColumn) || []),
      ];
    }

    if (filterUserType === "subscriber") {
      userBaseColumn = fanColumn;
    }

    const columnData = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        ...getColumnSearchProps("name"),
        render: (text, record) => (
          <Link to={`/dashboard?userId=${record?.key}`}>
            <Space size="small">
              <Avatar src={record?.profilePicUrl} size={32} />
              <Typography
                style={{ fontWeight: 500, fontSize: 14, color: "#1890ff" }}
              >
                {record?.name}
              </Typography>
            </Space>
          </Link>
        ),
      },
      ...userBaseColumn,
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        ...getColumnSearchProps("email"),
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        ...getColumnSearchProps("phone"),
      },
      {
        title: "Joining Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text, record) => (
          <Typography>
            {moment(record?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
          </Typography>
        ),
      },
    ];

    let data = [];
    if (filterUserType === "creator" || filterUserType === "dual") {
      data = userDetails?.fetchedUser
        ? userDetails?.fetchedUser.map((record) => ({
            name: record?._id?.creatorName,
            profilePicUrl: record?._id?.creatorProfilePicUrl,
            earning: userMangoDetails[record?._id?.creatorId]?.earing || 0,
            order: userMangoDetails[record?._id?.creatorId]?.order || 0,
            activeMango: record?.mangoList?.filter(
              (eachMango) =>
                !(eachMango.mangoIsHidden || eachMango.mangoIsStopTakingPayment)
            ).length,
            numberOfMangoes: record?.numberOfMangoes,
            email: record?._id?.creatorEmail,
            phone: record?._id?.creatorPhone,
            createdAt: record?._id?.createdAt,
            key: record?._id?.creatorId,
            mangoList: record?.mangoList,
          }))
        : [];
    }
    if (filterUserType === "subscriber") {
      data = userDetails?.fetchedUser
        ? userDetails?.fetchedUser.map((record) => ({
            name: record?.name,
            profilePicUrl: record?.profilePicUrl,
            email: record?.email,
            phone: record?.phone,
            createdAt: record?._id?.createdAt,
            key: record?._id,
          }))
        : [];
    }
    return (
      <>
        {loader && (
          <div style={loaderStyle}>
            <Loader />
          </div>
        )}
        <Table
          columns={columnData}
          // dataSource={userDetails?.fetchedUser ? userDetails?.fetchedUser.map(user => ({...user, key: user._id.creatorId})): []}
          dataSource={data}
          expandable={{ expandedRowRender }}
          pagination={{ pageSize: 100, hideOnSinglePage: true }}
        />
      </>
    );
  };

  useEffect(() => {
    setTotalCreatorCount(0);
    setTotalDualCount(0);
    setTotalSubscriberCount(0);

    getUserDetails(startDateState.toISOString(), endDateState.toISOString());
    getDateRangeEaring(
      startDateState.toISOString(),
      endDateState.toISOString(),
      true,
      undefined,
      searchBy,
      searchFieldText
    )
      .then((response) => {
        if (response.code === 0) {
          setTotalOrder(response?.result[0]?.totalOrder);
          setTotalEarning(response?.result[0]?.totalEarning);
        }
        // console.log(response);
      })
      .catch((error) => {
        errorHandle(error);
      });
    getDateRangeUserDetails(
      startDateState.toISOString(),
      endDateState.toISOString(),
      true,
      undefined,
      undefined,
      searchBy,
      searchFieldText
    )
      .then((response) => {
        if (response.code === 0) {
          response?.result.forEach((element) => {
            if (element?._id?.onboarding === "creator_completed") {
              setTotalCreatorCount(element?.totalCount);
            }
            if (element?._id?.onboarding === "dual") {
              setTotalDualCount(element?.totalCount);
            }
            if (element?._id?.onboarding === "fan_completed") {
              setTotalSubscriberCount(element?.totalCount);
            }
          });
        }
      })
      .catch((error) => {
        errorHandle(error);
      });
  }, [startDateState, endDateState, filterUserType, page, toggleFilter]);

  const toggleLifetime = () => {
    setShowLifetimeOrders(!showLifetimeOrders);
  };

  useEffect(() => {
    if (showLifetimeOrders) {
      history.push(
        `/dashboard?startDate=${moment("2020-12-28 14:30:14.755Z")
          .startOf("day")
          .toISOString()}&endDate=${moment()
          .startOf("day")
          .toISOString()}&userDetailsList=true&userType=${filterUserType}`
      );
    }
  }, [showLifetimeOrders]);

  const handleSearchOptionChange = (value) => {
    setSearchBy(value);
  };

  return (
    <>
      <div className="site-card-wrapper">
        <Row gutter={16} style={{ marginBottom: "20px" }}>
          <Col span={3}>
            <Card
              style={{ fontSize: "larger" }}
              title="Total Orders"
              bordered={false}
            >
              {Number(totalOrder).toLocaleString() || 0}
            </Card>
          </Col>
          <Col span={3}>
            <Card
              style={{ fontSize: "larger" }}
              title="Total Creators"
              bordered={false}
            >
              {`${
                (
                  Number(totalDualCount) + Number(totalCreatorCount)
                ).toLocaleString() || 0
              }`}
            </Card>
          </Col>
          <Col span={4}>
            <Card
              style={{ fontSize: "larger" }}
              title="Total Subscribers"
              bordered={false}
            >
              {`${
                Number(
                  Number(totalDualCount) + Number(totalSubscriberCount)
                ).toLocaleString() || 0
              }`}
            </Card>
          </Col>
          <Col span={3}>
            <Card
              style={{ fontSize: "larger" }}
              title="Total Earnings"
              bordered={false}
            >
              {`${Number(totalEarning).toLocaleString() || 0} Rs.`}
            </Card>
          </Col>
          <Col
            span={3}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              flexDirection: "column",
            }}
          >
            {selectUserFilter()}
          </Col>
          <Col
            span={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              flexDirection: "column",
            }}
          >
            <DatePicker.RangePicker
              disabled={showLifetimeOrders}
              onChange={onChange}
              value={[startDateState, endDateState]}
            />
            <Checkbox checked={showLifetimeOrders} onChange={toggleLifetime}>
              Show Lifetime Users
            </Checkbox>
          </Col>
          <Col
            span={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              flexDirection: "column",
            }}
          >
            <div>Search Users By</div>
            <Select
              style={{ width: "100%", marginBottom: "20px" }}
              onChange={handleSearchOptionChange}
              value={searchBy}
              defaultActiveFirstOption
            >
              <Option key="name">Name</Option>
              <Option key="email">Email</Option>
            </Select>
            <div>
              <Input
                style={{ width: "auto" }}
                value={searchFieldText}
                onChange={(e) => setSearchFieldText(e.target.value)}
              />
              <Button onClick={() => searchFilter(true)}>Go</Button>
              <Button onClick={() => searchFilter(false)}>Clear</Button>
            </div>
          </Col>
        </Row>
      </div>
      {displayUserDetails()}
      <div style={{ float: "right" }}>
        <Button
          type="primary"
          disabled={page === 1}
          icon={<LeftSquareOutlined />}
          onClick={() => handleCLick("previous")}
        />
        <Button
          type="primary"
          disabled={page === 1}
          icon={<HomeOutlined />}
          onClick={() => handleCLick("home")}
        />
        <Button
          type="primary"
          icon={<RightSquareOutlined />}
          disabled={page === totalPage}
          onClick={() => handleCLick("next")}
        />
      </div>
    </>
  );
};

export default UserDetailsList;
