import React from "react";
import { Layout, Menu } from "antd";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import logo from "./logo.png";
import { AVAILABLE_PAGES } from "../../Utils/constants";

const Sider = ({
  setSliderValue,
  sliderValue,
  isRestricted,
  allowedFunctions,
}) => {
  const history = useHistory();

  const handleClick = (data) => {
    setSliderValue(data?.key);
    history.replace("/dashboard");
  };

  const menuItems = AVAILABLE_PAGES.map((page) => (
    <Menu.Item key={page.key}>{page.name}</Menu.Item>
  ));
  return (
    <Layout.Sider className="siteSidebar">
      <div className="siteLogo">
        <img src={logo} alt="TagMango Logo" width={145} />
      </div>
      <Menu
        theme="dark"
        mode="inline"
        onClick={handleClick}
        selectedKeys={[sliderValue]}
      >
        {menuItems.filter((item) =>
          isRestricted ? allowedFunctions?.includes(item.key) : true
        )}
      </Menu>
    </Layout.Sider>
  );
};

Sider.propTypes = {
  setSliderValue: PropTypes.func.isRequired,
  sliderValue: PropTypes.string.isRequired,
  isRestricted: PropTypes.bool.isRequired,
  allowedFunctions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Sider;
