import React, { useEffect, useState } from "react";

import {
  Avatar,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import fileDownload from "js-file-download";
import moment from "moment";

import useDebounce from "../../hooks/useDebounce";
import { getWalletWithdrawalRequests } from "../api";
import BulkWithdrawalHandler from "./BulkWithdrawalHandler";
import WalletLedgerTable from "./WalletLedgerTable";
import WithdrawalHandler from "./WithdrawalHandler";

const { Option } = Select;

const razorpayAutomaticPayoutStatuses = [
  "queued",
  "processing",
  "processed",
  "reversed",
  "failed",
];

const currencySymbol = (selectedCurrency) => {
  if (selectedCurrency === "EUR") {
    return <span className="inr">&#x20AC;</span>;
  }
  if (selectedCurrency === "INR") {
    return <span className="inr">&#8377;</span>;
  }
  if (selectedCurrency === "USD") {
    return <span className="inr">&#x24;</span>;
  }
  return <span className="inr">&#8377;</span>;
};

const WalletWithdrawals = () => {
  const [withdrawalsData, setWithdrawalsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState("pending");
  const [startDate, setStartDate] = useState(
    moment().subtract(7, "days").startOf("day")
  );
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [transferMode, setTransferMode] = useState("all");
  const [automaticEligible, setAutomaticEligible] = useState(false);
  const [payoutStatus, setPayoutStatus] = useState("all");
  const [selectedWithdrawals, setSelectedWithdrawals] = useState([]);
  const [bulkWithdrawalModalMode, setBulkWithdrawalModalMode] = useState("");
  const [bulkWithdrawalModalVisible, setBulkWithdrawalModalVisible] =
    useState(false);
  const [presentlyViewing, setPresentlyViewing] = useState("");
  const [whitelabelPlanType, setWhitelabelPlanType] = useState(null);
  const [userTier, setUserTier] = useState(null);
  const [resetTableFilter, setResetTableFilter] = useState(false);
  const debouncedSearch = useDebounce({
    value: search,
    callback: () => setPage(1),
  });

  const getWithdrawalRequestsData = async () => {
    setLoading(true);
    try {
      const resp = await getWalletWithdrawalRequests(
        startDate.toISOString(),
        endDate.toISOString(),
        status,
        debouncedSearch,
        transferMode === "all" ? undefined : transferMode,
        automaticEligible ? true : undefined,
        payoutStatus === "all" ? undefined : payoutStatus,
        whitelabelPlanType,
        userTier,
        page,
        limit
      );
      if (resp?.code === 0) {
        setWithdrawalsData(resp.result?.data);
        setTotal(resp.result?.total);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleWidthdrawalRequestCSVDownload = async () => {
    try {
      const resp = await getWalletWithdrawalRequests(
        startDate.toISOString(),
        endDate.toISOString(),
        status,
        debouncedSearch,
        transferMode === "all" ? undefined : transferMode,
        automaticEligible ? true : undefined,
        payoutStatus === "all" ? undefined : payoutStatus,
        whitelabelPlanType,
        userTier,
        undefined,
        undefined,
        true
      );
      fileDownload(
        resp,
        `withdrawal-requestscsv_${moment().format("DD-MM-YYYY")}.csv`
      );
      message.success("CSV download started successfully");
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  const dataTable = () => {
    const columnData = [
      {
        title: "Creator",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <Space size="small" direction="vertical">
            <Space size="small">
              <Avatar src={record?.profilePicUrl} size={32} />
              <Typography
                style={{ fontWeight: 500, fontSize: 14, color: "#1890ff" }}
              >
                {record?.name}
              </Typography>
            </Space>
            <Typography
              style={{
                fontWeight: 300,
                fontSize: 14,
              }}
            >
              {record?.email}
            </Typography>
            <Typography
              style={{
                fontWeight: 300,
                fontSize: 14,
              }}
            >
              {record?.phone}
            </Typography>
            <Typography.Link>{record?.host}</Typography.Link>
            <Typography>
              <strong>
                ({record?.whitelabelPlanType || "N/A"}, {record?.userTier})
              </strong>
            </Typography>
          </Space>
        ),
      },
      {
        title: "Bank Details",
        dataIndex: "accountNumber",
        key: "accountNumber",
        render: (text, record) => (
          <Space size="small" direction="vertical">
            {record?.country === "india" || record?.country === "IN" ? (
              <>
                <Typography>
                  <strong>Account Name: </strong>
                  {record?.accountName}
                </Typography>
                <Typography>
                  <strong>Account Number: </strong>
                  {record?.accountNumber}
                </Typography>
                <Typography>
                  <strong>Bank Name: </strong>
                  {record?.bankName}
                </Typography>
                <Typography>
                  <strong>IFSC: </strong>
                  {record?.ifsc}
                </Typography>
              </>
            ) : (
              <>
                <Typography>
                  <strong>Account Name: </strong>
                  {record?.internationalBankAccount?.account_holder_name}
                </Typography>
                <Typography>
                  <strong>Account Number: </strong>
                  {record?.internationalBankAccount?.account_number}
                </Typography>
                <Typography>
                  <strong>Bank Name: </strong>
                  {record?.internationalBankAccount?.bank_name}
                </Typography>
                <Typography>
                  <strong>Bank ID: </strong>
                  {record?.internationalBankAccount?.bank_id}
                </Typography>
                <Typography>
                  <strong>Currency: </strong>
                  {record?.internationalBankAccount?.currency}
                </Typography>
              </>
            )}
          </Space>
        ),
      },
      {
        title: "PAN",
        dataIndex: "pan",
        key: "pan",
        render: (text, record) => (
          <Typography>
            <a href={record?.panImage} target="_blank" rel="noreferrer">
              {record?.pan}
            </a>
          </Typography>
        ),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (text, record) => (
          <Typography>
            {currencySymbol(record?.currency)} {text}
          </Typography>
        ),
      },
      {
        title: "Approval Status",
        dataIndex: "approvalStatus",
        key: "approvalStatus",
        render: (text, record) => (
          <Space direction="vertical">
            <Typography>{text}</Typography>
            {record?.rejectionReason !== undefined &&
              record?.rejectionReason !== null && (
                <Typography>Reason: {record?.rejectionReason}</Typography>
              )}
            {record?.transferMode === "automatic" && (
              <>
                <Typography>Payout Status: {record?.payoutStatus}</Typography>
                <Typography>
                  Payout Status Description: {record?.payoutStatusDetails}
                </Typography>
              </>
            )}
          </Space>
        ),
      },
      {
        title: "Requested At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text, record) => (
          <Space direction="vertical">
            <Typography>
              {moment(record?.createdAt).format("DD/MM/YYYY hh:mm a")}
            </Typography>
            <Typography>({moment(record?.createdAt).fromNow()})</Typography>
          </Space>
        ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        render: (text, record) => (
          <WithdrawalHandler
            withdrawalId={record?._id}
            withdrawalStatus={record?.approvalStatus}
            setResetTableFilter={setResetTableFilter}
            onWithdrawalStatusChange={(newStatus) =>
              setWithdrawalsData(
                withdrawalsData.reduce(
                  (acc, curr) => [
                    ...acc,
                    curr._id === record?._id
                      ? { ...curr, approvalStatus: newStatus }
                      : curr,
                  ],
                  []
                )
              )
            }
            withdrawalMode={record?.transferMode}
          />
        ),
      },
    ];
    return (
      <Table
        dataSource={withdrawalsData}
        columns={columnData}
        loading={loading}
        pagination={{
          pageSize: limit,
          total,
          onChange: setPage,
          current: page,
          onShowSizeChange: (current, size) => setLimit(size),
        }}
        rowKey={(record) => record?._id}
        expandable={{
          expandedRowRender: (record) => (
            <WalletLedgerTable creatorId={record?.creatorId} />
          ),
          expandedRowKeys: [presentlyViewing],
          onExpand: (expanded, record) => {
            if (expanded) {
              setPresentlyViewing(record?._id);
            } else {
              setPresentlyViewing("");
            }
          },
        }}
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedWithdrawals,
          onChange: (selectedRowKeys) => {
            setSelectedWithdrawals(selectedRowKeys);
          },
          getCheckboxProps: (record) => {
            if (record?.approvalStatus !== "pending") {
              return { disabled: true };
            }
            if (record?.payoutContact?.verificationStatus !== "completed") {
              return { disabled: true };
            }
          },
          preserveSelectedRowKeys: true,
        }}
      />
    );
  };

  const resetStates = () => {
    setStartDate(moment().subtract(7, "days").startOf("day"));
    setEndDate(moment().endOf("day"));
    setSearch("");
    setAutomaticEligible(false);
    setTransferMode("all");
    setPayoutStatus("all");
    setWhitelabelPlanType(null);
    setUserTier(null);
    setLimit(10);
    setPage(1);
    setStatus("pending");
    setSelectedWithdrawals([]);
    setWithdrawalsData([]);
    setTotal(0);
    setBulkWithdrawalModalMode("");
    setBulkWithdrawalModalVisible(false);
    setPresentlyViewing("");
  };

  useEffect(() => {
    if (status === "pending") {
      setStartDate(moment("2020-12-28 14:30:14.755Z").startOf("day"));
      setPage(1);
    } else {
      setStartDate(moment().subtract(7, "days").startOf("day"));
      setPage(1);
    }
  }, [status]);

  useEffect(() => {
    if (resetTableFilter) return false;
    getWithdrawalRequestsData();
  }, [
    startDate,
    endDate,
    debouncedSearch,
    automaticEligible,
    transferMode,
    payoutStatus,
    whitelabelPlanType,
    userTier,
    page,
    limit,
    resetTableFilter,
  ]);

  useEffect(() => {
    if (resetTableFilter) {
      setLoading(true);
      resetStates();
      setTimeout(() => {
        setResetTableFilter(false);
      }, 1000);
    }
  }, [resetTableFilter]);

  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: "10px",
        }}
      >
        <Col span={8}>
          <Input
            style={{ width: "100%" }}
            placeholder="Search Creator name, email, phone or host"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Col>
        <Col span={16}>
          <Space>
            <DatePicker.RangePicker
              value={[startDate, endDate]}
              onChange={([d1, d2]) => {
                if (d1 && d2) {
                  setStartDate(d1);
                  setEndDate(d2);
                } else {
                  setStartDate(moment().subtract(7, "days").startOf("day"));
                  setEndDate(moment().endOf("day"));
                }
              }}
              disabled={status === "pending"}
            />
            <Select
              placeholder="Approval Status"
              onSelect={setStatus}
              value={status}
            >
              <Option value="pending">Pending</Option>
              <Option value="approved">Approved</Option>
              <Option value="rejected">Rejected</Option>
            </Select>
            {status !== "pending" ? (
              <>
                <Select
                  placeholder="Transfer Mode"
                  value={transferMode}
                  onSelect={setTransferMode}
                  style={{
                    width: 100,
                  }}
                >
                  <Option value="all">All</Option>
                  <Option value="automatic">Automatic</Option>
                  <Option value="manual">Manual</Option>
                </Select>
                <Select
                  value={payoutStatus}
                  onSelect={setPayoutStatus}
                  style={{
                    width: 100,
                  }}
                >
                  <Option value="all">All</Option>
                  {razorpayAutomaticPayoutStatuses.map((st) => (
                    <Option value={st}>{st}</Option>
                  ))}
                </Select>
              </>
            ) : (
              <Checkbox
                value={automaticEligible}
                onChange={(e) => setAutomaticEligible(e.target.checked)}
              >
                Automatic Eligible
              </Checkbox>
            )}
            <Button onClick={() => getWithdrawalRequestsData()}>Refresh</Button>
            <Button
              type="primary"
              onClick={() => handleWidthdrawalRequestCSVDownload()}
            >
              Download CSV
            </Button>
          </Space>
        </Col>
      </Row>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: "10px",
        }}
      >
        <Col span={24}>
          <Space
            style={{
              width: "100%",
            }}
          >
            <Select
              allowClear
              style={{
                width: "200px",
              }}
              placeholder="Select Plan"
              value={whitelabelPlanType}
              onSelect={(value) => setWhitelabelPlanType(value)}
              onClear={() => setWhitelabelPlanType(null)}
            >
              <Option value="freedom-plan">Freedom plan</Option>
              <Option value="enterprise-plan">Enterprise plan</Option>
            </Select>
            <Select
              allowClear
              style={{
                width: "200px",
              }}
              placeholder="Select Tier"
              value={userTier}
              onSelect={(value) => setUserTier(value)}
              onClear={() => setUserTier(null)}
            >
              <Option value="tier1">Tier 1</Option>
              <Option value="tier2">Tier 2</Option>
              <Option value="tier3">Tier 3</Option>
              <Option value="tier4">Tier 4</Option>
            </Select>
          </Space>
        </Col>
      </Row>
      {selectedWithdrawals?.length > 0 && (
        <Row
          style={{
            marginBottom: "10px",
          }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Typography>
              {selectedWithdrawals?.length} Withdrawals selected
            </Typography>
            <Button
              type="primary"
              onClick={() => {
                setBulkWithdrawalModalMode("approve");
                setBulkWithdrawalModalVisible(true);
              }}
            >
              Bulk Approve
            </Button>
            <Button
              type="danger"
              onClick={() => {
                setBulkWithdrawalModalMode("reject");
                setBulkWithdrawalModalVisible(true);
              }}
            >
              Bulk Reject
            </Button>
          </Space>
        </Row>
      )}
      {/* <Button onClick={() => setResetTableFilter(true)}>Reset button</Button> */}
      <BulkWithdrawalHandler
        withdrawalIds={selectedWithdrawals}
        modalMode={bulkWithdrawalModalMode}
        setModalMode={setBulkWithdrawalModalMode}
        showModal={bulkWithdrawalModalVisible}
        setShowModal={setBulkWithdrawalModalVisible}
        setResetTableFilter={setResetTableFilter}
        onWithdrawalStatusChange={(withdrawalIds, newStatus) => {
          const withSet = new Set(withdrawalIds);
          setWithdrawalsData(
            withdrawalsData.reduce(
              (acc, curr) => [
                ...acc,
                withSet.has(curr._id)
                  ? { ...curr, approvalStatus: newStatus }
                  : curr,
              ],
              []
            )
          );
        }}
      />
      {dataTable()}
    </>
  );
};

export default WalletWithdrawals;
