import { Button, message, Space, Table, Typography } from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { getPendingBytescareRequests, approveRequestToBytescare } from "../api";

const BytescareRequests = () => {
  const [pendingRequests, setPendingRequests] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchPendingRequests = async () => {
    setLoading(true);
    try {
      const resp = await getPendingBytescareRequests();
      if (resp.code === 0) {
        const mappedRequests = resp?.result?.map((request) => ({
          ...request,
          user: request?.creator || request?.takedownUser,
        }));
        setPendingRequests(mappedRequests);
      } else {
        message.error("Error fetching pending requests");
      }
    } catch (err) {
      message.error("Error fetching pending requests");
    }
    setLoading(false);
  };

  const approveRequest = async (takedownRequestId) => {
    const hide = message.loading("Approving request...");
    try {
      const resp = await approveRequestToBytescare({ takedownRequestId });
      if (resp.code === 0) {
        message.success("Request approved");
        fetchPendingRequests();
      } else {
        message.error("Error approving request");
      }
    } catch (err) {
      message.error("Error approving request");
    }
    hide();
  };

  const columns = [
    {
      title: "Creator",
      dataIndex: "creator",
      key: "user",
      render: (text, record) => (
        <Space direction="vertical">
          <Typography strong>
            {(record?.creator || record?.takedownUser)?.name}
          </Typography>
          <Typography>{record.creator?.email}</Typography>
        </Space>
      ),
    },
    {
      title: "Leak Description",
      dataIndex: "foundWhere",
      key: "foundWhere",
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      render: (text) => <a href={text}>{text}</a>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Submitted At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (
        <Space direction="vertical">
          <Typography>{moment(text).format("DD/MM/YYYY hh:mm a")}</Typography>
          <Typography>({moment(text).fromNow()})</Typography>
        </Space>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Button onClick={() => approveRequest(record?._id)}>Approve</Button>
      ),
    },
  ];

  useEffect(() => {
    fetchPendingRequests();
  }, []);

  return (
    <Table columns={columns} dataSource={pendingRequests} loading={loading} />
  );
};

export default BytescareRequests;
