import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { Select, message } from "antd";
import { changeCreatorNature } from "../api";

const CreatorNatureAction = ({ userId, presentNature, onNatureChange }) => {
  const [loading, setLoading] = useState(false);
  const [nature, setNature] = useState(presentNature);
  const changeNature = async (changedNature) => {
    setLoading(true);
    try {
      const resp = await changeCreatorNature({
        userId,
        nature: changedNature,
      });
      if (resp.code === 0) {
        setNature(changedNature);
        onNatureChange(changedNature);
        message.success("Creator nature changed successfully");
      } else {
        message.error(resp.message);
      }
    } catch (error) {
      message.error(error.message);
    }
    setLoading(false);
  };
  return (
    <>
      {loading ? (
        <LoadingOutlined
          style={{
            fontSize: "1rem",
          }}
        />
      ) : (
        <Select onChange={changeNature} defaultValue={nature}>
          <Select.Option value="organic">Organic</Select.Option>
          <Select.Option value="growth">Growth</Select.Option>
          <Select.Option value="retention">Retention</Select.Option>
          <Select.Option value="mia">MIA</Select.Option>
        </Select>
      )}
    </>
  );
};

CreatorNatureAction.propTypes = {
  userId: PropTypes.string.isRequired,
  presentNature: PropTypes.string.isRequired,
  onNatureChange: PropTypes.func.isRequired,
};

export default CreatorNatureAction;
