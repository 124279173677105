import React, { useState } from "react";

import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Typography,
  Upload,
} from "antd";
import PropTypes from "prop-types";

import { UploadOutlined } from "@ant-design/icons";

import { getWalletWithdrawalOtp, handleWalletWithdrawalRequest } from "../api";

const WithdrawalHandler = ({
  withdrawalId,
  withdrawalStatus,
  onWithdrawalStatusChange,
  setResetTableFilter,
}) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const [form] = Form.useForm();
  const [sendingOtp, setSendingOtp] = useState(false);

  const clickHandler = (action) => {
    setShowModal(true);
    setModalMode(action);
  };

  const handleSendWithdrawalOTP = async () => {
    setSendingOtp(true);
    try {
      const resp = await getWalletWithdrawalOtp(withdrawalId);
      if (resp?.code === 0) {
        message.success("OTP sent successfully");
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      setSendingOtp(false);
    }
  };

  const actionsOnStatus = {
    pending: (
      <Space size="small" direction="vertical">
        <Button type="primary" block onClick={() => clickHandler("approve")}>
          Approve
        </Button>
        <Button type="danger" block onClick={() => clickHandler("reject")}>
          Reject
        </Button>
      </Space>
    ),
    approved: (
      <>
        <Space direction="vertical">
          <Typography style={{ color: "green" }}>Approved</Typography>
        </Space>
      </>
    ),
    rejected: <Typography style={{ color: "red" }}>Rejected</Typography>,
  };

  const modalBodyBasedOnAction = {
    approve: (
      <>
        <Form.Item name="transferMode" label="Transfer Mode">
          <Select>
            <Select.Option value="automatic">Automatic</Select.Option>
            <Select.Option value="manual">Manual</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) => prev.transferMode !== curr.transferMode}
        >
          {({ getFieldValue }) => {
            const transferMode = getFieldValue("transferMode");
            switch (transferMode) {
              case "manual": {
                return (
                  <>
                    <Form.Item
                      name="transferId"
                      label="Transfer ID"
                      rules={[
                        { required: true, message: "Transfer Id is mandatory" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name="proofOfTransfer" label="Transfer Proof">
                      <Upload
                        beforeUpload={() => false}
                        accept={[".png", ".jpg", ".jpeg"]}
                      >
                        <Button icon={<UploadOutlined />}>
                          Click to Upload
                        </Button>
                      </Upload>
                    </Form.Item>
                  </>
                );
              }
              case "automatic": {
                return (
                  <>
                    <Form.Item
                      name="otp"
                      label="OTP"
                      rules={[{ required: true, message: "OTP is mandatory" }]}
                    >
                      <Input />
                    </Form.Item>
                    <Button
                      type="primary"
                      onClick={() => handleSendWithdrawalOTP()}
                      loading={sendingOtp}
                    >
                      Send OTP
                    </Button>
                  </>
                );
              }
              default:
                return null;
            }
          }}
        </Form.Item>
      </>
    ),
    reject: (
      <>
        <Form.Item
          name="rejectionReason"
          label="Rejection Reason"
          rules={[
            { required: true, message: "Must have a reason for rejection" },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </>
    ),
  };

  const handleApprovalStatusChange = async (values) => {
    const reqBody = {
      ...values,
      withdrawalId,
      ...(modalMode === "approve"
        ? { approvalStatus: "approved" }
        : { approvalStatus: "rejected" }),
      ...(values?.proofOfTransfer
        ? { proofOfTransfer: values?.proofOfTransfer?.file }
        : {}),
    };
    const formReqBody = new FormData();
    Object.keys(reqBody).forEach((key) => {
      if (reqBody[key]) {
        formReqBody.append(key, reqBody[key]);
      }
    });
    setLoading(true);
    try {
      const resp = await handleWalletWithdrawalRequest(formReqBody);
      if (resp?.code === 0) {
        setShowModal(false);
        onWithdrawalStatusChange(reqBody?.approvalStatus);
        message.success("Successfully updated");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 800);
        setResetTableFilter(true);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error(err?.message || "Something went wrong");
    } finally {
      setLoading(false);
      setShowModal(false);
      setModalMode("");
      form.resetFields();
    }
  };

  return (
    <>
      <Modal
        title={
          <Typography>
            {modalMode === "approve"
              ? "Approve this request?"
              : "Reject this request?"}
          </Typography>
        }
        visible={showModal}
        footer={[
          <Button
            type="primary"
            htmlType="submit"
            form="withdrawal-action-form"
            loading={loading}
          >
            Submit
          </Button>,
        ]}
        destroyOnClose
        onCancel={() => {
          setShowModal(false);
          setModalMode("");
          form.resetFields();
        }}
      >
        <Form
          form={form}
          id="withdrawal-action-form"
          onFinish={handleApprovalStatusChange}
        >
          <Form.Item noStyle name="withdrawalId">
            <Input type="hidden" value={withdrawalId} />
          </Form.Item>
          {modalBodyBasedOnAction[modalMode]}
        </Form>
      </Modal>
      {actionsOnStatus[withdrawalStatus]}
    </>
  );
};

WithdrawalHandler.propTypes = {
  withdrawalId: PropTypes.string.isRequired,
  withdrawalStatus: PropTypes.string.isRequired,
  onWithdrawalStatusChange: PropTypes.func.isRequired,
  setResetTableFilter: PropTypes.func.isRequired,
};

export default WithdrawalHandler;
