import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import fileDownload from "js-file-download";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toFixedNumber } from "../../Utils/utilityFunctions";
import useDebounce from "../../hooks/useDebounce";
import {
  getCommissionTaxReport,
  getMangoCreditsTaxReport,
  getWhatsappSubscriptionTaxReport,
} from "../api";

const { Option } = Select;

const nameStyle = { fontWeight: 500, fontSize: 14, color: "#1890ff" };

const TaxReports = () => {
  const [search, setSearch] = useState("");
  const [dateString, setDateString] = useState(moment().format("YYYY-MM"));
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [reportType, setReportType] = useState("commissions");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalFieldsData, setTotalFieldsData] = useState({});
  const [csvLoading, setCsvLoading] = useState(false);
  const debouncedSearch = useDebounce({
    value: search,
    callback: () => setPage(1),
  });

  const gridStyle = {
    width: "50%",
    textAlign: "center",
    height: "100%",
  };

  const topGridStyle = {
    width: "100%",
    textAlign: "center",
    height: "100%",
  };

  const cardStyle = {
    height: "100%",
    textAlign: "center",
  };

  const getCardsToDisplayOnReportType = () => {
    switch (reportType) {
      case "commissions": {
        return (
          <>
            <Col span={6}>
              <Card title="Earnings" bordered={false} style={cardStyle}>
                <Card.Grid style={topGridStyle}>
                  {totalFieldsData?.totalEarning?.toFixed(2)}
                </Card.Grid>
                <Card.Grid style={gridStyle} title="Commission">
                  <strong>Commission:</strong> <br />
                  {totalFieldsData?.totalCommission?.toFixed(2)}
                </Card.Grid>
                <Card.Grid style={gridStyle}>
                  <strong>Convenience:</strong> <br />
                  {totalFieldsData?.totalConvenience?.toFixed(2)}
                </Card.Grid>
              </Card>
            </Col>
            <Col span={6}>
              <Card
                title="Commission Charged"
                bordered={false}
                style={cardStyle}
              >
                <Card.Grid style={topGridStyle}>
                  {totalFieldsData?.totalCommission?.toFixed(2)}
                </Card.Grid>
                <Card.Grid style={gridStyle} title="Commission">
                  <strong>Without GST:</strong> <br />
                  {totalFieldsData?.totalCommissionWithoutGst?.toFixed(2)}
                </Card.Grid>
                <Card.Grid style={gridStyle}>
                  <strong>GST:</strong> <br />
                  {totalFieldsData?.totalCommissionGst?.toFixed(2)}
                </Card.Grid>
              </Card>
            </Col>
            <Col span={6}>
              <Card
                title="Convenience Charged"
                bordered={false}
                style={cardStyle}
              >
                <Card.Grid style={topGridStyle}>
                  {totalFieldsData?.totalConvenience?.toFixed(2)}
                </Card.Grid>
                <Card.Grid style={gridStyle} title="Commission">
                  <strong>Without GST:</strong> <br />
                  {totalFieldsData?.totalConvenienceWithoutGst?.toFixed(2)}
                </Card.Grid>
                <Card.Grid style={gridStyle}>
                  <strong>GST:</strong> <br />
                  {totalFieldsData?.totalConvenienceGst?.toFixed(2)}
                </Card.Grid>
              </Card>
            </Col>
            <Col span={6}>
              <Card title="Total Creators" bordered={false} style={cardStyle}>
                <Typography>{total}</Typography>
              </Card>
            </Col>
          </>
        );
      }
      case "whatsappsubscriptions": {
        return (
          <>
            <Col span={12}>
              <Card
                title="Total Amount Charged"
                bordered={false}
                style={cardStyle}
              >
                <Card.Grid style={topGridStyle}>
                  {totalFieldsData?.totalPaid?.toFixed(2)}
                </Card.Grid>
                <Card.Grid style={gridStyle} title="Commission">
                  <strong>Without GST:</strong> <br />
                  {totalFieldsData?.totalPaidWithoutGst?.toFixed(2)}
                </Card.Grid>
                <Card.Grid style={gridStyle}>
                  <strong>GST:</strong> <br />
                  {totalFieldsData?.totalPaidGst?.toFixed(2)}
                </Card.Grid>
              </Card>
            </Col>
            <Col span={12}>
              <Card title="Total Creators" bordered={false} style={cardStyle}>
                <Typography>{total}</Typography>
              </Card>
            </Col>
          </>
        );
      }
      case "mangocredits": {
        return (
          <>
            <Col span={8}>
              <Card
                title="Total Amount Charged"
                bordered={false}
                style={cardStyle}
              >
                <Card.Grid style={topGridStyle}>
                  {totalFieldsData?.totalAmount?.toFixed(2)}
                </Card.Grid>
                <Card.Grid style={gridStyle} title="Commission">
                  <strong>Without GST:</strong> <br />
                  {totalFieldsData?.totalAmountWithoutGst?.toFixed(2)}
                </Card.Grid>
                <Card.Grid style={gridStyle}>
                  <strong>GST:</strong> <br />
                  {totalFieldsData?.totalGst?.toFixed(2)}
                </Card.Grid>
              </Card>
            </Col>
            <Col span={8}>
              <Card
                title="Total Convenience Charged"
                bordered={false}
                style={cardStyle}
              >
                <Card.Grid style={topGridStyle}>
                  {totalFieldsData?.totalConvenience?.toFixed(2)}
                </Card.Grid>
                <Card.Grid style={gridStyle} title="Commission">
                  <strong>Without GST:</strong> <br />
                  {totalFieldsData?.totalConvenienceWithoutGst?.toFixed(2)}
                </Card.Grid>
                <Card.Grid style={gridStyle}>
                  <strong>GST:</strong> <br />
                  {totalFieldsData?.totalConvenienceGst?.toFixed(2)}
                </Card.Grid>
              </Card>
            </Col>
            <Col span={8}>
              <Card title="Total Creators" bordered={false} style={cardStyle}>
                <Typography>{total}</Typography>
              </Card>
            </Col>
          </>
        );
      }
      default:
        return null;
    }
  };

  const getTableOnReportType = () => {
    const detailsColumns = [
      {
        title: "Creator",
        dataIndex: "creatorId",
        key: "creatorId",
        render: (text, record) => (
          <Space size="small" direction="horizontal">
            <Avatar src={record?.creatorProfilePicUrl} size={32} />
            <Typography style={nameStyle}>{record?.creatorName}</Typography>
          </Space>
        ),
      },
    ];
    const gstColumns = [
      {
        title: "GST Number",
        dataIndex: "gstNumber",
        key: "gstNumber",
        render: (text, record) => (
          <Space size="small" direction="vertical">
            <Typography>{record?.gstNumber}</Typography>
            <Typography>{record?.gstName}</Typography>
          </Space>
        ),
      },
      {
        title: "GST Address",
        dataIndex: "gstRegistrationAddress",
        key: "gstRegistrationAddress",
        render: (text, record) => (
          <Space size="small" direction="vertical">
            <Typography>Address: {record?.gstRegistrationAddress}</Typography>
            <Typography>State: {record?.gstState}</Typography>
          </Space>
        ),
      },
    ];
    let specificColumns = [];
    switch (reportType) {
      case "commissions": {
        specificColumns = [
          {
            title: "Commission",
            dataIndex: "totalCommissionWithoutGst",
            key: "totalCommissionWithoutGst",
            render: (text) => toFixedNumber(text, 3),
          },
          {
            title: "Commission GST",
            dataIndex: "totalCommissionGst",
            key: "totalCommissionGst",
            render: (text) => toFixedNumber(text, 3),
          },
          {
            title: "Convenience",
            dataIndex: "totalConvenienceWithoutGst",
            key: "totalConvenienceWithoutGst",
            render: (text) => toFixedNumber(text, 3),
          },
          {
            title: "Convenience GST",
            dataIndex: "totalConvenienceGst",
            key: "totalConvenienceGst",
            render: (text) => toFixedNumber(text, 3),
          },
        ];
        break;
      }
      case "whatsappsubscriptions": {
        specificColumns = [
          {
            title: "Amount (Without GST)",
            dataIndex: "totalPaidWithoutGst",
            key: "totalPaidWithoutGst",
            render: (text) => toFixedNumber(text, 3),
          },
          {
            title: "GST",
            dataIndex: "totalPaidGst",
            key: "totalPaidGst",
            render: (text) => toFixedNumber(text, 3),
          },
        ];
        break;
      }
      case "mangocredits": {
        specificColumns = [
          {
            title: "Amount (Without GST)",
            dataIndex: "totalAmountWithoutGst",
            key: "totalAmountWithoutGst",
            render: (text) => toFixedNumber(text, 3),
          },
          {
            title: "GST",
            dataIndex: "totalGst",
            key: "totalGst",
            render: (text) => toFixedNumber(text, 3),
          },
          {
            title: "Convenience (Without GST)",
            dataIndex: "totalConvenienceWithoutGst",
            key: "totalConvenienceWithoutGst",
            render: (text) => toFixedNumber(text, 3),
          },
          {
            title: "Convenience GST",
            dataIndex: "totalConvenienceGst",
            key: "totalConvenienceGst",
            render: (text) => toFixedNumber(text, 3),
          },
        ];
        break;
      }
      default:
        break;
    }
    return (
      <Table
        columns={[...detailsColumns, ...specificColumns, ...gstColumns]}
        dataSource={data}
        pagination={{
          total,
          current: page,
          onChange: setPage,
          showSizeChanger: false,
          pageSize: 10,
          position: ["bottomCenter"],
        }}
        loading={loading}
      />
    );
  };

  const getDataOnReportType = async () => {
    setLoading(true);
    let resp;
    let totalFields = [];
    const mom = moment(`${dateString}-02T18:30Z`);
    const startDate = mom.startOf("month")?.toISOString();
    const endDate = mom?.endOf("month")?.toISOString();
    try {
      switch (reportType) {
        case "commissions": {
          resp = await getCommissionTaxReport(
            startDate,
            endDate,
            debouncedSearch,
            page,
            10
          );
          totalFields = [
            "totalEarning",
            "totalCommission",
            "totalConvenience",
            "totalCommissionWithoutGst",
            "totalCommissionGst",
            "totalConvenienceWithoutGst",
            "totalConvenienceGst",
          ];
          break;
        }
        case "whatsappsubscriptions": {
          resp = await getWhatsappSubscriptionTaxReport(
            startDate,
            endDate,
            debouncedSearch,
            page,
            10
          );
          totalFields = ["totalPaid", "totalPaidWithoutGst", "totalPaidGst"];
          break;
        }
        case "mangocredits": {
          resp = await getMangoCreditsTaxReport(
            startDate,
            endDate,
            debouncedSearch,
            page,
            10
          );
          totalFields = [
            "totalAmount",
            "totalAmountWithoutGst",
            "totalGst",
            "totalConvenience",
            "totalConvenienceWithoutGst",
            "totalConvenienceGst",
          ];
          break;
        }
        default:
          break;
      }
      if (resp?.code === 0) {
        setData(resp?.result?.data);
        setTotal(resp?.result?.total);
        const tempTotalFieldsData = {};
        totalFields.forEach((field) => {
          tempTotalFieldsData[field] = resp?.result?.[field];
        });
        setTotalFieldsData(tempTotalFieldsData);
      } else {
        message.error(resp?.message || "Something went wrong");
      }
    } catch (err) {
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const getCSVOnReportType = async () => {
    setCsvLoading(true);
    let resp;
    const mom = moment(`${dateString}-02T18:30Z`);
    const startDate = mom.startOf("month")?.toISOString();
    const endDate = mom?.endOf("month")?.toISOString();
    try {
      switch (reportType) {
        case "commissions": {
          resp = await getCommissionTaxReport(
            startDate,
            endDate,
            debouncedSearch,
            undefined,
            undefined,
            true
          );
          break;
        }
        case "whatsappsubscriptions": {
          resp = await getWhatsappSubscriptionTaxReport(
            startDate,
            endDate,
            debouncedSearch,
            undefined,
            undefined,
            true
          );
          break;
        }
        case "mangocredits": {
          resp = await getMangoCreditsTaxReport(
            startDate,
            endDate,
            debouncedSearch,
            undefined,
            undefined,
            true
          );
          break;
        }
        default:
          break;
      }
      fileDownload(resp, `${reportType}-${Date.now()}.csv`);
    } catch (err) {
      message.error("Something went wrong");
    } finally {
      setCsvLoading(false);
    }
  };

  useEffect(() => {
    getDataOnReportType();
  }, [page, dateString, debouncedSearch, reportType]);

  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: "16px",
          width: "100%",
        }}
      >
        {getCardsToDisplayOnReportType()}
      </Row>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: "10px",
        }}
      >
        <Col span={12}>
          <Input
            style={{ width: "100%" }}
            placeholder="Search Creator"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Col>
        <Col span={12}>
          <Space>
            <DatePicker
              picker="month"
              onChange={(_, ds) => {
                setDateString(ds);
              }}
              value={dateString ? moment(dateString, "YYYY-MM") : null}
            />
            <Select
              placeholder="Approval Status"
              onSelect={setReportType}
              value={reportType}
            >
              <Option value="commissions">Commissions</Option>
              <Option value="whatsappsubscriptions">
                Whatsapp Subscriptions
              </Option>
              <Option value="mangocredits">Mango Credits</Option>
            </Select>
            <Button>Refresh</Button>
            <Button
              type="primary"
              onClick={() => getCSVOnReportType()}
              loading={csvLoading}
            >
              Download CSV
            </Button>
          </Space>
        </Col>
      </Row>
      {getTableOnReportType()}
    </>
  );
};

export default TaxReports;
