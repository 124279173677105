const config = {
  // baseURL: 'https://newstaging.tagmango.in/', // Dev API URL
  // baseURL: 'https://testing.tagmango.in/',
  baseURL: "http://localhost:6969/",
  // baseURL: 'https://api-dashboard.tagmango.in/',
  appURL: "https://tagmango.com/", // Live App URL
  ACServerUrl: "https://gzvrdq2769.execute-api.us-east-1.amazonaws.com/Prod/",
  ACServerRegion: "us-east-1",
  baseApiUrl: process.env.REACT_APP_API_BASE || "https://testing.tagmango.in",
};

export default config;
