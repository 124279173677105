import React, { useEffect, useState } from "react";
import { Table, Typography, message, Space, Button } from "antd";
import { DebounceInput } from "react-debounce-input";
import PropTypes from "prop-types";
import fileDownload from "js-file-download";
import { getOrdersOfAffiliate } from "../api";

const AffiliateOrdersTable = ({ affiliateId, startDate, endDate }) => {
  const [ordersData, setOrdersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);

  const getOrdersOfAffiliateData = async () => {
    setLoading(true);
    try {
      const resp = await getOrdersOfAffiliate(
        affiliateId,
        startDate,
        endDate,
        search,
        page,
        10
      );
      if (resp?.code === 0) {
        setOrdersData(resp.result?.data);
        setTotal(resp.result?.total);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const getAffiliateOrdersCsv = async () => {
    try {
      const resp = await getOrdersOfAffiliate(
        affiliateId,
        startDate,
        endDate,
        search,
        undefined,
        undefined,
        true
      );
      fileDownload(resp, `affiliate-${affiliateId}-orders.csv`);
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  const getAffiliateOrdersTable = () => {
    const columnData = [
      {
        title: "Order ID",
        dataIndex: "orderId",
        key: "orderId",
      },
      {
        title: "Creator",
        dataIndex: "creatorName",
        key: "creatorName",
      },
      {
        title: "Fan Details",
        dataIndex: "fanName",
        key: "fanName",
        render: (text, record) => (
          <Space size="small" direction="vertical">
            <Typography
              style={{ fontWeight: 500, fontSize: 14, color: "#1890ff" }}
            >
              {record?.fanName}
            </Typography>
            <Typography
              style={{
                fontWeight: 300,
                fontSize: 14,
              }}
            >
              {record?.fanEmail}
            </Typography>
            <Typography
              style={{
                fontWeight: 300,
                fontSize: 14,
              }}
            >
              {record?.fanPhone}
            </Typography>
          </Space>
        ),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
      },
      {
        title: "Commission",
        dataIndex: "commission",
        key: "commission",
      },
      {
        title: "TM Commission",
        dataIndex: "tmCommission",
        key: "tmCommission",
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
      },
    ];

    return (
      <Table
        columns={columnData}
        dataSource={ordersData}
        loading={loading}
        pagination={{
          current: page,
          total,
          pageSize: 10,
          onChange: (current) => setPage(current),
        }}
      />
    );
  };

  useEffect(() => {
    getOrdersOfAffiliateData();
  }, [page, search]);

  return (
    <>
      <Space size="small" style={{ width: "100%", marginBottom: "10px" }}>
        <DebounceInput
          style={{ width: "25rem" }}
          placeholder="Search orders"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button type="primary" onClick={() => getAffiliateOrdersCsv()}>
          Download Breakdown CSV
        </Button>
      </Space>
      {getAffiliateOrdersTable()}
    </>
  );
};

AffiliateOrdersTable.propTypes = {
  affiliateId: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default AffiliateOrdersTable;
