import React, { useState } from "react";
import {
  Typography,
  Checkbox,
  message,
  Button,
  Modal,
  Form,
  Select,
  Input,
} from "antd";
import PropTypes from "prop-types";
import { signup } from "../api";
import { AVAILABLE_PAGES } from "../../Utils/constants";

const NewUserCreationForm = ({ visible, setVisible }) => {
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    setSubmitting(true);
    try {
      const resp = await signup(values);
      if (resp.code === 0) {
        setVisible(false);
        message.success("User created successfully");
      } else {
        message.error("Something went wrong" || resp?.message);
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
    form.resetFields();
    setSubmitting(false);
  };

  return (
    <Modal
      title={<Typography>Create a new User</Typography>}
      footer={[
        <Button
          type="primary"
          htmlType="submit"
          form="new-user-form"
          loading={submitting}
        >
          Submit
        </Button>,
      ]}
      onCancel={() => {
        form.resetFields();
        setVisible(false);
      }}
      visible={visible}
      destroyOnClose
    >
      <Form form={form} id="new-user-form" onFinish={handleSubmit}>
        <Form.Item label="Email" name="email">
          <Input type="email" />
        </Form.Item>
        <Form.Item label="Password" name="password">
          <Input />
        </Form.Item>
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item
          name="isRestricted"
          label="Restricted"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prev, current) =>
            prev.isRestricted !== current?.isRestricted
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("isRestricted") ? (
              <Form.Item name="allowedFunctions" label="Allowed Functions">
                <Select mode="multiple" allowClear>
                  {AVAILABLE_PAGES.map((page) => (
                    <Select.Option key={page.key} value={page.key}>
                      {page.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : null
          }
        </Form.Item>
      </Form>
    </Modal>
  );
};

NewUserCreationForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};

export default NewUserCreationForm;
