/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col, Table, Space, Select, DatePicker, message } from "antd";
import { DebounceInput } from "react-debounce-input";
import moment from "moment";
import { getCreatorFunnelUTMS, getFunnelCounts } from "../api";
import FunnelExpandTable from "./FunnelExpandTable";

const CreatorsFunnel = () => {
  const [utmOptions, setUtmOptions] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().startOf("day").subtract(7, "days")
  );
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [funnelStages, setFunnelStages] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");

  const loadUtmCampaignOptions = async (searchTerm) => {
    try {
      const resp = await getCreatorFunnelUTMS(searchTerm, 1, 100);
      if (resp.code === 0) {
        setUtmOptions(resp.result?.map((utm) => ({ label: utm, value: utm })));
      } else {
        message.error("Error fetching utm campaigns");
      }
    } catch (error) {
      message.error("Error fetching utm campaigns");
    }
  };

  const loadFunnelCounts = async (utm_campaign) => {
    try {
      const resp = await getFunnelCounts(
        utm_campaign,
        startDate.toISOString(),
        endDate.toISOString()
      );
      if (resp.code === 0) {
        setFunnelStages(
          Object.entries(resp.result)?.map(([key, value]) => ({
            funnelStage: key,
            count: value,
          }))
        );
      } else {
        message.error("Error fetching funnel counts");
      }
    } catch (error) {
      message.error("Error fetching funnel counts");
    }
  };

  useEffect(() => {
    loadUtmCampaignOptions("");
  }, []);

  useEffect(() => {
    loadFunnelCounts(selectedCampaign);
  }, [selectedCampaign]);

  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: "16px",
          width: "100%",
        }}
      >
        <Col span={24}>
          <Space
            style={{
              width: "100%",
            }}
          >
            <Select
              style={{
                width: "200px",
              }}
              options={utmOptions}
              onSearch={(value) => loadUtmCampaignOptions(value)}
              onSelect={setSelectedCampaign}
              onClear={() => setSelectedCampaign("")}
              placeholder="Search by utm campaign"
              allowClear
              showSearch
            />
            <DatePicker.RangePicker
              onChange={([start, end]) => {
                if (start && end) {
                  setStartDate(start);
                  setEndDate(end);
                } else {
                  setStartDate(moment().startOf("day").subtract(7, "days"));
                  setEndDate(moment().endOf("day"));
                }
              }}
              value={[startDate, endDate]}
            />
          </Space>
        </Col>
      </Row>
      <Table
        columns={[
          {
            title: "Funnel Stage",
            dataIndex: "funnelStage",
            key: "funnelStage",
          },
          {
            title: "Count",
            dataIndex: "count",
            key: "count",
          },
        ]}
        dataSource={funnelStages}
        rowKey={(row) => row.funnelStage}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => (
            <FunnelExpandTable
              funnelStage={record.funnelStage}
              utm_campaign={selectedCampaign}
              startDate={startDate?.toDate()}
              endDate={endDate?.toDate()}
            />
          ),
        }}
      />
    </>
  );
};

export default CreatorsFunnel;
