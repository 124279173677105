/* eslint-disable no-unused-vars */
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Avatar,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { deactivateCoupon, getAllInfluencers, getCouponsV2 } from "../api";

import "antd/dist/antd.css";
import "../../App.min.css";
import useDebounce from "../../hooks/useDebounce";
import CreateCouponForm from "./CreateCouponForm";

const CouponSection = () => {
  const [couponList, setCouponList] = useState([]);
  const [users, setUsers] = useState([]);
  const history = useHistory();
  const [startDate, setStartDate] = useState(
    moment().add(-7, "days").startOf("day")
  );
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [toggleCreate, setToggleCreate] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [creatorquery, setCreatorQuery] = useState("");
  const [radioValue, setRadioValue] = React.useState("coupon");
  const [selectedCreator, setSelectedCreator] = useState("");
  const [formVisible, setFormVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [limit, setLimit] = useState(10);
  const [showExpired, setShowExpired] = useState(false);
  const [showMoengage, setShowMoengage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showLifetimeCoupons, setShowLifetimeCoupons] = useState(false);
  const debouncedSearch = useDebounce({
    value: searchQuery,
    callback: () => setPage(1),
  });

  function onChange(date) {
    if (date) {
      setStartDate(date[0].startOf("day"));
      setEndDate(date[1].endOf("day"));
    } else {
      setStartDate(moment().add(-7, "days").startOf("day"));
      setEndDate(moment().endOf("day"));
    }
  }

  const errorHandle = (error) => {
    if (error.status === 401) {
      localStorage.clear();
      history.push("/");
      if (error?.message) message.error(error?.message);
      else message.error("Something went wrong");
    } else if (error?.message) message.error(error?.message);
    else message.error("Something went wrong");
  };

  const getCreatorSearch = () => {
    getAllInfluencers(creatorquery, 1)
      .then((response) => {
        if (response.code === 0) {
          if (response.result.length) {
            setUsers(response.result);
          }
        } else if (response.code === 100) {
          message.error("Something went wrong");
        }
      })
      .catch((err) => {
        errorHandle(err);
      });
  };

  const deactivate = (couponId) => {
    Modal.confirm({
      title: "Do You Want to Deactivate This Coupon?",
      icon: <ExclamationCircleOutlined />,
      okType: "danger",
      okText: "Deactivate",
      cancelText: "Cancel",
      width: 600,
      onOk: async () => {
        deactivateCoupon({ couponId })
          .then((response) => {
            if (response.code === 0) {
              message.success("Coupon Deactivated SuccessFully!");
              setToggleCreate(!toggleCreate);
            }
          })
          .catch((err) => {
            errorHandle(err);
          });
      },
      onCancel: () => {},
    });
  };

  useEffect(() => {
    getCreatorSearch();
  }, [creatorquery]);

  useEffect(async () => {
    setLoading(true);
    try {
      const res = await getCouponsV2(
        startDate.toISOString(),
        endDate.toISOString(),
        debouncedSearch,
        selectedCreator,
        showExpired,
        showMoengage,
        page,
        limit
      );
      setCouponList(res?.result?.data);
      setTotal(res?.result?.total);
    } catch (err) {
      errorHandle(err);
    }
    setLoading(false);
  }, [
    startDate,
    endDate,
    debouncedSearch,
    selectedCreator,
    radioValue,
    toggleCreate,
    page,
    limit,
    showExpired,
    showMoengage,
  ]);

  useEffect(() => {
    setStartDate(moment("2020-12-28 14:30:14.755Z").startOf("day"));
  }, [showLifetimeCoupons]);

  let tableData = [];
  tableData = couponList.reduce((acc, eachDetails) => {
    return [
      ...acc,
      {
        key: eachDetails?._id,
        coupontype: `${eachDetails?.type}\n${
          eachDetails?.moengagecoupon ? "(Moengage)" : ""
        }`,
        creatorId: eachDetails?.creator?._id,
        creator: eachDetails?.creator?.name || "-",
        creatorProfilePicUrl: eachDetails?.creator?.profilePicUrl,
        mangoId: eachDetails?.mango?._id,
        mango: eachDetails?.mango?.title || "-",
        couponcode: eachDetails?.code,
        discount:
          (eachDetails?.percentageDiscount &&
            `${eachDetails?.percentageDiscount}%`) ||
          (eachDetails?.flatDiscount && `Rs. ${eachDetails?.flatDiscount}`),
        discountAmount:
          eachDetails?.percentageDiscount || eachDetails?.flatDiscount,
        discountType: eachDetails?.percentageDiscount
          ? "percentageDiscount"
          : "flatDiscount",
        startdate: moment(eachDetails?.startAt).format("Do MMM, YYYY"),
        enddate:
          (eachDetails?.validTill &&
            moment(eachDetails?.validTill).format("Do MMM, YYYY")) ||
          "-",
        startAt: eachDetails?.startAt,
        validTill: eachDetails?.validTill,
        remainingusage: eachDetails?.remainingOrder || "-",
        currentusage: eachDetails?.totalUsage || "-",
        minimum: eachDetails?.minAmount || "N/A",
        maximum: eachDetails?.maxAmount || "N/A",
      },
    ];
  }, []);

  const tableButtonStyle = {
    margin: "4px",
    fontSize: 13,
  };

  const columnData = [
    {
      title: "Creator Name",
      dataIndex: "creator",
      key: "creator",
      width: 224,
      render: (text, record) => (
        <Space size="small">
          <Avatar src={record.creatorProfilePicUrl} size={32} />
          <Typography style={{ fontWeight: 500, fontSize: 14, marginLeft: 6 }}>
            {text}
          </Typography>
        </Space>
      ),
    },
    {
      title: "Mango Name",
      dataIndex: "mango",
      key: "mango",
      render: (text) => (
        <Typography
          style={{ fontWeight: 400, fontSize: 14, fontFamily: "Poppins" }}
        >
          {text}
        </Typography>
      ),
    },
    {
      title: "Coupon Code",
      dataIndex: "couponcode",
      key: "couponcode",
      render: (text) => (
        <Typography
          style={{ fontWeight: 400, fontSize: 14, fontFamily: "Poppins" }}
        >
          {text}
        </Typography>
      ),
    },
    {
      title: "Coupon Type",
      dataIndex: "coupontype",
      key: "coupontype",
      render: (text) => (
        <Typography
          style={{ fontWeight: 400, fontSize: 14, fontFamily: "Poppins" }}
        >
          {text}
        </Typography>
      ),
    },
    {
      title: "Coupon Discount Amount",
      dataIndex: "discount",
      key: "discount",
      render: (text) => (
        <Typography
          style={{ fontWeight: 400, fontSize: 14, fontFamily: "Poppins" }}
        >
          {text}
        </Typography>
      ),
    },
    {
      title: "Coupon Duration",
      dataIndex: "startdate",
      key: "startdate",
      render: (_, record) => (
        <Typography
          style={{ fontWeight: 400, fontSize: 14, fontFamily: "Poppins" }}
        >
          {record?.startdate} - {record?.enddate}
        </Typography>
      ),
    },
    {
      title: "Coupon Usage",
      dataIndex: "remainingusage",
      key: "remainingusage ",
      render: (_, record) => (
        <Typography
          style={{ fontWeight: 400, fontSize: 14, fontFamily: "Poppins" }}
        >
          {record?.remainingusage} / {record?.currentusage}
        </Typography>
      ),
    },
    {
      title: "Amount Range",
      dataIndex: "minimum",
      key: "minimum ",
      render: (_, record) => (
        <Typography
          style={{ fontWeight: 400, fontSize: 14, fontFamily: "Poppins" }}
        >
          {record?.minimum} - {record?.maximum}
        </Typography>
      ),
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              className="siteBtn siteBtnPrimary sm"
              style={tableButtonStyle}
              onClick={() => {
                setSelectedRow(record);
                setFormVisible(true);
              }}
            >
              Duplicate
            </Button>
            <Button
              className="siteBtn siteBtnPrimary sm"
              style={tableButtonStyle}
              onClick={() => {
                setSelectedRow(record);
                setFormVisible(true);
                setEditMode(true);
              }}
            >
              Edit
            </Button>
            <Button
              className="siteBtn siteBtnPrimary sm"
              style={tableButtonStyle}
              danger
              onClick={() => deactivate(record.key)}
            >
              Deactivate
            </Button>
          </div>
        </>
      ),
    },
  ];
  const onSearch = (query) => {
    setPage(1);
    setSearchQuery(query);
  };
  const onRadioChange = (e) => {
    setRadioValue(e.target.value);
    setCreatorQuery("");
    setSelectedCreator("");
  };
  const searchStyle = { width: "120%", marginBottom: 18 };
  const { Option } = AutoComplete;

  return (
    <>
      <Row
        justify="space-between"
        style={{
          marginBottom: "20px",
        }}
      >
        <Col span={4}>
          {radioValue === "coupon" ? (
            <Input
              minLength={2}
              className="searchbar"
              style={searchStyle}
              value={searchQuery}
              placeholder="Search coupon code, type"
              onChange={(event) => onSearch(event.target.value)}
            />
          ) : (
            <AutoComplete
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
              allowClear
              onSelect={(value, record) => {
                setSelectedCreator(record.key);
                setCreatorQuery("");
              }}
              onSearch={(value) => setCreatorQuery(value)}
              placeholder="Search a Creator"
              onChange={(value) => {
                if (!value) setSelectedCreator("");
              }}
              style={searchStyle}
            >
              {users.map((eachDetails) => (
                <Option key={eachDetails?._id} value={eachDetails?.name}>
                  <Space size="small">
                    <Avatar src={eachDetails?.profilePicUrl} size={32} />
                    <Typography
                      style={{ fontWeight: 500, fontSize: 14, marginLeft: 6 }}
                    >
                      {eachDetails?.name}
                    </Typography>
                  </Space>
                </Option>
              ))}
            </AutoComplete>
          )}
        </Col>
        <Col span={4} style={{ marginLeft: 90 }}>
          <Radio.Group onChange={onRadioChange} value={radioValue}>
            <Space direction="horizontal">
              <Radio value="coupon">Search By Coupon</Radio>
              <Radio value="creator">Search By Creator</Radio>
            </Space>
          </Radio.Group>
        </Col>
        <Col span={4}>
          <Select
            style={{ width: "100%" }}
            placeholder="Filters"
            mode="multiple"
            onChange={(value) => {
              setShowExpired(value.includes("expired"));
              setShowMoengage(value.includes("moengage"));
            }}
            defaultValue={[]}
          >
            <Select.Option value="moengage">
              Show Moengage Coupons
            </Select.Option>
            <Select.Option value="expired">Show Expired Coupons</Select.Option>
          </Select>
        </Col>
        <Col offset={3}>
          <Button type="primary" onClick={() => setFormVisible(true)}>
            Create A New Coupon
          </Button>
        </Col>
        <Col span={4}>
          <Space direction="vertical">
            <DatePicker.RangePicker
              style={{ marginLeft: "auto" }}
              onChange={onChange}
              value={[startDate, endDate]}
            />
            <Checkbox
              checked={showLifetimeCoupons}
              onChange={(e) => setShowLifetimeCoupons(e.target.checked)}
            >
              Show Lifetime Coupons
            </Checkbox>
          </Space>
        </Col>
      </Row>
      <CreateCouponForm
        key={formVisible}
        formVisible={formVisible}
        setFormVisible={setFormVisible}
        setSelectedRow={setSelectedRow}
        setToggleCreate={setToggleCreate}
        toggleCreate={toggleCreate}
        selectedRow={selectedRow}
        editMode={editMode}
        setEditMode={setEditMode}
      />
      <Table
        columns={columnData}
        dataSource={tableData}
        pagination={{
          showTotal: (t) => `Total ${t} coupons`,
          current: page,
          total,
          pageSize: limit,
          showSizeChanger: true,
          onShowSizeChange: (current, size) => setLimit(size),
          onChange: (current) => {
            setPage(current);
          },
        }}
        loading={loading}
        bordered
      />
    </>
  );
};

export default CouponSection;
