import {
  Avatar,
  Button,
  Col,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import fileDownload from "js-file-download";
import moment from "moment";
import React, { useEffect, useState } from "react";
import RefernEarnDetailsExpand from "../../Components/RefernEarnDetailsExpand";
import useDebounce from "../../hooks/useDebounce";
import { getRefernEarnRequests, updateRefernEarnRequest } from "../api";

const nameStyle = { fontWeight: 500, fontSize: 14, color: "#1890ff" };

const RefernEarnRequests = () => {
  const [refernEarnRequests, setRefernEarnRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [accessStatus, setAccessStatus] = useState("requested");
  const [approvalState, setApprovalState] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [approvalModalVisible, setApprovalModalVisible] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const debouncedSearch = useDebounce({
    value: search,
    callback: () => setPage(1),
  });
  const handleGetRefernEarnRequests = async () => {
    setLoading(true);
    try {
      const res = await getRefernEarnRequests(
        accessStatus,
        debouncedSearch,
        page,
        limit
      );
      if (res?.code === 0) {
        setRefernEarnRequests(res.result?.data);
        setTotal(res.result?.total);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleGetRefernEarnRequestsCSV = async () => {
    const hide = message.loading("Downloading CSV...", 0);
    try {
      const res = await getRefernEarnRequests(
        accessStatus,
        debouncedSearch,
        page,
        limit,
        true
      );
      fileDownload(res, "refern_earn_requests.csv");
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      hide();
    }
  };

  const handleUpdateRefernEarnRequest = async () => {
    try {
      const resp = await updateRefernEarnRequest({
        user: selectedUser?._id,
        access: approvalState,
        rejectionReason,
      });
      if (resp?.code === 0) {
        setApprovalModalVisible(false);
        setSelectedUser(null);
        setApprovalState(null);
        setRejectionReason("");
        handleGetRefernEarnRequests();
        message.success("Request updated successfully");
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "refernEarnStatusUpdatedAt",
      key: "refernEarnStatusUpdatedAt",
      render: (text) => moment(text).format("DD/MM/YYYY hh:mm A"),
    },
    {
      title: "Creator Details",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <Space size="small" direction="vertical">
          <Avatar src={record?.profilePicUrl} size={32} />
          <Typography style={nameStyle}>{record?.name}</Typography>
          <Typography>{record?.email}</Typography>
          <Typography>{record?.phone}</Typography>
          <Typography.Link>{record?.host}</Typography.Link>
          <Typography>
            ({record?.whitelabelPlanType}, {record?.userTier})
          </Typography>
        </Space>
      ),
    },
    {
      title: "Earnings",
      dataIndex: "totalEarning",
      key: "totalEarning",
    },
    {
      title: "GST Details",
      dataIndex: "gstNumber",
      key: "gstNumber",
      width: "25%",
      render: (_, record) => {
        return (
          <Space direction="vertical">
            <Typography>{record?.gstNumber}</Typography>
            <Typography>
              <strong>GST Registration Name:</strong>{" "}
              {record?.gstRegistrationName}
            </Typography>
            <Typography>
              <strong>GST Registration Address:</strong>{" "}
              {record?.gstRegistrationAddress}
            </Typography>
            <Typography>
              <strong>GST State:</strong> {record?.gstState}
            </Typography>
          </Space>
        );
      },
    },
    {
      title: "PAN",
      dataIndex: "pan",
      key: "pan",
    },
    {
      title: "Remarks",
      dataIndex: "refernEarnRejectionReason",
      key: "refernEarnRejectionReason",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space direction="vertical">
          {accessStatus !== "approved" && (
            <Button
              type="primary"
              block
              onClick={() => {
                setSelectedUser(record);
                setApprovalState("approve");
                setApprovalModalVisible(true);
              }}
            >
              Approve
            </Button>
          )}
          {accessStatus !== "rejected" && (
            <Button
              type="danger"
              block
              onClick={() => {
                setSelectedUser(record);
                setApprovalState("reject");
                setApprovalModalVisible(true);
              }}
            >
              Reject
            </Button>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    handleGetRefernEarnRequests();
  }, [page, limit, debouncedSearch, accessStatus]);

  return (
    <>
      <Row>
        <Col span={12}>
          <Input
            minLength={2}
            value={search}
            className="searchbar"
            style={{ width: "100%" }}
            placeholder="Search a creator"
            onChange={(event) => setSearch(event.target.value)}
          />
        </Col>
        <Col span={12}>
          <Select
            defaultValue="requested"
            onSelect={(value) => setAccessStatus(value)}
            style={{
              marginLeft: "20px",
            }}
          >
            <Select.Option value="requested">Requested</Select.Option>
            <Select.Option value="approved">Approved</Select.Option>
            <Select.Option value="rejected">Rejected</Select.Option>
          </Select>
          <Button
            type="primary"
            onClick={() => {
              handleGetRefernEarnRequestsCSV();
            }}
          >
            Download CSV
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={refernEarnRequests}
        loading={loading}
        style={{
          marginTop: "20px",
        }}
        pagination={{
          current: page,
          total,
          pageSize: limit,
          onChange: (p) => setPage(p),
          showSizeChanger: true,
          onShowSizeChange: (p, l) => {
            setPage(p);
            setLimit(l);
          },
        }}
        rowKey={(record) => record._id}
        expandable={{
          expandedRowRender: (record) => (
            <RefernEarnDetailsExpand userDetails={record} />
          ),
          rowExpandable: (record) =>
            record?.refernEarnRequestStatus === "approved",
        }}
      />
      <Modal
        visible={approvalModalVisible}
        onCancel={() => {
          setApprovalModalVisible(false);
          setSelectedUser(null);
          setApprovalState(null);
        }}
        onOk={handleUpdateRefernEarnRequest}
        title="Approve/Reject Request"
        destroyOnClose
      >
        <Space direction="vertical">
          <Typography>
            Are you sure you want to {approvalState} the request of{" "}
            {selectedUser?.name}?
          </Typography>
          {approvalState === "reject" && (
            <Input.TextArea
              placeholder="Remarks"
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
            />
          )}
        </Space>
      </Modal>
    </>
  );
};

export default RefernEarnRequests;
