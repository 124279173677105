/* eslint-disable no-unused-vars */
import {
  Button,
  Form,
  message,
  Space,
  Typography,
  Avatar,
  AutoComplete,
  Select,
  Input,
  Upload,
} from "antd";
import React, { useState, useEffect } from "react";
import Papa from "papaparse";

import fileDownload from "js-file-download";
import { getAllInfluencers, migrateSubs } from "../api";

const { Option } = AutoComplete;

const mangoPriceStyle = {
  color: "#f18926",
  fontSize: 18,
  marginRight: 6,
  fontFamily: "sans-serif",
};

const mangoTypeStyle = { fontSize: 16, color: "grey" };

const Migration = () => {
  const [creators, setCreators] = useState([]);
  const [mangoes, setMangoes] = useState([]);
  const [creatorQuery, setCreatorQuery] = useState("");
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [mango, setMango] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [users, setUsers] = useState([]);

  const getCreatorSearch = () => {
    getAllInfluencers(creatorQuery, 1)
      .then((response) => {
        if (response.code === 0) {
          if (response.result.length) {
            setCreators(response.result);
          }
        } else if (response.code === 100) {
          message.error("Something went wrong");
        }
      })
      .catch(() => {
        message.error("Something went wrong");
      });
  };

  const handleCsvUpload = (file) => {
    Papa.parse(file, {
      complete: (results) => {
        const { data, meta } = results;
        if (data.length > 100000 || meta.fields.length !== 3) {
          message.error("Invalid CSV file");
          return false;
        }
        setUsers(data);
        message.success("CSV uploaded successfully");
      },
      header: true,
    });
    return false;
  };

  const handleMigrate = async () => {
    const hide = message.loading("Migrating...", 0);
    try {
      const resp = await migrateSubs(selectedCreator, mango, coupon, users);
      if (resp.code === 0) {
        message.success("Migrated successfully");
        const statusData = resp.result;
        const unparsed = Papa.unparse(statusData);
        fileDownload(unparsed, "migrate-status.csv");
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
    hide();
  };

  useEffect(() => {
    getCreatorSearch();
  }, [creatorQuery]);

  useEffect(() => {
    setMangoes(
      creators
        .filter((eachDetails) => eachDetails?._id === selectedCreator)[0]
        ?.mangoes?.filter((eachMango) => eachMango?.isPublic !== true)
    );
  }, [selectedCreator]);

  return (
    <>
      <Form>
        <Form.Item name="creator" label="Select Creator">
          <AutoComplete
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            allowClear
            onSelect={(value, record) => {
              setSelectedCreator(record?.key);
              setMango(null);
            }}
            onSearch={(value) => setCreatorQuery(value)}
            placeholder="Enter Creator"
            style={{
              width: "100%",
            }}
          >
            {creators?.map((eachDetails) => (
              <Option
                key={eachDetails?._id}
                value={`${eachDetails?.name}-${eachDetails?._id}`}
              >
                <Space size="small">
                  <Avatar src={eachDetails?.profilePicUrl} size={32} />
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                      marginLeft: 6,
                    }}
                  >
                    {eachDetails?.name}{" "}
                    {eachDetails?.isWhitelabelled ? "(Whitelabel)" : ""}
                  </Typography>
                </Space>
              </Option>
            ))}
          </AutoComplete>
        </Form.Item>
        <Form.Item name="mango" label="Select Mango">
          <Select
            style={{ width: "100%" }}
            placeholder="Enter Mango"
            allowClear
            size="large"
            notFoundContent={null}
            value={mango}
            defaultValue={mango}
            onChange={(value) => setMango(value)}
            listItemHeight={10}
            listHeight={250}
          >
            {mangoes?.map((item) => (
              <Select.Option value={item._id} key={item._id}>
                <div style={{ padding: "7.5px 0" }}>
                  <Typography.Title
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    {item.title}
                  </Typography.Title>
                  <span className="inputRupee" style={mangoPriceStyle}>
                    &#8377;
                    {item.price}
                  </span>
                  <span style={mangoTypeStyle}>{item.recurringType}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="coupon" label="Select Coupon">
          <Input onChange={(e) => setCoupon(e.target.value)} value={coupon} />
        </Form.Item>
        <Form.Item>
          <Upload accept=".csv" beforeUpload={handleCsvUpload}>
            <Button>Upload CSV</Button>
          </Upload>
        </Form.Item>
      </Form>
      <Button
        disabled={!selectedCreator || !mango || !users?.length}
        onClick={handleMigrate}
      >
        Migrate
      </Button>
    </>
  );
};

export default Migration;
