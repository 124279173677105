import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { AVAILABLE_PAGES } from "../../Utils/constants";
import useDebounce from "../../hooks/useDebounce";
import { getAllAdminUsers, updateAdminUser } from "../api";
import NewUserCreationForm from "./NewUserCreationForm";

const DashboardAccess = () => {
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showSetRestrictionsModal, setShowSetRestrictionsModal] =
    useState(false);
  const [showSetApprovalModal, setShowApprovalModal] = useState(false);
  const [showNewUserCreationModal, setShowNewUserCreationModal] =
    useState(false);
  const [setRestrictionsForm] = Form.useForm();
  const debouncedSearch = useDebounce({
    value: search,
  });

  const getAdminUsersData = async () => {
    setLoading(true);
    try {
      const resp = await getAllAdminUsers(debouncedSearch);
      if (resp.code === 0) {
        setUsers(resp.result || []);
      } else {
        message.error("Something went wrong" || resp?.message);
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
    setLoading(false);
  };

  const handleUpdateUser = async (user, updates) => {
    try {
      const resp = await updateAdminUser(user?._id, updates);
      if (resp.code === 0) {
        await getAdminUsersData();
        message.success("Updated successfully");
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  useEffect(async () => {
    await getAdminUsersData();
  }, [debouncedSearch]);

  const columnData = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Restriction Status",
      dataIndex: "isRestricted",
      key: "isRestricted",
      render: (isRestricted) => (
        <Typography>{isRestricted ? "Yes" : "No"}</Typography>
      ),
    },
    {
      title: "Allowed Functions",
      dataIndex: "allowedFunctions",
      key: "allowedFunctions",
      render: (text, record) => (
        <Typography>{record?.allowedFunctions?.join(", ") || ""}</Typography>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space direction="vertical" size="middle">
          <Button
            danger={record?.approved}
            onClick={() => {
              setSelectedUser(record);
              setShowApprovalModal(true);
            }}
          >
            {record?.approved ? "Remove Approval" : "Approve"}
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setSelectedUser(record);
              setShowSetRestrictionsModal(true);
              setRestrictionsForm.setFieldsValue({
                isRestricted: record?.isRestricted || false,
                allowedFunctions: record?.allowedFunctions || [],
              });
            }}
          >
            Change Restrictions
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row
        style={{
          marginBottom: "20px",
        }}
      >
        <Col span={12}>
          <Input
            value={search}
            placeholder="Seach by name or email"
            minLength={2}
            style={{
              width: "100%",
            }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
          span={12}
        >
          <Button
            type="primary"
            onClick={() => setShowNewUserCreationModal(true)}
          >
            Create new Admin User
          </Button>
        </Col>
      </Row>
      <Table columns={columnData} dataSource={users} loading={loading} />
      <Modal
        title={
          <Typography>
            Are you sure you want to restrict {selectedUser?.name}?
          </Typography>
        }
        footer={[
          <Button type="primary" htmlType="submit" form="set-restrictions-form">
            Submit
          </Button>,
        ]}
        visible={showSetRestrictionsModal}
        onCancel={() => {
          setShowSetRestrictionsModal(false);
          setRestrictionsForm.resetFields();
        }}
        destroyOnClose
      >
        <Form
          form={setRestrictionsForm}
          id="set-restrictions-form"
          onFinish={(values) => {
            handleUpdateUser(selectedUser, {
              isRestricted: values.isRestricted,
              allowedFunctions: values?.isRestricted
                ? values.allowedFunctions
                : [],
            });
            setShowSetRestrictionsModal(false);
            setRestrictionsForm.resetFields();
          }}
        >
          <Form.Item
            name="isRestricted"
            label="Restricted"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev, current) =>
              prev.isRestricted !== current?.isRestricted
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("isRestricted") ? (
                <Form.Item name="allowedFunctions" label="Allowed Functions">
                  <Select mode="multiple" allowClear>
                    {AVAILABLE_PAGES.map((page) => (
                      <Select.Option key={page.key} value={page.key}>
                        {page.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : null
            }
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={showSetApprovalModal}
        onCancel={() => setShowApprovalModal(false)}
        title={
          <Typography>
            Are you sure you want to remove {`${selectedUser?.name}'s`}{" "}
            approval?
          </Typography>
        }
        onOk={() => {
          handleUpdateUser(selectedUser, {
            approved: false,
          });
          setShowApprovalModal(false);
        }}
        destroyOnClose
        closable={false}
      >
        <Typography>This action could be irreversible</Typography>
      </Modal>
      <NewUserCreationForm
        visible={showNewUserCreationModal}
        setVisible={setShowNewUserCreationModal}
      />
    </>
  );
};

export default DashboardAccess;
