import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { message, Table, Space, Avatar, Typography } from "antd";
import { DebounceInput } from "react-debounce-input";
import { getRequestedAccessCreators } from "../api";
import CreatorTableItem from "./CreatorTableItem";

const SOCIALS = ["twitter", "facebook", "instagram", "youtube", "linkedIn"];

const CreatorAccess = () => {
  const [creators, setCreators] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const getRequestedCreators = async () => {
    try {
      setLoading(true);
      const res = await getRequestedAccessCreators(searchFilter);
      if (res?.result && res?.code === 0) {
        setCreators(res.result);
      } else {
        message.error("Could not successfully fetch data");
      }
    } catch (err) {
      console.log(err);
      message.error("Could not successfully fetch data");
    } finally {
      setLoading(false);
    }
  };
  const extractSocials = (creator) => {
    const socials = [];
    SOCIALS.forEach((social) => {
      if (creator[social]) {
        socials.push(
          <a
            style={{
              display: "block",
              textTransform: "capitalize",
            }}
            href={creator[social]}
          >
            {social}
          </a>
        );
      }
    });
    return socials.length > 0 ? socials : <p>No socials provided!</p>;
  };
  const onSearch = (value) => {
    setSearchFilter(value);
  };
  useEffect(() => {
    getRequestedCreators();
  }, [searchFilter]);
  const columns = [
    {
      title: "Creator",
      dataIndex: "creator",
      width: 250,
      render: (text, record) => (
        <Space size="small" style={{ width: "100%" }}>
          <Avatar src={record?.profilePicUrl} size={32} />
          <Typography style={{ fontWeight: 500, fontSize: 15, marginLeft: 6 }}>
            {record?.name}
          </Typography>
        </Space>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text) => <a href={`mailto:${text}`}>{text}</a>,
    },
    {
      title: "Socials",
      dataIndex: "socials",
      render: (_, record) => extractSocials(record),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: 250,
      render: (_, record) => (
        <CreatorTableItem
          creator={record}
          approveFunc={() => {
            setCreators((prev) =>
              prev.map((c) => {
                if (c._id === record._id) {
                  return { ...c, creatorAccessRequested: "approved" };
                }
                return c;
              })
            );
          }}
        />
      ),
    },
  ];
  const dataSource = creators.map((creator) => ({
    key: creator?._id,
    ...creator,
    socials: "",
    actions: "",
  }));
  return (
    <div>
      <DebounceInput
        minLength={2}
        debounceTimeout={300}
        className="searchbar"
        style={{ width: "100%" }}
        placeholder="Search a creator"
        onChange={(event) => onSearch(event.target.value)}
      />
      <div
        style={{
          display: "grid",
        }}
      >
        {!loading && creators ? (
          <Table columns={columns} dataSource={dataSource} />
        ) : (
          <LoadingOutlined
            style={{
              fontSize: "50px",
              alignSelf: "center",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default CreatorAccess;
