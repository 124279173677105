import React, { useEffect, useState } from "react";
import { DatePicker, Table, message, Typography, Pagination } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import { getPayoutHistory } from "../api";

const PayoutHistoryTable = ({ creator, presentlyViewing }) => {
  const [payoutHistory, setPayoutHistory] = useState([]);
  const [payoutHistoryLoading, setPayoutHistoryLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().add(-1, "month").startOf("day")
  );
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);

  const getPayoutHistoryData = async () => {
    setPayoutHistoryLoading(true);
    try {
      const resp = await getPayoutHistory(
        creator,
        startDate,
        endDate,
        page,
        limit
      );
      if (resp?.code === 0) {
        setPayoutHistory(resp?.result.data);
        setTotal(resp?.result.total);
      } else {
        message.error("Something went wrong!");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong!");
    }
    setPayoutHistoryLoading(false);
  };

  const payoutsTable = () => {
    const columnsTable = [
      {
        title: "Payout Id",
        dataIndex: "_id",
        key: "_id",
      },
      {
        title: "Payment Term",
        dataIndex: "paymentTerm",
        key: "paymentTerm",
        render: (_, record) => (
          <Typography>
            <strong>Term Id:</strong> {record?.paymentTerm?._id} <br />
            <strong>Term Duration: </strong>{" "}
            {moment(record?.paymentTerm?.startDate).format("DD/MM/YYYY")} -{" "}
            {moment(record?.paymentTerm?.endDate).format("DD/MM/YYYY")} <br />
            <strong>Minimum Guarantee: </strong>{" "}
            {record?.paymentTerm?.minimumGuarantee} <br />
            <strong>Premium: </strong> {record?.paymentTerm?.premium} <br />
            <strong>Notes: </strong> {record?.paymentTerm?.notes} <br />
          </Typography>
        ),
      },
      {
        title: "Payout Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (_, record) => (
          <Typography>
            {moment(record?.createdAt).format("DD/MM/YYYY hh:mm a")}
          </Typography>
        ),
      },
      {
        title: "Base Amount",
        dataIndex: "baseAmount",
        key: "baseAmount",
      },
      {
        title: "Base Orders",
        dataIndex: "baseOrders",
        key: "baseOrders",
      },
      {
        title: "Ads Deduction",
        dataIndex: "adsDeduction",
        key: "adsDeduction",
      },
      {
        title: "Refunds Deduction",
        dataIndex: "refundsDeduction",
        key: "refundsDeduction",
      },
      {
        title: "Miscellaneous Deduction",
        dataIndex: "miscellaneousDeduction",
        key: "miscellaneousDeduction",
      },
      {
        title: "Final Payout",
        dataIndex: "finalAmount",
        key: "finalAmount",
      },
    ];
    return (
      <>
        <Table
          style={{
            width: "100%",
          }}
          columns={columnsTable}
          dataSource={payoutHistory}
          pagination={false}
          loading={payoutHistoryLoading}
        />
        <Pagination
          current={page}
          total={total}
          onChange={setPage}
          pageSize={limit}
          onShowSizeChange={setLimit}
        />
      </>
    );
  };

  useEffect(async () => {
    console.log(presentlyViewing);
    if (presentlyViewing === creator) {
      await getPayoutHistoryData();
    }
  }, [page, limit, startDate, endDate, presentlyViewing]);

  return (
    <>
      <DatePicker.RangePicker
        onChange={(date) => {
          if (date) {
            setStartDate(date[0].startOf("day"));
            setEndDate(date[1].endOf("day"));
          } else {
            setStartDate(moment().add(-1, "month").startOf("day"));
            setEndDate(moment().endOf("day"));
          }
        }}
        value={[startDate, endDate]}
      />
      {payoutsTable()}
    </>
  );
};

PayoutHistoryTable.propTypes = {
  creator: PropTypes.string.isRequired,
  presentlyViewing: PropTypes.string.isRequired,
};

export default PayoutHistoryTable;
