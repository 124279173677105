import React, { useEffect, useState } from "react";
import { Space, message, Select, Button, DatePicker, Typography } from "antd";
import PropTypes from "prop-types";
import fileDownload from "js-file-download";
import moment from "moment";
import {
  getLedgerOfWallet,
  getOrdersOfWallet,
  getTotalWithdrawnOldToDate,
} from "../api";

const { Option } = Select;

const WalletLedgerTable = ({ creatorId }) => {
  const [startDate, setStartDate] = useState(moment().startOf("month"));
  const [endDate, setEndDate] = useState(moment().endOf("month"));
  const [transactionTypeFilter, setTransactionTypeFilter] = useState("");
  const [previousWithdrawalAmount, setPreviousWithdrawalAmount] = useState(0);

  // const handleGetLedgerOfWallet = async () => {
  //   setLoading(true);
  //   try {
  //     const resp = await getLedgerOfWallet(
  //       creatorId,
  //       startDate?.toISOString(),
  //       endDate?.toISOString(),
  //       transactionTypeFilter,
  //       page,
  //       limit,
  //       false
  //     );
  //     if (resp?.code === 0) {
  //       setOrdersData(resp.result?.data);
  //       setTotal(resp.result?.total);
  //     } else {
  //       message.error("Something went wrong");
  //     }
  //   } catch (err) {
  //     message.error("Something went wrong");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleDownloadLedgerBreakdown = async () => {
    try {
      const resp = await getLedgerOfWallet(
        creatorId,
        startDate?.toISOString(),
        endDate?.toISOString(),
        transactionTypeFilter,
        undefined,
        undefined,
        true
      );
      fileDownload(resp, "ledger-breakdown.csv");
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  const handleDownloadOrdersBreakdown = async () => {
    try {
      const resp = await getOrdersOfWallet(
        creatorId,
        startDate?.toISOString(),
        endDate?.toISOString(),
        undefined,
        undefined,
        true
      );
      fileDownload(resp, "orders-breakdown.csv");
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  const handleGetOldWithdrawnAmount = async () => {
    try {
      const resp = await getTotalWithdrawnOldToDate(creatorId);
      if (resp?.code === 0) {
        setPreviousWithdrawalAmount(resp.result);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  // const getLedgerTable = () => {
  //   const columns = [
  //     {
  //       title: "Ledger ID",
  //       dataIndex: "_id",
  //       key: "_id",
  //     },
  //     {
  //       title: "Transaction Type",
  //       dataIndex: "transactionType",
  //       key: "transactionType",
  //     },
  //     {
  //       title: "Transaction Status",
  //       dataIndex: "transactionStatus",
  //       key: "transactionStatus",
  //     },
  //     {
  //       title: "Amount",
  //       dataIndex: "inrAmount",
  //       key: "inrAmount",
  //       render: (text, record) => (
  //         <Space direction="vertical">
  //           <Typography.Text>INR: {record?.inrAmount}</Typography.Text>
  //           <Typography.Text>EUR: {record?.eurAmount}</Typography.Text>
  //           <Typography.Text>USD: {record?.usdAmount}</Typography.Text>
  //         </Space>
  //       ),
  //     },
  //     {
  //       title: "Balance",
  //       dataIndex: "inrBalance",
  //       key: "inrBalance",
  //       render: (text, record) => (
  //         <Space direction="vertical">
  //           <Typography.Text>INR: {record?.inrBalance}</Typography.Text>
  //           <Typography.Text>EUR: {record?.eurBalance}</Typography.Text>
  //           <Typography.Text>USD: {record?.usdBalance}</Typography.Text>
  //         </Space>
  //       ),
  //     },
  //     {
  //       title: "Order Id",
  //       dataIndex: "orderId",
  //       key: "orderId",
  //     },
  //     {
  //       title: "Withdrawal Id",
  //       dataIndex: "withdrawalId",
  //       key: "withdrawalId",
  //     },
  //     {
  //       title: "Created At",
  //       dataIndex: "createdAt",
  //       key: "createdAt",
  //       render: (text) => moment(text).format("DD/MM/YYYY hh:mm A"),
  //     },
  //   ];

  //   return (
  //     <Table
  //       columns={columns}
  //       dataSource={ordersData}
  //       loading={loading}
  //       pagination={{
  //         current: page,
  //         total,
  //         pageSize: limit,
  //         onChange: (current) => setPage(current),
  //         onShowSizeChange: (current, size) => setLimit(size),
  //       }}
  //     />
  //   );
  // };

  // useEffect(() => {
  //   handleGetLedgerOfWallet();
  // }, [page, limit, transactionTypeFilter, startDate, endDate]);

  useEffect(() => {
    handleGetOldWithdrawnAmount();
  }, []);

  return (
    <>
      <Space size="small" style={{ width: "100%", marginBottom: "10px" }}>
        <DatePicker.RangePicker
          onChange={(dates) => {
            if (dates) {
              setStartDate(dates[0]);
              setEndDate(dates[1]);
            }
          }}
          value={[startDate, endDate]}
        />
        <Select
          placeholder="Transaction Type Filter"
          value={transactionTypeFilter}
          onSelect={(value) => {
            setTransactionTypeFilter(value);
          }}
          style={{
            width: "200px",
          }}
        >
          <Option value="">All</Option>
          <Option value="credit">Credit</Option>
          <Option value="debit">Debit</Option>
        </Select>
        <Button type="primary" onClick={handleDownloadLedgerBreakdown}>
          Download Ledger CSV
        </Button>
        <Button type="primary" onClick={handleDownloadOrdersBreakdown}>
          Download Orders CSV
        </Button>
        <Typography.Text>
          Previous System Withdrawal Amount: {previousWithdrawalAmount}
        </Typography.Text>
      </Space>
      {/* {getLedgerTable()} */}
    </>
  );
};

WalletLedgerTable.propTypes = {
  creatorId: PropTypes.string.isRequired,
};

export default WalletLedgerTable;
