/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { useHistory } from "react-router-dom";
import qs from "qs";
import Sider from "../../ThemeComponents/Sider/Sider";
import Header from "../../ThemeComponents/Header/Header";
import Loader from "../../Utils/Loader";
import Footer from "../../ThemeComponents/Footer/Footer";
import Dashboard from "./Dashboard";
import { getRestrictionStatus } from "../api/index";
import "antd/dist/antd.css";
import "../../App.min.css";

function DashboardIndex({
  isRestricted,
  setIsRestricted,
  allowedFunctions,
  setAllowedFunctions,
  sensitiveAllowedActions,
  setSensitiveAllowedActions,
}) {
  const history = useHistory();
  const [sliderValue, setSliderValue] = useState(
    isRestricted ? allowedFunctions[0] : "home"
  );
  const [loader, setLoader] = useState(false);

  const loaderStyle = {
    position: "fixed",
    top: "45%",
    left: "45%",
    zIndex: 9999,
  };

  useEffect(async () => {
    try {
      if (isRestricted) return false;
      setLoader(true);
      const resp = await getRestrictionStatus();
      if (resp.code === 0) {
        if (resp.result?.isRestricted) {
          setIsRestricted(true);
          setAllowedFunctions(resp.result?.allowedFunctions || []);
        }
        setSensitiveAllowedActions(resp.result?.sensitiveAllowedActions || []);
      } else {
        console.log(resp);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const { userId, orderPage, userDetailsList } = qs.parse(
      history.location.search.slice(1)
    );
    if (userId) {
      setSliderValue("userDetails");
    }
    if (orderPage === "true") {
      setSliderValue("orderDetails");
    }
    if (userDetailsList === "true") {
      setSliderValue("userDetailsList");
    }
  }, [history.location.search]);

  return (
    <>
      {loader ? (
        <Loader style={loaderStyle} />
      ) : (
        <Layout>
          <Sider
            setSliderValue={setSliderValue}
            sliderValue={sliderValue}
            isRestricted={isRestricted}
            allowedFunctions={allowedFunctions}
          />
          <Layout className="siteLayout">
            <Header />
            <Layout.Content className="siteContent">
              <Dashboard
                sliderValue={sliderValue}
                isRestricted={isRestricted}
                allowedFunctions={allowedFunctions}
                sensitiveAllowedActions={sensitiveAllowedActions}
              />
            </Layout.Content>
            <Footer />
          </Layout>
        </Layout>
      )}
    </>
  );
}

export default DashboardIndex;
