import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  DatePicker,
  message,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import fileDownload from "js-file-download";
import { DebounceInput } from "react-debounce-input";
import moment from "moment";
import { getRefernEarnSalesOfProduct } from "../Pages/api";

const nameStyle = { fontWeight: 500, fontSize: 14, color: "#1890ff" };

const SalesTable = ({ product }) => {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);

  const handleFetchSalesOfProduct = async () => {
    try {
      const resp = await getRefernEarnSalesOfProduct(
        product?._id,
        startDate,
        endDate,
        search,
        page,
        limit
      );
      if (resp?.code === 0) {
        setData(resp.result?.data);
        setTotal(resp.result?.total);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  const handleDownloadCSVOfSalesOfProduct = async () => {
    try {
      const resp = await getRefernEarnSalesOfProduct(
        product?._id,
        startDate,
        endDate,
        search,
        page,
        limit,
        true
      );
      fileDownload(resp, "sales.csv");
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  const columns = [
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("DD/MM/YYYY hh:mm A"),
    },
    {
      title: "Reffered User",
      dataIndex: "refferedUserName",
      key: "refferedUserName",
      render: (text, record) => (
        <Space size="small" direction="vertical">
          <Typography style={nameStyle}>{text}</Typography>
          <Typography>{record?.refferedUserEmail}</Typography>
          <Typography>{record?.refferedUserPhone}</Typography>
        </Space>
      ),
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Product Value",
      dataIndex: "productValue",
      key: "productValue",
    },
    {
      title: "Commission",
      dataIndex: "commission",
      key: "commission",
    },
  ];

  useEffect(() => {
    if (product) {
      handleFetchSalesOfProduct();
    }
  }, [product, startDate, endDate, page, limit, search]);

  return (
    <>
      <Row>
        <Col span={12}>
          <DebounceInput
            minLength={2}
            debounceTimeout={300}
            className="searchbar"
            style={{ width: "100%" }}
            placeholder="Search a creator"
            onChange={(event) => setSearch(event.target.value)}
          />
        </Col>
        <Col span={12}>
          <Space
            style={{
              marginLeft: "10px",
            }}
          >
            <DatePicker.RangePicker
              value={[startDate, endDate]}
              onChange={(dates) => {
                setStartDate(dates?.[0]);
                setEndDate(dates?.[1]);
              }}
              allowClear
            />
            <Button type="primary" onClick={handleDownloadCSVOfSalesOfProduct}>
              Download CSV
            </Button>
          </Space>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          current: page,
          total,
          pageSize: limit,
          onChange: (p) => setPage(p),
          showSizeChanger: true,
          onShowSizeChange: (p, l) => {
            setPage(p);
            setLimit(l);
          },
        }}
      />
    </>
  );
};

SalesTable.propTypes = {
  product: PropTypes.shape(),
};

export default SalesTable;
