/* eslint-disable react/prop-types */
/* eslint-disable import/order */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import moment from "moment";
import {
  Table,
  Space,
  Avatar,
  Typography,
  Button,
  Row,
  Col,
  Radio,
  message,
  Card,
  Statistic,
  DatePicker,
  AutoComplete,
  Menu,
  Layout,
} from "antd";
import {
  RiseOutlined,
  FallOutlined,
  HomeOutlined,
  LeftSquareOutlined,
  RightSquareOutlined,
} from "@ant-design/icons";
import Loader from "../../Utils/Loader";

import "antd/dist/antd.css";
import "../../App.min.css";
import { CSVLink } from "react-csv";
import {
  getAllUserList,
  getCreatorMangoList,
  getCreatorOrderList,
} from "../api/index";
import UserDetails from "../UserDetails";

const { Header, Sider, Content } = Layout;

const HistoryTab = ({ date, setDate }) => {
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [userDetails, setUserDetails] = useState([]);
  const [creatorOrderList, setCreatorOrderList] = useState([]);
  const [creatorMangoList, setCreatorMangoList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [userMGDetails, setUserMGDetails] = useState(null);
  const [notFoundContent, setNotFoundContent] = useState(false);
  const [page, setPage] = useState(1);
  const [creatorPage, setCreatorPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [totalPageCreator, setTotalPageCreator] = useState(2);
  const [radioValue, setRadioValue] = React.useState("both");
  const [totalIncoming, setTotalIncoming] = useState(0);
  const [totalOutgoing, setTotalOutgoing] = useState(0);
  const [profit, setProfit] = useState(0);
  const loaderStyle = {
    position: "fixed",
    top: "45%",
    left: "45%",
    zIndex: 9999,
  };

  const customFormat = (value) => `Before: ${value.format("DD-MM-YYYY")}`;

  useEffect(async () => {
    try {
      const searchValue = searchQuery.length > 2 ? searchQuery : "";
      let usersList;
      if (!searchValue) {
        usersList = await getAllUserList(creatorPage);
      } else {
        usersList = await getAllUserList(creatorPage, searchValue);
      }
      if (usersList.code === 0) {
        if (
          searchValue &&
          usersList?.result?.length &&
          usersList?.result?.length === 0
        ) {
          setNotFoundContent(true);
          return false;
        }
        // alert(JSON.stringify(usersList.result));
        setUserDetails(usersList?.result);
        setSelectedRow(usersList?.result[0]?._id);
        setUserMGDetails(
          `${usersList?.result[0]?.name}'s MG Balance ${
            usersList?.result[0]?.mgAmount || 0
          }`
        );
      } else if (usersList.code === 401 || usersList.status === 401) {
        localStorage.clear();
        history.push("/");
        usersList?.message
          ? message.error(usersList?.message)
          : message.error("Something went wrong");
      } else {
        usersList?.message
          ? message.error(usersList?.message)
          : message.error("Something went wrong");
      }
    } catch (error) {
      error?.message
        ? message.error(error?.message)
        : message.error("Something went wrong");
    }
  }, [searchQuery, creatorPage]);

  useEffect(async () => {
    if (
      selectedRow !== undefined &&
      selectedRow !== -1 &&
      userDetails?.length > 0
    ) {
      setLoader(true);
      let orderList = [];
      let mangoList = [];
      setCreatorOrderList([]);
      setCreatorMangoList([]);
      orderList = await getCreatorOrderList(
        selectedRow,
        page,
        date,
        radioValue
      );
      mangoList = await getCreatorMangoList(selectedRow, date);

      const totalValue = orderList?.result?.totalValue;
      const { totalGst } = totalValue;
      const { totalEarning } = totalValue;
      const { totalWhatsapp } = totalValue;
      const { totalTransferAmount } = totalValue;
      const { totalNonTransfer } = totalValue;

      setTotalIncoming(totalGst + totalEarning + totalWhatsapp);
      setTotalOutgoing(totalNonTransfer * 0.9);
      setProfit(
        totalGst +
          totalEarning +
          totalWhatsapp -
          totalTransferAmount -
          totalNonTransfer * 0.9
      );
      setLoader(false);
      if (orderList.code === 0) {
        setCreatorOrderList(orderList.result.fetchedOrder);
        setTotalPage(Math.ceil(orderList?.result.orderCount / 100));
      }

      if (mangoList.code === 0) {
        setCreatorMangoList(mangoList.result?.fetchedMangoList);
      }
    } else {
      setTotalIncoming(0);
      setTotalOutgoing(0);
      setProfit(0);
      setCreatorOrderList([]);
      setCreatorMangoList([]);
    }
    return () => {
      setUserDetails([]);
    };
  }, [selectedRow, page, radioValue, date, searchQuery]);

  /* userDetails.map(data => {
    data.orderNum = Number(data?.earningsList?.length)
  }) */

  const onSearch = (query) => {
    setNotFoundContent(false);
    setCreatorPage(1);
    setTotalPageCreator(2);
    setPage(1);
    setUserDetails([]);
    setSearchQuery(query);
  };

  const handleCLick = (action) => {
    if (action === "home") {
      setPage(1);
    }

    if (action === "next") {
      setPage(page + 1);
    }

    if (action === "previous") {
      setPage(page - 1);
    }
  };
  const handleCLickCreator = (action) => {
    if (action === "home") {
      setCreatorPage(1);
      setPage(1);
    }

    if (action === "next") {
      setCreatorPage(creatorPage + 1);
      setPage(1);
    }

    if (action === "previous") {
      setCreatorPage(creatorPage - 1);
      setPage(1);
    }
  };

  const pageDisplay = () => {
    return (
      <p style={{ display: "contents" }}>
        Page {page} of {totalPage}
      </p>
    );
  };
  const pageDisplayCreator = () => {
    return (
      <p style={{ display: "contents" }}>
        Page {creatorPage} of {totalPageCreator}
      </p>
    );
  };
  const displayUserDetails = () => {
    const handleOnChnage = (pagination) => {
      console.log(pagination);
    };

    let tableData = [];
    // console.log("creatoOrderList = ", creatorOrderList);
    // console.log("tableData before = ", tableData);
    tableData = creatorOrderList.reduce((acc, eachDetails) => {
      return [
        ...acc,
        {
          key: selectedRow,
          from: eachDetails.fanName,
          fanProfilePicUrl: eachDetails.fanProfilePicUrl,
          amount: eachDetails?.amount || eachDetails?.manualPayAmount,
          payslip:
            eachDetails.status === "manualpay" ? eachDetails?.payslip : null,
          date: eachDetails.createdAt,
          admin: eachDetails?.adminUserName,
          gst: eachDetails.gst,
          whatsappCharge: eachDetails.whatsappCharge,
          orderId: eachDetails.razorpayOrderId,
          status: eachDetails.status,
          transferAmount: eachDetails?.transferAmount,
          manualPayAmount: eachDetails?.manualPayAmount,
          manualTransferId: eachDetails?.manualTransferId,
        },
      ];
    }, []);

    const returnAvatar = (record) => {
      if (record.fanProfilePicUrl) {
        return <Avatar src={record.fanProfilePicUrl} size={32} />;
      }
      return null;
    };
    const getTotalAmount = (record) => {
      if (record?.status !== "manualpay") {
        return record?.transferAmount || 0.0;
      }
      return (
        <a href={record?.payslip} target="_blank">
          {record?.manualPayAmount}
        </a>
      );
    };
    const dateFormat = { year: "numeric", month: "long", day: "numeric" };
    const columnData = [
      {
        title: "From",
        dataIndex: "from",
        key: "from",
        render: (text, record) => (
          <Space size="small" style={{ width: "100%" }}>
            {returnAvatar(record)}
            <Typography
              style={{ fontWeight: 500, fontSize: 14, marginLeft: 6 }}
            >
              {record.status !== "manualpay" ? text : "Tagmango"}
            </Typography>
          </Space>
        ),
      },
      {
        title: "Admin",
        dataIndex: "admin",
        key: "admin",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (text, record) => (
          <Typography style={{ fontWeight: 500, fontSize: 14 }}>
            {(record?.amount || 0).toFixed(2)}
          </Typography>
        ),
      },
      {
        title: "GST",
        dataIndex: "gst",
        key: "gst",
        render: (text, record) => (
          <Typography style={{ fontWeight: 500, fontSize: 14 }}>
            {(record?.gst || 0).toFixed(2)}
          </Typography>
        ),
      },
      {
        title: "Whatsapp",
        dataIndex: "whatsappCharge",
        key: "whatsappCharge",
        render: (text, record) => (
          <Typography style={{ fontWeight: 500, fontSize: 14 }}>
            {(record?.whatsappCharge || 0).toFixed(2)}
          </Typography>
        ),
      },
      {
        title: "Order Id/Transfer Id",
        dataIndex: "orderId",
        key: "orderId",
        render: (text, record) => (
          <Typography style={{ fontWeight: 500, fontSize: 14 }}>
            {record?.orderId || record?.manualTransferId}
          </Typography>
        ),
      },
      {
        title: "Transfer Amount",
        dataIndex: "finalAmount",
        key: "finalAmount",
        render: (text, record) => (
          <>
            <Typography
              style={{ fontWeight: 500, fontSize: 14, textAlign: "right" }}
            >
              {record?.status === "manualpay" ? (
                <RiseOutlined style={{ color: "red", marginRight: 10 }} />
              ) : (
                <FallOutlined style={{ color: "green", marginRight: 10 }} />
              )}
              {getTotalAmount(record)}
            </Typography>
            <Typography
              style={{ fontWeight: 500, fontSize: 14, textAlign: "right" }}
            >
              {!record?.date ||
                new Date(record?.date).toLocaleDateString("en-US", dateFormat)}
            </Typography>
          </>
        ),
      },
    ];

    const colorChange = (record) => {
      if (record.from === "tagmango") return "red";
      return record.status === "manualpay" ? "red" : "green";
    };
    return (
      <>
        {tableData?.length ? (
          <Table
            className="components-table-demo-nested"
            columns={columnData}
            dataSource={tableData}
            pagination={false}
            rowClassName={colorChange}
            scroll={{ y: 639 }}
            bordered
            onChange={handleOnChnage}
          />
        ) : (
          <p>No Data Found</p>
        )}
      </>
    );
  };

  const headers = [
    { label: "Creator Name", key: "_id.creatorName" },
    { label: "Number of pending transfers", key: "orderNum" },
    { label: "Total pending amount", key: "totalUntransferred" },
  ];
  const creatorcolumnData = [
    {
      title: "",
      dataIndex: "creatorName",
      key: "creatorName",
      render: (text, record) => (
        <Space size="small" style={{ width: "100%" }}>
          <Avatar src={record.creatorProfilePicUrl} size={32} />
          <Typography style={{ fontWeight: 500, fontSize: 14 }}>
            {text}
          </Typography>
        </Space>
      ),
    },
  ];

  const creatortableData = userDetails.reduce((acc, eachDetails) => {
    return [
      ...acc,
      {
        key: eachDetails?._id,
        creatorName: eachDetails.name,
        creatorProfilePicUrl: eachDetails.profilePicUrl,
        mgAmount: eachDetails?.mgAmount,
      },
    ];
  }, []);
  const handleOnChnage = (pagination) => {
    console.log(pagination);
  };
  const clickEvent = (e) => {
    return {
      onClick: () => {
        if (e !== undefined) {
          setSelectedRow(e.key);
          setUserMGDetails(
            `${e?.creatorName}'s MG Balance ${e?.mgAmount || 0}`
          );
        }
      },
    };
  };
  const setRowClassName = (record) => {
    return selectedRow === record.key ? "clickRowStyl" : "";
  };
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setRadioValue(e.target.value);
  };

  const mangocolumnData = [
    {
      title: "Mango Name",
      dataIndex: "mangoName",
      key: "mangoName",
    },
    {
      title: "Total Earnings",
      dataIndex: "totalEarnings",
      key: "totalEarnings",
    },
    {
      title: "Total GST",
      dataIndex: "totalgst",
      key: "totalgst",
      render: (text, record) => (
        <Typography style={{ fontWeight: 500, fontSize: 14 }}>
          {(record?.totalgst || 0).toFixed(2)}
        </Typography>
      ),
    },
    {
      title: "Total Whatsapp",
      dataIndex: "totalwhatsapp",
      key: "totalwhatsapp",
    },
    {
      title: "Total Transferred",
      dataIndex: "totaltransferred",
      key: "totaltransferred",
      render: (text, record) => (
        <Typography style={{ fontWeight: 500, fontSize: 14 }}>
          {(record?.totaltransferred || 0).toFixed(2)}
        </Typography>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "No of Orders",
      dataIndex: "numberorders",
      key: "numberorders",
    },
  ];
  const mangotableData = creatorMangoList.reduce((acc, eachDetails) => {
    return [
      ...acc,
      {
        key: eachDetails?._id.mangoId,
        mangoName: eachDetails?._id.mangoTitle,
        amount: eachDetails?._id?.amount,
        totalEarnings: eachDetails.totalEarning,
        totalgst: eachDetails.totalGST,
        totalwhatsapp: eachDetails.totalWhatsappCharge,
        totaltransferred: eachDetails.totalTransferredAmount,
        numberorders: eachDetails.totalOrder,
      },
    ];
  }, []);

  return (
    <>
      {/* <CSVLink
        filename={`non_transferred_users_${new Date()}.csv`}
        data={userDetails}
        headers={headers}>
        <Button>
          Export CSV
       </Button>
      </CSVLink> */}
      <Row gutter={[8, 8]} type="flex">
        <Col span={5}>
          <DebounceInput
            minLength={2}
            debounceTimeout={300}
            className="searchbar"
            style={{ width: "100%" }}
            placeholder="Search a creator"
            onChange={(event) => onSearch(event.target.value)}
          />
          {userDetails.length > 0 ? (
            <Table
              className="components-table-demo-nested"
              columns={creatorcolumnData}
              dataSource={creatortableData}
              pagination={false}
              scroll={{ y: 750 }}
              onRow={clickEvent}
              rowClassName={setRowClassName}
              bordered
              onChange={handleOnChnage}
            />
          ) : null}
          {pageDisplayCreator()}
          <div style={{ float: "right", marginTop: 6 }}>
            <Button
              type="primary"
              disabled={creatorPage === 1}
              icon={<LeftSquareOutlined />}
              onClick={() => handleCLickCreator("previous")}
            />
            <Button
              type="primary"
              disabled={creatorPage === 1}
              icon={<HomeOutlined />}
              onClick={() => handleCLickCreator("home")}
            />
            <Button
              type="primary"
              disabled={creatorPage === totalPageCreator}
              icon={<RightSquareOutlined />}
              onClick={() => handleCLickCreator("next")}
            />
          </div>
        </Col>
        {loader ? (
          <Col span={19}>
            <Loader style={loaderStyle} />
          </Col>
        ) : (
          <Col span={19}>
            <Row gutter={[8, 8]} type="flex" align="middle">
              <Col span={4}>
                <Card
                  className="widgetCard active"
                  bodyStyle={{ position: "relative", textAlign: "center" }}
                >
                  <Statistic
                    title="Incomming"
                    value={totalIncoming}
                    precision={0}
                    valueStyle={{ color: "#3f8600" }}
                  />
                </Card>
              </Col>
              <Col span={4}>
                <Card
                  className="widgetCard active"
                  bodyStyle={{ position: "relative", textAlign: "center" }}
                >
                  <Statistic
                    title="Outgoing"
                    value={totalOutgoing}
                    precision={0}
                    valueStyle={{ color: "#3f8600" }}
                  />
                  <snap
                    style={{
                      position: "absolute",
                      right: 5,
                      bottom: 5,
                      textAlign: "right",
                      fontSize: 11,
                      color: "#bdbdbd",
                      letterSpacing: "0.025em",
                    }}
                  >
                    <span style={{ color: "#cf1322" }}>*</span>WhatsApp Charge
                    not included
                  </snap>
                </Card>
              </Col>
              <Col span={4}>
                <Card
                  className="widgetCard active"
                  bodyStyle={{ position: "relative", textAlign: "center" }}
                >
                  <Statistic
                    title="Profit"
                    value={profit}
                    precision={0}
                    valueStyle={{ color: "#3f8600" }}
                  />
                </Card>
              </Col>
              <Col span={3} offset={1}>
                <Radio.Group onChange={onChange} value={radioValue}>
                  <Radio
                    style={{ fontWeight: 500, fontSize: 16 }}
                    value="incoming"
                  >
                    Incoming
                  </Radio>
                  <Radio
                    style={{ fontWeight: 500, fontSize: 16 }}
                    value="outgoing"
                  >
                    Outgoing
                  </Radio>
                  <Radio style={{ fontWeight: 500, fontSize: 16 }} value="both">
                    {" "}
                    All{" "}
                  </Radio>
                </Radio.Group>
              </Col>
              <Col span={5} offset={2} align="bottom">
                <DatePicker
                  value={date}
                  format={customFormat}
                  disabledDate={(current) => current.isAfter(moment())}
                  onChange={(d) => setDate(d.startOf("day"))}
                />
                <Typography style={{ fontWeight: 500, fontSize: 14 }}>
                  {userMGDetails}
                </Typography>
              </Col>
            </Row>
            {creatorOrderList?.length > 0 ? displayUserDetails() : null}
            {pageDisplay()}
            <div style={{ float: "right", marginTop: 6 }}>
              <Button
                type="primary"
                disabled={page === 1}
                icon={<LeftSquareOutlined />}
                onClick={() => handleCLick("previous")}
              />
              <Button
                type="primary"
                disabled={page === 1}
                icon={<HomeOutlined />}
                onClick={() => handleCLick("home")}
              />
              <Button
                type="primary"
                disabled={page === totalPage}
                icon={<RightSquareOutlined />}
                onClick={() => handleCLick("next")}
              />
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <Table
          className="components-table-demo-nested"
          columns={mangocolumnData}
          dataSource={mangotableData}
          pagination={false}
          scroll={{ y: 250 }}
          bordered
        />
      </Row>
    </>
  );
};

export default HistoryTab;
