/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Table,
  Space,
  Typography,
  Avatar,
  message,
  Select,
  Button,
  Tabs,
} from "antd";
import { DebounceInput } from "react-debounce-input";
import PropTypes from "prop-types";
import fileDownload from "js-file-download";
import moment from "moment";
import { getOrdersOfWithdrawal, downloadWithdrawalBreakdown } from "../api";

const { Option } = Select;
const { TabPane } = Tabs;

const WithdrawalOrdersTable = ({
  withdrawalId,
  presentlyViewing,
  creatorId,
}) => {
  const [ordersData, setOrdersData] = useState([]);
  const [affiliateData, setAffiliateData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const [tab, setTab] = useState("1");

  const getOrdersOfWithdrawalData = async () => {
    setLoading(true);
    try {
      const resp = await getOrdersOfWithdrawal(
        withdrawalId,
        search,
        statusFilter,
        page,
        limit
      );
      if (resp?.code === 0) {
        setOrdersData(resp.result?.data);
        setAffiliateData(resp.result?.affiliateData);
        setTotal(resp.result?.total);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      message.error("Something went wrong");
    }
    setLoading(false);
  };

  const handleWithdrawalBreakdownDownload = async () => {
    try {
      const resp = await downloadWithdrawalBreakdown(withdrawalId, creatorId);
      fileDownload(resp, `withdrawal_breakdown_${moment().toISOString()}.csv`);
      message.success("Download started");
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (presentlyViewing === withdrawalId) {
      getOrdersOfWithdrawalData();
    }
  }, [page, limit, search, statusFilter]);

  const blueTextStyling = { color: "#1890ff" };
  const ordersCoulmnData = [
    {
      title: "Order ID",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Fan Details",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Space size="small" direction="vertical">
          <Avatar src={record?.profilePicUrl} size={32} />
          <Typography
            style={{ fontWeight: 500, fontSize: 14, color: "#1890ff" }}
          >
            {record?.name}
          </Typography>
          <Typography
            style={{
              fontWeight: 300,
              fontSize: 14,
            }}
          >
            {record?.email}
          </Typography>
          <Typography
            style={{
              fontWeight: 300,
              fontSize: 14,
            }}
          >
            {record?.phone}
          </Typography>
        </Space>
      ),
    },
    {
      title: "Amount Details",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <Space size="small" direction="vertical">
          {record?.status === "completed" &&
          record?.refundState !== "ongoing" ? (
            <>
              <Typography>
                <strong>Order currency: </strong>{" "}
                {record?.subscriberCurrency || "INR"}
              </Typography>
              <Typography>
                <strong>Mango Price: </strong> {record?.mangoPrice.toFixed(2)}
              </Typography>
              <Typography>
                <strong>Amount: </strong>
                {record?.amount?.toFixed(2)}
              </Typography>
              <Typography>
                <strong>GST: </strong>
                {record?.gst?.toFixed(2) || "N/A"}
              </Typography>
              {record?.coupon ? (
                <Typography>
                  <strong>Discount: </strong>
                  {record?.discount?.toFixed(2) || "N/A"}{" "}
                  <strong>Coupon: </strong> {record?.coupon || "N/A"}
                  <span style={blueTextStyling}>
                    {record?.moengageCouponUsed ? " (Moengage)" : ""}
                  </span>
                </Typography>
              ) : null}
              <Typography>
                <strong>Gross Amount: </strong>
                {record?.grossAmount?.toFixed(2)}
              </Typography>
              <Typography>
                <strong>Shortfall: </strong>
                {record?.shortfall || 0}
              </Typography>
              <Typography>
                <strong>Payable Amount: </strong>
                {record?.netPayable}
              </Typography>
              <Typography>
                <strong>Comission Amount: </strong>
                {
                  record[(record?.subscriberCurrency || "INR")?.toLowerCase()]
                    ?.commissionCharged
                }
              </Typography>
              <Typography>
                <strong>Affiliate Comission: </strong>
                {
                  record[(record?.subscriberCurrency || "INR")?.toLowerCase()]
                    ?.affiliateComission
                }
              </Typography>
            </>
          ) : null}
          {record?.isTesting ? (
            <Typography style={blueTextStyling}>(Testing)</Typography>
          ) : null}
          {record?.status === "refunded" ||
          record?.refundState === "ongoing" ? (
            <>
              <Typography>
                <strong>Refund Amount: </strong>
                {record?.refundableAmount?.toFixed(2)}
              </Typography>
              <Typography style={blueTextStyling}>(Refunded)</Typography>
            </>
          ) : null}
        </Space>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <Typography>
          {moment(record?.createdAt).format("DD MMM YYYY, hh:mm a")}
        </Typography>
      ),
    },
  ];

  const ordersTable = () => {
    return (
      <Table
        columns={ordersCoulmnData}
        dataSource={ordersData}
        loading={loading}
        pagination={{
          current: page,
          total,
          pageSize: limit,
          onChange: (current) => setPage(current),
          onShowSizeChange: (current, size) => setLimit(size),
        }}
      />
    );
  };

  const affiliateTable = () => {
    const columnData = [
      {
        title: "Affiliate ID",
        dataIndex: "_id",
        key: "_id",
      },
      {
        title: "Affiliate Details",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <Space size="small" direction="vertical">
            <Avatar src={record?.profilePicUrl} size={32} />
            <Typography
              style={{ fontWeight: 500, fontSize: 14, color: "#1890ff" }}
            >
              {record?.name}
            </Typography>
            <Typography
              style={{
                fontWeight: 300,
                fontSize: 14,
              }}
            >
              {record?.email}
            </Typography>
            <Typography
              style={{
                fontWeight: 300,
                fontSize: 14,
              }}
            >
              {record?.phone}
            </Typography>
          </Space>
        ),
      },
      {
        title: "Total Comission",
        dataIndex: "totalComission",
        key: "totalComission",
      },
    ];
    const expandedRowRender = (record) => {
      return <Table columns={ordersCoulmnData} dataSource={record?.orders} />;
    };
    return (
      <Table
        columns={columnData}
        dataSource={affiliateData}
        rowKey={(record) => record._id}
        expandable={{
          expandedRowRender,
          rowExpandable: (record) => record?.orders?.length > 0,
        }}
      />
    );
  };

  const orderTableComponent = (
    <>
      <Space size="small" style={{ width: "100%", marginBottom: "10px" }}>
        <DebounceInput
          style={{ width: "25rem" }}
          placeholder="Search orders"
          value="Search"
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          placeholder="Status filters"
          onSelect={(value) => {
            setPage(1);
            setStatusFilter(value);
          }}
        >
          <Option value="">All</Option>
          <Option value="completed">Completed</Option>
          <Option value="refunded">Refunded</Option>
          <Option value="testing">Testing</Option>
          <Option value="moengage">Moengage Coupon</Option>
        </Select>
        <Button type="primary" onClick={handleWithdrawalBreakdownDownload}>
          Download Breakdown CSV
        </Button>
      </Space>
      {ordersTable()}
    </>
  );

  const affiliateTableComponent = <>{affiliateTable()}</>;

  const tabs = [
    {
      key: "orders",
      label: "Orders",
      children: orderTableComponent,
    },
    {
      key: "affiliates",
      label: "Affiliates",
      children: affiliateTableComponent,
    },
  ];

  return (
    <Tabs defaultActiveKey="1" activeKey={tab} onChange={(key) => setTab(key)}>
      {tabs.map((t) => (
        <TabPane tab={t.label} key={t.key}>
          {t.children}
        </TabPane>
      ))}
    </Tabs>
  );
};

WithdrawalOrdersTable.propTypes = {
  withdrawalId: PropTypes.string.isRequired,
  presentlyViewing: PropTypes.string.isRequired,
  creatorId: PropTypes.string.isRequired,
};

export default WithdrawalOrdersTable;
