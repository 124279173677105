import React, { useEffect, useState } from "react";
import moment from "moment";
import { message, Form, Button } from "antd";
import PropTypes from "prop-types";
import {
  getActivePaymentTerm,
  getCreatorDetailsWithHiddenMangoes,
  createPaymentTerm,
  editPaymentTerm,
} from "../api";
import PaymentTermsModal from "./PaymentTermsModal";

const PaymentTerms = ({ creator }) => {
  const [mangos, setMangos] = useState([]);
  const [activeTermDataLoading, setActiveTermDataLoading] = useState(false);
  const [activeTermResponse, setActiveTermResponse] = useState(null);
  const [paymentTermsModalVisible, setPaymentTermsModalVisible] =
    useState(false);
  const [formSubmissionLoading, setFormSubmissionLoading] = useState(false);
  const [activeTermForm] = Form.useForm();

  const getMangoData = async () => {
    try {
      const resp = await getCreatorDetailsWithHiddenMangoes(creator);
      if (resp?.code === 0) {
        setMangos(
          resp?.result?.activeMangoes?.filter(
            (mango) => mango.isPublic === false
          )
        );
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  const getActiveTermData = async () => {
    try {
      const resp = await getActivePaymentTerm(creator);
      if (resp?.code === 0 && resp?.result) {
        const termData = {
          ...resp?.result,
          ...(resp?.result?.startDate
            ? {
                startDate: moment(
                  new Date(resp?.result?.startDate),
                  "DD/MM/YYYY"
                ),
              }
            : { startDate: moment(new Date(), "DD/MM/YYYY") }),
          ...(resp?.result?.expiryDate && {
            expiryDate: moment(
              new Date(resp?.result?.expiryDate),
              "DD/MM/YYYY"
            ),
          }),
          ...(resp?.result?.workshopEndDate && {
            workshopEndDate: moment(
              new Date(resp?.result?.workshopEndDate),
              "DD/MM/YYYY"
            ),
          }),
          ...(resp?.result?.workshopPayoutDate && {
            workshopPayoutDate: moment(
              new Date(resp?.result?.workshopPayoutDate),
              "DD/MM/YYYY"
            ),
          }),
        };
        activeTermForm.setFieldsValue(termData);
        setActiveTermResponse(termData);
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  const handlePaymentTermsFormSubmission = async (values) => {
    setFormSubmissionLoading(true);
    try {
      let resp;
      const reqBody = {
        creator,
        ...values,
        ...(values?.expiryDate && {
          expiryDate: new Date(values?.expiryDate).toISOString(),
        }),
        ...(values?.workshopEndDate && {
          workshopEndDate: new Date(values?.workshopEndDate).toISOString(),
        }),
        ...(values?.workshopPayoutDate && {
          workshopPayoutDate: new Date(
            values?.workshopPayoutDate
          ).toISOString(),
        }),
        startDate: new Date(values?.startDate).toISOString(),
      };
      const initialObject = activeTermResponse
        ? {
            creator,
            ...activeTermResponse,
            ...(activeTermResponse?.expiryDate && {
              expiryDate: new Date(
                activeTermResponse?.expiryDate
              ).toISOString(),
            }),
            ...(activeTermResponse?.workshopEndDate && {
              workshopEndDate: new Date(
                activeTermResponse?.workshopEndDate
              ).toISOString(),
            }),
            ...(activeTermResponse?.workshopPayoutDate && {
              workshopPayoutDate: new Date(
                activeTermResponse?.workshopPayoutDate
              ).toISOString(),
            }),
            startDate: new Date(activeTermResponse?.startDate).toISOString(),
          }
        : {};
      const difference = Object.keys(initialObject).reduce((prev, current) => {
        return {
          ...prev,
          ...(reqBody[current] &&
            initialObject[current] &&
            reqBody[current]?.toString() !==
              initialObject[current]?.toString() && {
              [current]: true,
            }),
        };
      }, {});
      const editableFields = {
        workshop: {
          workshopEndDate: true,
          workshopPayoutDate: true,
          notes: true,
          expiryDate: true,
        },
        continuous: {
          expiryDate: true,
          notes: true,
        },
      };
      const editable =
        activeTermResponse &&
        Object.keys(difference).reduce(
          (prev, current) =>
            prev &&
            difference[current] &&
            (editableFields[initialObject.termsType][current] || false),
          true
        );
      if (!editable) {
        resp = await createPaymentTerm(reqBody);
      } else {
        resp = await editPaymentTerm(initialObject?._id, reqBody);
      }
      if (resp?.code === 0) {
        message.success(
          `Payment terms ${editable ? "updated" : "created"} successfully`
        );
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
    setPaymentTermsModalVisible(false);
    setFormSubmissionLoading(false);
  };

  useEffect(async () => {
    if (paymentTermsModalVisible === true) {
      setActiveTermDataLoading(true);
      await getMangoData();
      await getActiveTermData();
      setActiveTermDataLoading(false);
    }
  }, [paymentTermsModalVisible]);

  return (
    <>
      <Button
        type="primary"
        style={{
          marginRight: "8px",
        }}
        onClick={() => setPaymentTermsModalVisible(true)}
      >
        Add/Edit Payment Terms
      </Button>
      <PaymentTermsModal
        modalVisible={paymentTermsModalVisible}
        setModalVisible={setPaymentTermsModalVisible}
        activeTermForm={activeTermForm}
        handleFormSubmit={handlePaymentTermsFormSubmission}
        mangos={mangos}
        loadingState={activeTermDataLoading}
        formSubmissionLoadingState={formSubmissionLoading}
      />
    </>
  );
};

PaymentTerms.propTypes = {
  creator: PropTypes.string.isRequired,
};

export default PaymentTerms;
