import React, { useEffect, useState } from "react";
import {
  Typography,
  Checkbox,
  Form,
  Button,
  Modal,
  InputNumber,
  Upload,
  Input,
  DatePicker,
} from "antd";
import PropTypes from "prop-types";
import { UploadOutlined } from "@ant-design/icons";
import { getUpcomingPayouts } from "../api";

const SettlePayoutModal = ({
  baseAmount,
  baseOrders,
  premium,
  minimumGuarantee,
  modalVisible,
  setModalVisible,
  settlePaymentFunc,
  formSubmissionState,
  paymentTermId,
  endDate,
}) => {
  const [deductionFields, setDeductionFields] = useState({
    adsDeduction: false,
    refundsDeduction: false,
    miscellaneousDeduction: false,
  });
  const [loading, setLoading] = useState(false);
  const [baseAmountState, setBaseAmountState] = useState(baseAmount);
  const [baseOrdersState, setBaseOrdersState] = useState(baseOrders);
  const [customLastDate, setCustomLastDate] = useState(null);
  const [form] = Form.useForm();

  const refundsFormInputElement = (formField) => (
    <div
      style={{
        display: "flex",
      }}
    >
      <InputNumber min={0} />
      <Checkbox
        style={{
          marginLeft: "8px",
        }}
        onChange={(e) =>
          setDeductionFields({
            ...deductionFields,
            [formField]: e.target.checked,
          })
        }
      >
        After Premium
      </Checkbox>
    </div>
  );

  const calculateFinalAmount = () => {
    let amount = baseAmountState;
    Object.keys(deductionFields).forEach((key) => {
      if (!deductionFields[key]) {
        amount -= form.getFieldValue(key) || 0;
      }
    });
    amount *= 1 + premium / 100;
    Object.keys(deductionFields).forEach((key) => {
      if (deductionFields[key]) {
        amount -= form.getFieldValue(key) || 0;
      }
    });
    form.setFieldsValue({
      finalAmount: amount,
    });
  };

  const getCustomPayoutData = async () => {
    setLoading(true);
    try {
      const resp = await getUpcomingPayouts(
        endDate,
        1,
        10,
        "",
        paymentTermId,
        customLastDate
      );
      if (resp?.code === 0) {
        const payout = resp?.result?.data[0];
        console.log(payout?.baseAmount);
        if (payout) {
          setBaseAmountState(payout?.baseAmount);
          setBaseOrdersState(payout?.baseOrders);
        }
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    calculateFinalAmount();
  }, [deductionFields, baseAmountState]);

  useEffect(() => {
    form.setFieldsValue({
      adsDeduction: 0,
      refundsDeduction: 0,
      miscellaneousDeduction: 0,
    });
  }, []);

  useEffect(async () => {
    if (customLastDate) {
      await getCustomPayoutData();
    } else {
      setBaseAmountState(baseAmount);
      setBaseOrdersState(baseOrders);
    }
  }, [customLastDate]);

  return (
    <>
      <Modal
        title="Settle Payout"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            type="primary"
            htmlType="submit"
            form="settlement-form"
            loading={formSubmissionState || loading}
          >
            Settle
          </Button>,
        ]}
        destroyOnClose
      >
        <Typography.Title level={4}>Base Payout Details:</Typography.Title>
        <Typography.Text>
          Base Amount: ₹<strong>{baseAmountState}</strong> <br />
          Minimum Guarantee: ₹<strong>{minimumGuarantee}</strong> <br />
          Premium: <strong>{premium} %</strong>
        </Typography.Text>
        <Typography.Title level={4}>Deductions:</Typography.Title>
        <Form
          form={form}
          id="settlement-form"
          onChange={calculateFinalAmount}
          onFinish={(values) =>
            settlePaymentFunc({
              ...values,
              ...(customLastDate && {
                baseAmount: baseAmountState,
                baseOrders: baseOrdersState,
              }),
            })
          }
        >
          <Form.Item label="Ads Spend" name="adsDeduction">
            {refundsFormInputElement("adsDeduction")}
          </Form.Item>
          <Form.Item label="Refunds Deduction" name="refundsDeduction">
            {refundsFormInputElement("refundsDeduction")}
          </Form.Item>
          <Form.Item
            label="Miscellaneous Deduction"
            name="miscellaneousDeduction"
          >
            {refundsFormInputElement("miscellaneousDeduction")}
          </Form.Item>
          <Typography.Title level={4}>Final Amount:</Typography.Title>
          <Form.Item
            name="finalAmount"
            rules={[
              {
                required: true,
                message: "Please input final amount",
              },
            ]}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
          <Typography.Title level={4}>Proof:</Typography.Title>
          <Form.Item label="Photographic Proof" name="payoutProof">
            <Upload
              accept={[".png", ".jpg", ".jpeg"]}
              beforeUpload={() => false}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Transfer ID"
            name="transferId"
            rules={[
              {
                required: true,
                message: "Please input transfer ID",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="customSettlementDate" label="Cutom Settlement Date">
            <DatePicker
              onChange={(date) => setCustomLastDate(date?.toISOString())}
              allowClear
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

SettlePayoutModal.propTypes = {
  baseAmount: PropTypes.number.isRequired,
  premium: PropTypes.number.isRequired,
  minimumGuarantee: PropTypes.number.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  settlePaymentFunc: PropTypes.func.isRequired,
  formSubmissionState: PropTypes.bool.isRequired,
  paymentTermId: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  baseOrders: PropTypes.number.isRequired,
};

export default SettlePayoutModal;
