/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import { Card, Button, Form, Input, Divider, message } from "antd";
import logo from "./logo-color.png";
import { userLogin } from "../api/index";

const Login = ({ setIsRestricted, setAllowedFunctions }) => {
  const history = useHistory();
  const handleOnFinish = async (value) => {
    try {
      const response = await userLogin(value);
      if (response.code === 0) {
        localStorage.setItem("token", response?.result?.token);
        setIsRestricted(response?.result?.isRestricted);
        setAllowedFunctions(response?.result?.allowedFunctions);
        message.success("Login Successfully");
        setTimeout(() => {
          history.push("/dashboard");
        }, 200);
      } else {
        response?.message
          ? message.error(response?.message)
          : message.error("Something went wrong");
      }
    } catch (error) {
      console.log(error);
      error?.message
        ? message.error(error?.message)
        : message.error("Something went wrong");
    }
  };

  return (
    <div className="siteLogin">
      <Card
        bordered={false}
        bodyStyle={{
          width: 420,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="TagMango Logo" width={145} />
        <Divider className="customDivider noBorder" />
        <Form
          layout="vertical"
          style={{ width: "100%" }}
          onFinish={handleOnFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input placeholder="Email" size="large" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password placeholder="Password" size="large" />
          </Form.Item>
          <Button type="primary" htmlType="submit" size="large" block>
            Login
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
