export default function debounce(func, delay = 300) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
}

export function toFixedNumber(num, precision) {
  return Math.round(num * 10 ** precision) / 10 ** precision;
}
