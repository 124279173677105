/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import Login from "../Login/Login";
import Dashboard from "../Dashboard/index";
import UserDetails from "../UserDetails/index";
import NoAccountUser from "../NoAccountUser/index";
import AllUser from "../AllUser/index";
import NonTransferredPayment from "../NonTransferredPayment";

const Routes = () => {
  const [isRestricted, setIsRestricted] = useState(false);
  const [allowedFunctions, setAllowedFunctions] = useState([]);
  const [sensitiveAllowedActions, setSensitiveAllowedActions] = useState([]);
  return (
    <Router>
      <Switch>
        {/* <Route path="/no-internet" component={NoConnection} /> */}
        <Route
          exact
          path="/"
          render={() => (
            <Login
              setIsRestricted={setIsRestricted}
              setAllowedFunctions={setAllowedFunctions}
            />
          )}
        />
        <Route
          exact
          path="/login"
          render={() => (
            <Login
              setIsRestricted={setIsRestricted}
              setAllowedFunctions={setAllowedFunctions}
            />
          )}
        />
        <Route
          exact
          path="/dashboard"
          render={() => (
            <Dashboard
              isRestricted={isRestricted}
              setIsRestricted={setIsRestricted}
              setAllowedFunctions={setAllowedFunctions}
              allowedFunctions={allowedFunctions}
              sensitiveAllowedActions={sensitiveAllowedActions}
              setSensitiveAllowedActions={setSensitiveAllowedActions}
            />
          )}
        />
        {/* <Route exact path="/dashboard/user/:userId" component={UserDetails} />
        <Route exact path="/dashboard/noAccountUser" component={NoAccountUser} />
        <Route exact path="/allUser" component={AllUser} />
        <Route exact path="/nonTransferred" component={NonTransferredPayment} /> */}
      </Switch>
    </Router>
  );
};

export default Routes;
