import "./dashboardBanner.css";

import React, { useEffect, useState } from "react";

import {
  Button,
  Divider,
  Input,
  message,
  Switch,
  Typography,
  Upload,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";

import { getBase64, validImageExtensions } from "../../Utils/file";
import { createBanners, getBanners, updateBanners, uploadImage } from "../api";

const DashboardBanner = () => {
  const [tagMangoImgFile, setTagmangoImgFile] = useState(null);
  const [tagMangoImgUrl, setTagmangoImgUrl] = useState(null);
  const [tagmangoBtnLoader, setTagmangoBtnLoader] = useState(false);
  const [wlImgFile, setWlImgFile] = useState(null);
  const [wlImgUrl, setWlImgUrl] = useState(null);
  const [wlBtnLoader, setWlBtnLoader] = useState(false);
  const [tagmangoRedirectUrl, setTagmangoRedirectUrl] = useState(null);
  const [wlRedirectUrl, setWlRedirectUrl] = useState(null);
  const [tagmangoBannerStatus, setTagmangoBannerStatus] = useState(false);
  const [wlBannerStatus, setWlBannerStatus] = useState(false);
  const [tagmangoBannerData, setTagmangoBannerData] = useState(null);
  const [wlBannerData, setWlBannerData] = useState(null);

  const isValidUrl = (string) => {
    try {
      const data = new URL(string);
      console.log(data);
      return true;
    } catch (err) {
      return false;
    }
  };

  const setBanner = (setUrl, setStatus, setRedirectUrl, setData, data) => {
    setUrl(data?.bannerUrl);
    setStatus(data?.isActive);
    setRedirectUrl(data?.redirectUrl);
    setData(data);
  };

  const updateDataInState = (bannerList) => {
    bannerList.forEach((eachBanner) => {
      if (eachBanner?.platform === "tagmango") {
        setBanner(
          setTagmangoImgUrl,
          setTagmangoBannerStatus,
          setTagmangoRedirectUrl,
          setTagmangoBannerData,
          eachBanner
        );
      } else {
        setBanner(
          setWlImgUrl,
          setWlBannerStatus,
          setWlRedirectUrl,
          setWlBannerData,
          eachBanner
        );
      }
    });
  };

  const getAllBannerDetails = async () => {
    try {
      const resp = await getBanners();
      if (resp?.code === 0 && resp?.result?.length) {
        updateDataInState(resp?.result);
      }
    } catch (error) {
      message.error(error?.message);
    }
  };

  const beforeUpload = (inputfile, setImgLoading, setImageUrl, setFile) => {
    const isValidExtension =
      validImageExtensions.indexOf(inputfile.type) !== -1;

    if (!isValidExtension) {
      alert("You can only valid image type!");
      return;
    }

    const isValidSize = inputfile.size / 1024 / 1024 < 1024;
    if (!isValidSize) {
      alert("Image must be smaller than 1024MB!");
      return;
    }

    // const type = getFileExtension(inputfile.name)[1].toLowerCase();

    setImgLoading(true);
    setImageUrl(null);
    if (isValidExtension && isValidSize) {
      // convertImage(type, inputfile, setFileAndThumbnail);
      getBase64(inputfile, (url) => {
        setImgLoading(false);
        setFile(inputfile);
        setImageUrl(url);
      });
    } else {
      setFile(null);
    }
    return false;
  };

  const handleCreateBanner = async (isTagmango, setButtonLoader) => {
    try {
      setButtonLoader(true);
      const formData = new FormData();
      formData.append("image", isTagmango ? tagMangoImgFile : wlImgFile);
      const imgResp = await uploadImage(formData);
      if (imgResp.code === 0) {
        const reqBody = {
          bannerUrl: imgResp?.result?.files?.[0],
          // redirectUrl: isTagmango ? tagmangoRedirectUrl : wlRedirectUrl,
          platform: isTagmango ? "tagmango" : "whitelabel",
          isActive: isTagmango ? tagmangoBannerStatus : wlBannerStatus,
        };
        if (isTagmango ? tagmangoRedirectUrl : wlRedirectUrl) {
          const validUrl = await isValidUrl(
            isTagmango ? tagmangoRedirectUrl : wlRedirectUrl
          );
          if (!validUrl) {
            message.error("Redirect url is not valid");
            return;
          }
          reqBody.redirectUrl = isTagmango
            ? tagmangoRedirectUrl
            : wlRedirectUrl;
        }
        const resp = await createBanners(reqBody);
        if (resp.code === 201) {
          message.success("Banner created successfully");
          getAllBannerDetails();
        }
      }
    } catch (error) {
      message.error(error?.message);
    } finally {
      setButtonLoader(false);
    }
  };

  const editBannerData = async (isTagmango, setButtonLoader) => {
    try {
      setButtonLoader(true);
      let imgFile = null;
      if (isTagmango && tagMangoImgFile) {
        imgFile = tagMangoImgFile;
      } else if (!isTagmango && wlImgFile) {
        imgFile = wlImgFile;
      }
      let imgResp = { code: 0 };
      if (imgFile) {
        const formData = new FormData();
        formData.append("image", isTagmango ? tagMangoImgFile : wlImgFile);
        imgResp = await uploadImage(formData);
      }
      if (imgResp?.code === 0) {
        const reqBody = {};
        if (imgFile && imgResp?.result?.files?.[0]) {
          reqBody.bannerUrl = imgResp?.result?.files?.[0];
        }
        reqBody.isActive = isTagmango ? tagmangoBannerStatus : wlBannerStatus;
        if (isTagmango ? tagmangoRedirectUrl : wlRedirectUrl) {
          const validUrl = await isValidUrl(
            isTagmango ? tagmangoRedirectUrl : wlRedirectUrl
          );
          if (!validUrl) {
            message.error("Redirect url is not valid");
            return;
          }
          reqBody.redirectUrl = isTagmango
            ? tagmangoRedirectUrl
            : wlRedirectUrl;
        } else {
          reqBody.redirectUrl = "";
        }
        const resp = await updateBanners(
          reqBody,
          isTagmango ? tagmangoBannerData?._id : wlBannerData?._id
        );
        if (resp?.code === 0) {
          message.success("Banner updated successfully");
          getAllBannerDetails();
        }
      }
    } catch (error) {
      message.error(error?.message);
    } finally {
      setButtonLoader(false);
    }
  };

  const handleSaveButton = async (isTagmango, setButtonLoader) => {
    if (isTagmango && tagmangoBannerData?._id) {
      editBannerData(isTagmango, setButtonLoader);
    } else if (!isTagmango && wlBannerData?._id) {
      editBannerData(isTagmango, setButtonLoader);
    } else {
      handleCreateBanner(isTagmango, setButtonLoader);
    }
  };

  useEffect(() => {
    getAllBannerDetails();
  }, []);

  return (
    <div className="dashboardBannerMain">
      <br />
      <br />
      <div className="platformBannerWrapper">
        <div className="titleSaveBtnWrapper">
          <Typography.Title>Tagmango</Typography.Title>
          <Typography>
            Status:{" "}
            <Switch
              checked={tagmangoBannerStatus}
              disabled={tagmangoBtnLoader}
              onChange={setTagmangoBannerStatus}
            />{" "}
            Active
          </Typography>
          <Button
            type="primary"
            disabled={tagmangoBtnLoader}
            onClick={() => handleSaveButton(true, setTagmangoBtnLoader)}
          >
            Save
          </Button>
        </div>
        <div className="uploadBannerWrapper">
          <Typography.Text className="bannerImgTitle">
            Banner Image:{" "}
          </Typography.Text>
          <Upload
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={(file) =>
              beforeUpload(
                file,
                setTagmangoBtnLoader,
                setTagmangoImgUrl,
                setTagmangoImgFile
              )
            }
            disabled={tagmangoBtnLoader}
            accept="image/*"
          >
            {tagMangoImgUrl ? (
              <div>
                <img
                  className="bannerImg"
                  src={tagMangoImgUrl}
                  alt="tagMangoImgUrl"
                />
              </div>
            ) : (
              <Button
                loading={tagmangoBtnLoader}
                disabled={tagmangoBtnLoader}
                icon={<UploadOutlined />}
              >
                Click to Upload
              </Button>
            )}
          </Upload>
        </div>
        <br />
        <div className="uploadBannerWrapper">
          <Typography>Redirect URL: </Typography>
          <Input
            className="redirectUrlInput"
            placeholder="Enter redirect URL (Optional)"
            value={tagmangoRedirectUrl}
            disabled={tagmangoBtnLoader}
            onChange={(e) => setTagmangoRedirectUrl(e?.target?.value)}
          />
        </div>
      </div>

      <Divider />
      <br />
      <br />
      <div className="platformBannerWrapper">
        <div className="titleSaveBtnWrapper">
          <Typography.Title>Whitelabel</Typography.Title>
          <Typography>
            Status:{" "}
            <Switch
              disabled={wlBtnLoader}
              checked={wlBannerStatus}
              onChange={setWlBannerStatus}
            />{" "}
            Active
          </Typography>
          <Button
            type="primary"
            disabled={wlBtnLoader}
            onClick={() => handleSaveButton(false, setWlBtnLoader)}
          >
            Save
          </Button>
        </div>
      </div>
      <div className="uploadBannerWrapper">
        <Typography.Text className="bannerImgTitle">
          Banner Image:{" "}
        </Typography.Text>
        <Upload
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={(file) =>
            beforeUpload(file, setWlBtnLoader, setWlImgUrl, setWlImgFile)
          }
          accept="image/*"
          disabled={wlBtnLoader}
        >
          {wlImgUrl ? (
            <div>
              <img className="bannerImg" src={wlImgUrl} alt="wlImgUrl" />
            </div>
          ) : (
            <Button
              loading={wlBtnLoader}
              disabled={wlBtnLoader}
              icon={<UploadOutlined />}
            >
              Click to Upload
            </Button>
          )}
        </Upload>
      </div>
      <br />
      <div className="uploadBannerWrapper">
        <Typography>Redirect URL:</Typography>
        <Input
          className="redirectUrlInput"
          placeholder="Enter redirect URL (Optional)"
          value={wlRedirectUrl}
          disabled={wlBtnLoader}
          onChange={(e) => setWlRedirectUrl(e?.target?.value)}
        />
      </div>
    </div>
  );
};

export default DashboardBanner;
