import React, { useEffect, useState } from "react";

import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import fileDownload from "js-file-download";
import moment from "moment";

import useDebounce from "../../hooks/useDebounce";
import debounce from "../../Utils/utilityFunctions";
import {
  cancelTierSubscription,
  getWhitelabelCreatorsData,
  suspendPlatform,
  updateWLExpiry,
  upgradeTier,
} from "../api";
import CreatorDetailExpand from "./CreatorDetailExpand";

const nameStyle = { fontWeight: 500, fontSize: 14, color: "#1890ff" };

const { Option } = Select;

const EnterpriseCreators = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [earningGt, setEarningGt] = useState(null);
  const [earningLt, setEarningLt] = useState(null);
  const [mangoCountGt, setMangoCountGt] = useState(null);
  const [mangoCountLt, setMangoCountLt] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [tierUpgradeModalVisible, setTierUpgradeModalVisible] = useState(false);
  const [selectedTier, setSelectedTier] = useState(null);
  const [selectPaymentPlan, setSelectPaymentPlan] = useState(null);
  const [filterTier, setFilterTier] = useState(null);
  const [filterDomainStatus, setFilterDomainStatus] = useState(null);
  const [totalDomainActivated, setTotalDomainActivated] = useState(0);
  const [totalSuspended, setTotalSuspended] = useState(0);
  const [tierExpiryStartDate, setTierExpiryStartDate] = useState(null);
  const [tierExpiryEndDate, setTierExpiryEndDate] = useState(null);
  const [suspendPlatformModalVisible, setSuspendPlatformModalVisible] =
    useState(false);
  const [tierExpiryStatus, setTierExpiryStatus] = useState(null);
  const [tierExpiryUpdateModalVisible, setTierExpiryUpdateModalVisible] =
    useState(false);
  const [selectedTierExpiryDate, setSelectedTierExpiryDate] = useState(null);
  const [platformSuspensionStatus, setPlatformSuspensionStatus] =
    useState(null);
  const [tierAutopayStatus, setTierAutopayStatus] = useState(null);
  const [cancelSubscriptionModalVisible, setCancelSubscriptionModalVisible] =
    useState(false);
  const [onetimePer, setOnetimePer] = useState(null);
  const [recurringPer, setRecurringPer] = useState(null);
  const debouncedSearch = useDebounce({
    value: search,
    callback: () => setPage(1),
  });
  const autopayStatuses = [
    "pending",
    "halted",
    "cancelled",
    "paused",
    "expired",
    "completed",
    "active",
    "authenticated",
  ];

  const creatorsTable = () => {
    const columns = [
      {
        title: "Creator",
        dataIndex: "name",
        key: "name",
        render: (_, record) => {
          return (
            <>
              <Space size="small" direction="horizontal">
                <Avatar src={record?.profilePicUrl} size={32} />
                <Typography style={nameStyle}>{record?.name}</Typography>
              </Space>
            </>
          );
        },
      },
      {
        title: "Host",
        dataIndex: "host",
        key: "host",
        render: (text, record) => (
          <>
            <Typography.Link href={`https://${text}`}>{text}</Typography.Link>
            <Typography>
              <strong>Domain Status:</strong> {record?.domainStatus}
            </Typography>
          </>
        ),
      },
      {
        title: "User Tier",
        dataIndex: "userTier",
        key: "userTier",
      },
      {
        title: "Payment Plan",
        dataIndex: "paymentPlan",
        key: "paymentPlan",

        render: (text) => {
          return <Typography>{text || "N/A"}</Typography>;
        },
      },
      {
        title: "Mango Count",
        dataIndex: "mangoCount",
        key: "mangoCount",
      },
      {
        title: "Total Earnings",
        dataIndex: "totalEarning",
        key: "totalEarning",
        render: (text) => text?.toFixed(2),
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text) => moment(text).format("DD MMM YYYY"),
      },
      {
        title: "Tier Expiry Date",
        dataIndex: "accountSettingTierSubscriptionExpiry",
        key: "accountSettingTierSubscriptionExpiry",
        render: (text, record) => (
          <Space direction="vertical">
            <Typography>{moment(text).format("DD MMM YYYY")}</Typography>
            <Typography>
              {record?.tierExpired ? "Tier Expired" : "Tier Active"}
            </Typography>
          </Space>
        ),
      },
      {
        title: "Tier Autopay Status",
        dataIndex: "accountSettingTierRazorpaySubscriptionIsAutopayStatus",
        key: "accountSettingTierRazorpaySubscriptionIsAutopayStatus",
      },
      {
        title: "Whatsapp Autopay Status",
        dataIndex: "accountSettingWhatsappRazorpaySubscriptionIsAutopayStatus",
        key: "accountSettingWhatsappRazorpaySubscriptionIsAutopayStatus",
      },
      {
        title: "Actions",
        key: "actions",
        render: (_, record) => {
          return (
            <Space size="small" direction="vertical">
              {record?.platformSuspended ? (
                <Typography>Platform Suspended</Typography>
              ) : (
                <>
                  {record?.userTier === "tier2" ||
                  record?.userTier === "tier3" ||
                  record?.userTier === "tier4" ? (
                    <>
                      <Button
                        type="primary"
                        onClick={() => {
                          setSelectedUser(record);
                          setTierExpiryUpdateModalVisible(true);
                        }}
                        block
                        disabled={["active", "authenticated"].includes(
                          record?.accountSettingTierRazorpaySubscriptionIsAutopayStatus
                        )}
                      >
                        Update Tier Expiry
                      </Button>
                      <Button
                        type="primary"
                        block
                        disabled={
                          !["active", "authenticated"].includes(
                            record?.accountSettingTierRazorpaySubscriptionIsAutopayStatus
                          )
                        }
                        onClick={() => {
                          setSelectedUser(record);
                          setCancelSubscriptionModalVisible(true);
                        }}
                      >
                        Cancel Subscription
                      </Button>
                    </>
                  ) : null}
                  <Button
                    type="primary"
                    onClick={() => {
                      setSelectedUser(record);
                      setTierUpgradeModalVisible(true);
                      setOnetimePer(record?.razorpayOnetimeComission);
                      setRecurringPer(record?.razorpayRecurringComission);
                      setSelectedTier(record?.userTier);
                      setSelectPaymentPlan(record?.paymentPlan);
                    }}
                    block
                  >
                    Upgrade Tier
                  </Button>
                  <Button
                    type="danger"
                    onClick={() => {
                      setSelectedUser(record);
                      setSuspendPlatformModalVisible(true);
                    }}
                    block
                  >
                    Suspend Platform
                  </Button>
                </>
              )}
            </Space>
          );
        },
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={users}
        pagination={{
          current: page,
          total,
          onChange: (pg) => setPage(pg),
        }}
        loading={loading}
        rowKey={(record) => record._id}
        expandable={{
          expandedRowRender: (record) => (
            <CreatorDetailExpand creatorId={record?._id} />
          ),
        }}
      />
    );
  };

  const handleGetWLCreators = async () => {
    setLoading(true);
    try {
      const resp = await getWhitelabelCreatorsData(
        debouncedSearch,
        "enterprise-plan",
        filterTier,
        filterDomainStatus,
        startDate,
        endDate,
        earningGt,
        earningLt,
        mangoCountGt,
        mangoCountLt,
        tierExpiryStartDate,
        tierExpiryEndDate,
        tierExpiryStatus,
        platformSuspensionStatus,
        tierAutopayStatus,
        page,
        10,
        false
      );
      if (resp.code === 0) {
        setUsers(resp?.result?.data);
        setTotal(resp?.result?.total);
        setTotalDomainActivated(resp?.result?.totalDomainActivated);
        setTotalSuspended(resp?.result?.totalSuspended);
      } else {
        message.error(resp?.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleGetWLCreatorsCSV = async () => {
    try {
      const resp = await getWhitelabelCreatorsData(
        debouncedSearch,
        "enterprise-plan",
        filterTier,
        filterDomainStatus,
        startDate,
        endDate,
        earningGt,
        earningLt,
        mangoCountGt,
        mangoCountLt,
        tierExpiryStartDate,
        tierExpiryEndDate,
        tierExpiryStatus,
        platformSuspensionStatus,
        tierAutopayStatus,
        page,
        10,
        true
      );
      fileDownload(resp, `enterprise-creators-${Date.now()}.csv`);
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  const handleTierUpgrade = async (
    userId,
    tier,
    onetime,
    recurring,
    paymentPlan
  ) => {
    const reqBody = { userId, tier, paymentPlan };
    if (tier === "tier4") {
      if (!onetime && !recurring) {
        message.error("Enter onetime and recurring payment gateway charges");
        return;
      }
      if (!onetime && onetime !== 0) {
        message.error("Enter onetime payment gateway charges");
        return;
      }
      if (onetime < 0 || onetime > 100) {
        message.error("Enter valid onetime payment gateway charges");
        return;
      }
      if (!recurring && recurring !== 0) {
        message.error("Enter recurring payment gateway charges");
        return;
      }
      if (recurring < 0 || recurring > 100) {
        message.error("Enter valid recurring payment gateway charges");
        return;
      }
      reqBody.razorpayOnetimeComission = Number(onetime);
      reqBody.razorpayRecurringComission = Number(recurring);
    }
    const hide = message.loading("Upgrading tier...");
    try {
      const resp = await upgradeTier(reqBody);
      if (resp.code === 0) {
        message.success("Tier upgraded successfully");
        setTierUpgradeModalVisible(false);
        setSelectedUser(null);
        setSelectedTier(null);
        setSelectPaymentPlan(null);
        setRecurringPer(null);
        setOnetimePer(null);
        handleGetWLCreators();
      } else {
        message.error(resp?.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      hide();
    }
  };

  const handlePlatformSuspend = async (userId) => {
    const hide = message.loading("Suspending platform...");
    try {
      const resp = await suspendPlatform({ userId });
      if (resp.code === 0) {
        message.success("Platform suspended successfully");
        setSuspendPlatformModalVisible(false);
        setSelectedUser(null);
        handleGetWLCreators();
      } else {
        message.error(resp?.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      hide();
    }
  };

  const handleUpdateTierExpiry = async (userId, expiryDate) => {
    const hide = message.loading("Updating tier expiry...");
    try {
      const resp = await updateWLExpiry({ userId, expiryDate });
      if (resp.code === 0) {
        message.success("Tier expiry updated successfully");
        setTierExpiryUpdateModalVisible(false);
        setSelectedUser(null);
        setSelectedTierExpiryDate(null);
        handleGetWLCreators();
      } else {
        message.error(resp?.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      hide();
    }
  };

  const handleCancelTierSubscription = async (userId) => {
    const hide = message.loading("Cancelling subscription...");
    try {
      const resp = await cancelTierSubscription({ userId });
      if (resp.code === 0) {
        message.success("Subscription cancelled successfully");
      } else {
        message.error(resp?.result || resp?.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error(
        err?.response?.data.result || err?.message || "Something went wrong"
      );
    } finally {
      hide();
      setCancelSubscriptionModalVisible(false);
      setSelectedUser(null);
    }
  };

  useEffect(() => {
    handleGetWLCreators();
  }, [
    page,
    debouncedSearch,
    startDate,
    endDate,
    earningGt,
    earningLt,
    mangoCountGt,
    mangoCountLt,
    filterTier,
    filterDomainStatus,
    tierExpiryStartDate,
    tierExpiryEndDate,
    tierExpiryStatus,
    platformSuspensionStatus,
    tierAutopayStatus,
  ]);

  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: 8,
        }}
      >
        <Col span={8}>
          <Card title="Total Creators">
            <Typography>{total}</Typography>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Total Activated">
            <Typography>{totalDomainActivated}</Typography>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Total Suspended">
            <Typography>{totalSuspended}</Typography>
          </Card>
        </Col>
      </Row>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: 8,
        }}
      >
        <Col span={16}>
          <Input
            minLength={2}
            value={search}
            className="searchbar"
            style={{ width: "100%" }}
            placeholder="Search by name or email or host"
            onChange={(event) => setSearch(event.target.value)}
          />
        </Col>
        <Col span={8}>
          <Space>
            <Button type="primary" onClick={handleGetWLCreatorsCSV}>
              Download CSV
            </Button>
          </Space>
        </Col>
      </Row>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: 8,
        }}
      >
        <Col span={8}>
          <Space>
            <Typography>Amount Made:</Typography>
            <InputNumber value={earningGt} onChange={debounce(setEarningGt)} />
            <Typography>To:</Typography>
            <InputNumber value={earningLt} onChange={debounce(setEarningLt)} />
          </Space>
        </Col>
        <Col span={8}>
          <Space>
            <Typography>Number of Mangoes Made:</Typography>
            <InputNumber
              value={mangoCountGt}
              onChange={debounce(setMangoCountGt)}
            />
            <Typography>To:</Typography>
            <InputNumber
              value={mangoCountLt}
              onChange={debounce(setMangoCountLt)}
            />
          </Space>
        </Col>
        <Col
          span={8}
          style={{
            height: "100%",
          }}
        >
          <Space>
            <DatePicker.RangePicker
              value={[startDate, endDate]}
              onChange={(values) => {
                setStartDate(values?.[0]);
                setEndDate(values?.[1]);
              }}
              placeholder={["Start Date", "End Date"]}
            />
          </Space>
        </Col>
      </Row>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: 8,
        }}
      >
        <Col span={24}>
          <Space>
            <Select
              placeholder="Select Tier"
              allowClear
              value={filterTier}
              onSelect={setFilterTier}
              onClear={() => setFilterTier(null)}
            >
              <Option value="tier1">Tier 1</Option>
              <Option value="tier2">Tier 2</Option>
              <Option value="tier3">Tier 3</Option>
              <Option value="tier4">Tier 4</Option>
            </Select>
            <Select
              placeholder="Domain Activation Status"
              allowClear
              value={filterDomainStatus}
              onSelect={setFilterDomainStatus}
              onClear={() => setFilterDomainStatus(null)}
            >
              <Option value="domain_verified">Domain Verified</Option>
              <Option value="initiated">Initiated</Option>
              <Option value="drafted">Drafted</Option>
            </Select>
            <DatePicker.RangePicker
              placeholder={["Sub End Start Date", "Sub End End Date"]}
              allowClear
              value={[tierExpiryStartDate, tierExpiryEndDate]}
              onChange={(values) => {
                setTierExpiryStartDate(values?.[0]);
                setTierExpiryEndDate(values?.[1]);
              }}
            />
            <Select
              placeholder="Tier Expiry Status"
              allowClear
              value={tierExpiryStatus}
              onSelect={setTierExpiryStatus}
              onClear={() => setTierExpiryStatus(null)}
            >
              <Option value="expired">Expired</Option>
              <Option value="notexpired">Not Expired</Option>
            </Select>
            <Select
              placeholder="Platform suspension status"
              allowClear
              value={platformSuspensionStatus}
              onSelect={setPlatformSuspensionStatus}
              onClear={() => setPlatformSuspensionStatus(null)}
            >
              <Option value="suspended">Suspended</Option>
              <Option value="notsuspended">Not Suspended</Option>
            </Select>
            <Select
              placeholder="Tier Autopay Status"
              allowClear
              value={tierAutopayStatus}
              onSelect={setTierAutopayStatus}
              onClear={() => setTierAutopayStatus(null)}
            >
              {autopayStatuses.map((val) => (
                <Option value={val}>{val}</Option>
              ))}
            </Select>
          </Space>
        </Col>
      </Row>
      {creatorsTable()}
      <Modal
        visible={tierUpgradeModalVisible}
        onCancel={() => {
          setTierUpgradeModalVisible(false);
          setSelectedUser(null);
          setSelectedTier(null);
          setRecurringPer(null);
          setOnetimePer(null);
        }}
        footer={null}
        width={800}
        destroyOnClose
      >
        <Typography>
          Are you sure you want to upgrade the plan for {selectedUser?.name}?
        </Typography>
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          <Select
            style={{
              width: "200px",
            }}
            value={selectedTier}
            onChange={(value) => setSelectedTier(value)}
          >
            <Option value="tier1">Tier 1</Option>
            <Option value="tier2">Tier 2</Option>
            <Option value="tier3">Tier 3</Option>
            <Option value="tier4">Tier 4</Option>
          </Select>
          {selectedTier === "tier4" ? (
            <>
              <Typography>TM Commission: 0%</Typography>
              <Typography>Stripe Commission: 4.5%</Typography>
              <Typography>Indian PG charges: </Typography>

              <Typography>
                {" "}
                onetime % :{" "}
                <InputNumber value={onetimePer} onChange={setOnetimePer} />
              </Typography>

              <Typography>
                {" "}
                recurring % :{" "}
                <InputNumber value={recurringPer} onChange={setRecurringPer} />
              </Typography>
            </>
          ) : null}

          <Typography>Payment Plan</Typography>
          <Select
            style={{
              width: "200px",
            }}
            value={selectPaymentPlan}
            onChange={(value) => setSelectPaymentPlan(value)}
          >
            <Option value="monthly">Monthly</Option>
            <Option value="annual">annual</Option>
          </Select>

          <Space>
            <Button
              type="primary"
              onClick={() => {
                if (selectedTier) {
                  handleTierUpgrade(
                    selectedUser?._id,
                    selectedTier,
                    onetimePer,
                    recurringPer,
                    selectPaymentPlan
                  );
                } else {
                  message.error("Please select a plan");
                }
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                setTierUpgradeModalVisible(false);
                setSelectedUser(null);
                setSelectedTier(null);
                setRecurringPer(null);
                setOnetimePer(null);
              }}
            >
              No
            </Button>
          </Space>
        </Space>
      </Modal>
      <Modal
        visible={suspendPlatformModalVisible}
        onCancel={() => {
          setSuspendPlatformModalVisible(false);
          setSelectedUser(null);
        }}
        footer={null}
        width={800}
        destroyOnClose
      >
        <Typography>
          Are you sure you want to suspend the platform for {selectedUser?.name}
          ?
        </Typography>
        <Space>
          <Button
            type="primary"
            onClick={() => {
              handlePlatformSuspend(selectedUser?._id);
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setTierUpgradeModalVisible(false);
              setSelectedUser(null);
            }}
          >
            No
          </Button>
        </Space>
      </Modal>
      <Modal
        visible={tierExpiryUpdateModalVisible}
        onCancel={() => {
          setTierExpiryUpdateModalVisible(false);
          setSelectedUser(null);
        }}
        footer={null}
        width={800}
        destroyOnClose
      >
        <Typography>
          Are you sure you want to update the tier expiry for{" "}
          {selectedUser?.name}?
        </Typography>
        <Space direction="vertical">
          <DatePicker
            value={selectedTierExpiryDate}
            onChange={(value) => setSelectedTierExpiryDate(value)}
            placeholder="Tier Expiry Date"
            style={{
              width: "200px",
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                handleUpdateTierExpiry(
                  selectedUser?._id,
                  selectedTierExpiryDate
                );
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                setTierUpgradeModalVisible(false);
                setSelectedUser(null);
                setSelectedTierExpiryDate(null);
              }}
            >
              Cancel
            </Button>
          </Space>
        </Space>
      </Modal>
      <Modal
        visible={cancelSubscriptionModalVisible}
        onCancel={() => {
          setSelectedUser(null);
          setCancelSubscriptionModalVisible(false);
        }}
        onOk={() => {
          handleCancelTierSubscription(selectedUser?._id);
        }}
        destroyOnClose
        title="Cancel Subscription?"
      >
        Are you sure you want to cancel the subscription for{" "}
        {selectedUser?.name}? THIS IS IRREVERSIBLE
      </Modal>
    </>
  );
};

export default EnterpriseCreators;
