import { useEffect, useRef, useState } from "react";

const useDebounce = ({ value, delay = 1000, callback = () => {} }) => {
  const [debouncedValue, setDebouncedValue] = useState("");
  const timerRef = useRef();

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      if (callback) {
        callback();
      }
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
