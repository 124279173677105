/* eslint-disable react/jsx-filename-extension */
import React from "react";
import Routes from "./Pages/Routes/index";
import "antd/dist/antd.css";
import "./App.min.css";

function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
