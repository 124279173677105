/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { Layout } from "antd";

export default class Footer extends Component {
  render() {
    return (
      <Layout.Footer className="siteFooter" style={{ textAlign: "center" }}>
        TagMango Admin ©2020
      </Layout.Footer>
    );
  }
}
