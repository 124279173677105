import React from "react";
import {
  DatePicker,
  Form,
  Button,
  Modal,
  Select,
  Input,
  InputNumber,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const PaymentTermsModal = ({
  activeTermForm,
  modalVisible,
  setModalVisible,
  handleFormSubmit,
  mangos,
  loadingState,
  formSubmissionLoadingState,
}) => {
  const getDaysOfMonth = () => {
    const days = [];
    for (let i = 1; i <= 28; i += 1) {
      days.push(i);
    }
    return days;
  };

  return (
    <Modal
      visible={modalVisible}
      title="Add/Edit Payment Terms"
      onCancel={() => setModalVisible(false)}
      footer={[
        formSubmissionLoadingState ? (
          <LoadingOutlined />
        ) : (
          <Button
            type="primary"
            htmlType="submit"
            form="payment-terms-form"
            disabled={loadingState}
          >
            Submit
          </Button>
        ),
      ]}
      destroyOnClose
    >
      {loadingState ? (
        <LoadingOutlined
          style={{
            fontSize: "2rem",
          }}
        />
      ) : (
        <Form
          id="payment-terms-form"
          form={activeTermForm}
          onFinish={handleFormSubmit}
        >
          <Form.Item
            name="startDate"
            label="Start Date"
            rules={[
              {
                required: true,
                message: "Please select start date",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="termsType"
            label="Terms Type"
            rules={[
              {
                required: true,
                message: "Please select a terms type",
              },
            ]}
          >
            <Select>
              <Select.Option value="workshop">Workshop</Select.Option>
              <Select.Option value="continuous">Continuous</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.termsType !== currentValues.termsType
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("termsType") === "workshop" ? (
                <>
                  <Form.Item
                    name="selectedMango"
                    label="Select Mango"
                    rules={[
                      {
                        required:
                          activeTermForm.getFieldValue("termsType") ===
                          "workshop",
                        message: "Please select a mango",
                      },
                    ]}
                  >
                    <Select>
                      {mangos.map((mango) => (
                        <Select.Option value={mango?._id} key={mango?._id}>
                          {mango?.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="workshopEndDate"
                    label="Workshop End Date"
                    rules={[
                      {
                        required:
                          activeTermForm.getFieldValue("termsType") ===
                          "workshop",
                        message: "Please select workshop end date",
                      },
                      {
                        validator: (_, value) => {
                          if (
                            value &&
                            value.isBefore(
                              activeTermForm.getFieldValue("startDate")
                            )
                          ) {
                            return Promise.reject(
                              new Error(
                                "Workshop end date cannot be before start date"
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <DatePicker />
                  </Form.Item>
                  <Form.Item
                    name="workshopPayoutDate"
                    label="Workshop Payout Date"
                    rules={[
                      {
                        required:
                          activeTermForm.getFieldValue("termsType") ===
                          "workshop",
                        message: "Please select workshop payout date",
                      },
                      {
                        validator: (_, value) => {
                          if (
                            value &&
                            value.isBefore(
                              activeTermForm.getFieldValue("workshopEndDate")
                            )
                          ) {
                            return Promise.reject(
                              new Error(
                                "Workshop payout date cannot be before workshop end date"
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <DatePicker />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    name="payoutFrequency"
                    label="Payout Frequency"
                    rules={[
                      {
                        required:
                          activeTermForm.getFieldValue("termsType") ===
                          "continuous",
                        message: "Please select payout frequency",
                      },
                    ]}
                  >
                    <Select>
                      <Select.Option value="weekly">Weekly</Select.Option>
                      <Select.Option value="monthly">Monthly</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.payoutFrequency !==
                      currentValues.payoutFrequency
                    }
                  >
                    {(params) => {
                      const fieldValueFunc = params.getFieldValue;
                      return (
                        <Form.Item
                          name="continuousPayoutDates"
                          label="Payout Dates"
                          rules={[
                            {
                              required:
                                activeTermForm.getFieldValue("termsType") ===
                                "continuous",
                              message: "Please select payout dates",
                            },
                          ]}
                        >
                          {fieldValueFunc("payoutFrequency") === "weekly" ? (
                            <Select mode="multiple">
                              <Select.Option value={1} key={1}>
                                Monday
                              </Select.Option>
                              <Select.Option value={2} key={2}>
                                Tuesday
                              </Select.Option>
                              <Select.Option value={3} key={3}>
                                Wednesday
                              </Select.Option>
                              <Select.Option value={4} key={4}>
                                Thursday
                              </Select.Option>
                              <Select.Option value={5} key={5}>
                                Friday
                              </Select.Option>
                              <Select.Option value={6} key={6}>
                                Saturday
                              </Select.Option>
                              <Select.Option value={0} key={0}>
                                Sunday
                              </Select.Option>
                            </Select>
                          ) : (
                            <Select mode="multiple">
                              {getDaysOfMonth().map((day) => (
                                <Select.Option value={day} key={day}>
                                  {day}
                                </Select.Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </>
              )
            }
          </Form.Item>
          <Form.Item name="minimumGuarantee" label="Minimum Guarantee">
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item name="premium" label="Premium">
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item name="notes" label="Notes">
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="expiryDate"
            label="Expiry Date"
            rules={[
              {
                required: true,
                message: "Please select expiry date",
              },
              {
                validator: (_, value) => {
                  if (
                    value &&
                    value.isBefore(activeTermForm.getFieldValue("startDate"))
                  ) {
                    return Promise.reject(
                      new Error("Expiry date cannot be before start date")
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item name="_id" noStyle>
            <Input type="hidden" />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

PaymentTermsModal.propTypes = {
  activeTermForm: PropTypes.shape(Object).isRequired,
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  mangos: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  loadingState: PropTypes.bool.isRequired,
  formSubmissionLoadingState: PropTypes.bool.isRequired,
};

export default PaymentTermsModal;
