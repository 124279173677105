import React, { useState } from "react";

import { Button, Form, Input, message, Modal, Typography } from "antd";
import PropTypes from "prop-types";

import { getBulkWalletWithdrawalOtp, handleBulkWalletWithdrawal } from "../api";

const BulkWithdrawalHandler = ({
  withdrawalIds,
  modalMode,
  setModalMode,
  showModal,
  setShowModal,
  onWithdrawalStatusChange,
  setResetTableFilter,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [sendingOtp, setSendingOtp] = useState(false);

  const handleSendWithdrawalOTP = async () => {
    setSendingOtp(true);
    try {
      const resp = await getBulkWalletWithdrawalOtp({
        withdrawalIds,
      });
      if (resp?.code === 0) {
        message.success("OTP sent successfully");
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      setSendingOtp(false);
    }
  };

  const modalBodyBasedOnAction = {
    approve: (
      <>
        <Form.Item
          name="otp"
          label="OTP"
          rules={[{ required: true, message: "OTP is mandatory" }]}
        >
          <Input />
        </Form.Item>
        <Button
          type="primary"
          onClick={() => handleSendWithdrawalOTP()}
          loading={sendingOtp}
        >
          Send OTP
        </Button>
      </>
    ),
    reject: (
      <>
        <Form.Item
          name="rejectionReason"
          label="Rejection Reason"
          rules={[
            { required: true, message: "Must have a reason for rejection" },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </>
    ),
  };

  const handleApprovalStatusChange = async (values) => {
    const reqBody = {
      ...values,
      ...(modalMode === "approve"
        ? { approvalStatus: "approved" }
        : { approvalStatus: "rejected" }),
      withdrawalIds,
    };
    setLoading(true);
    try {
      const resp = await handleBulkWalletWithdrawal(reqBody);
      if (resp?.code === 0) {
        setShowModal(false);
        onWithdrawalStatusChange(withdrawalIds, reqBody?.approvalStatus);
        message.success("Successfully updated");
        setResetTableFilter(true);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 800);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        <Typography>
          {modalMode === "approve"
            ? "Approve this request?"
            : "Reject this request?"}
        </Typography>
      }
      visible={showModal}
      destroyOnClose
      onCancel={() => {
        setShowModal(false);
        setModalMode("");
        form.resetFields();
      }}
      footer={[
        <Button
          type="primary"
          htmlType="submit"
          form="withdrawal-action-form"
          loading={loading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        form={form}
        id="withdrawal-action-form"
        onFinish={handleApprovalStatusChange}
      >
        {modalBodyBasedOnAction[modalMode]}
      </Form>
    </Modal>
  );
};

BulkWithdrawalHandler.propTypes = {
  withdrawalIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  modalMode: PropTypes.string.isRequired,
  setModalMode: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onWithdrawalStatusChange: PropTypes.func.isRequired,
  setResetTableFilter: PropTypes.func.isRequired,
};

export default BulkWithdrawalHandler;
