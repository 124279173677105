import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { Form, Input, InputNumber } from "antd";

const ProductForm = ({ product }, ref) => {
  const [form] = Form.useForm();

  const requiredRule = [{ required: true }];

  useEffect(() => {
    if (product) {
      const copyProduct = {
        productName: product.productName,
        productLink: product.productLink,
        revenueShare: product.revenueShare,
      };
      form.setFieldsValue(copyProduct);
    } else {
      form.resetFields();
    }
  }, [product]);

  useImperativeHandle(ref, () => ({
    form,
  }));

  return (
    <Form form={form}>
      <Form.Item
        name="productName"
        label="Product Name"
        required
        rules={requiredRule}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="productLink"
        label="Product Link"
        required
        rules={requiredRule}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="revenueShare"
        label="Revenue Share"
        required
        rules={requiredRule}
      >
        <InputNumber max={100} min={0} />
      </Form.Item>
      {!product && (
        <Form.Item
          name="mango"
          label="Mango"
          extra="Please enter the mango id here"
          required
          rules={requiredRule}
        >
          <Input />
        </Form.Item>
      )}
    </Form>
  );
};

ProductForm.propTypes = {
  product: PropTypes.shape({
    productName: PropTypes.string,
    productLink: PropTypes.string,
    revenueShare: PropTypes.number,
    mango: PropTypes.string,
  }),
};

export default forwardRef(ProductForm);
