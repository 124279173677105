import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import "../App.min.css";

const Loader = () => (
  <div className="loader">
    <LoadingOutlined />
  </div>
);

export default Loader;
